import {
    CreateButton,
    Datagrid,
    DateField,
    ExportButton,
    FunctionField,
    List,
    TextField,
    TopToolbar,
    useLocaleState,
    useTranslate
} from "react-admin";
import {EmptyPage} from "../../../components/EmtpyPage";
import SelectCompanyWithBusinessUnitInput from "../../../components/SelectCompanyWithBusinessUnitInput";
import React, {useEffect, useState} from "react";
import {PinkPagination} from "../../../components/PinkPagination";
import {getValidPrivilegesIds} from "../../../components/authentication/PermissionHandler";
import RedirectToPurchase from "../../../components/RedirectToPurchase";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import {IconButton} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";


const OperationsList = ({permissions}) => {
    const [posSet, setPosSet] = useState(0)
    const [isShowRedirect, setIsShowRedirect] = useState(false)
    const hasEdit = getValidPrivilegesIds("WAREHOUSE", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    const hasCreate = getValidPrivilegesIds("WAREHOUSE", "CREATE").some(r => permissions.includes(r))
    const locale = useLocaleState();
    const translate = useTranslate()
    const url = window.location.href.split('?')[0]

    useEffect(() => {
        localStorage.removeItem("importedWarehouseOperations")
    }, [])

    useEffect(() => {
        if (posSet && posSet !== "undefined" && parseInt(posSet) !== 0) {
            localStorage.setItem("operationsPosSetId", posSet)
        }
    }, [posSet])

    const exporter = operations => {
        const headers = [translate("operations.type"), translate("operations.date"), translate("operations.source"), translate("operations.destination"),
            translate("operations.invoice"), translate("operations.comment"), translate("operations.sum")]
        let exportedOperations = []
        operations.forEach(item => {
            let arr = [
                translate(`operations.${snakeToCamelConverter(item.type)}`),
                dayjs(item.dateTime).format('YYYY.MM.DD HH:mm'),
                item.sourceName,
                item.targetName,
                item.invoice,
                item.description,
                item.sum
            ]
            exportedOperations.push(arr)
        })
        exportedOperations.unshift(headers)
        let wscols = [
            {wch:22},
            {wch:15},
            {wch:15},
            {wch:15},
            {wch:15},
            {wch:15},
            {wch:15}
        ];
        const ws = XLSX.utils.aoa_to_sheet(exportedOperations);
        ws['!cols'] = wscols;
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "export");
        XLSX.writeFile(wb, "export.xlsx");
    }

    const snakeToCamelConverter = str =>
        str.toLowerCase().replace(/([-_][a-z])/g, group =>
            group
                .toUpperCase()
                .replace('-', '')
                .replace('_', '')
        )

    const ListActions = () => {
        return (
            <TopToolbar>
                {hasCreate && <CreateButton/>}
                <ExportButton/>
            </TopToolbar>
        )
    }

    return (
        <>
            <SelectCompanyWithBusinessUnitInput getResource={"warehouse-operation"} setPosSet={setPosSet}
                                                setIsShowRedirect={setIsShowRedirect}/>
            {isShowRedirect ?
                <RedirectToPurchase resource={"warehouse"}/>
                :
                posSet && posSet !== "undefined" && parseInt(posSet) !== 0 &&
                <>
                <List actions={<ListActions/>}
                      exporter={exporter}
                      title={"operations.title"}
                      empty={<EmptyPage text={"operations.empty"} createEnabled={true}/>}
                      pagination={<PinkPagination trigger={posSet}/>}
                      filterDefaultValues={{posSetId: posSet}}
                >
                    <Datagrid rowClick={hasEdit} bulkActionButtons={false}>
                        <FunctionField
                            render={record => {
                                return (
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        <span>{translate(`operations.${snakeToCamelConverter(record.type)}`)}</span>
                                        <IconButton onClick={(event) => {
                                            event.stopPropagation()
                                            window.open(`${url}/${record.id}`, '_blank', 'noopener,noreferrer')
                                        }}>
                                            <OpenInNewIcon/>
                                        </IconButton>
                                    </div>

                                )
                            }}
                            source="type" label={"operations.type"}/>
                        <DateField locales={locale[0]} showTime source="dateTime" label={"operations.date"}/>
                        <TextField source="sourceName" label={"operations.source"}/>
                        <TextField source="targetName" label={"operations.destination"}/>
                        <TextField source="invoice" label={"operations.invoice"} sortable={false}/>
                        <TextField source="description" label={"operations.comment"} sortable={false}/>
                        <TextField source="sum" label={"operations.sum"} sortable={false}/>
                    </Datagrid>
                </List>
                <img src={shorticLogo}
            style={{position: "absolute", bottom: "0", left: "20", width: "150px", paddingBottom: '10px'}}/>
</>
}
</>
)
}

export default OperationsList