import {List, TopToolbar, CreateButton, TextField, DateField, FunctionField, useTranslate, useNotify, useRefresh} from "react-admin";
import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import dataProvider from "../../components/dataProvider";
import ConfigurableDatagrid from "../../components/ConfigurableDatagrid";
import DeleteIcon from "@mui/icons-material/Delete";
import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React from "react";

export const VoucherGeneratorList = ({ permissions }) => {

  const hasCreate = getValidPrivilegesIds("PURCHASE", "LIST").some((r) =>
    permissions.includes(r)
  );

const translate = useTranslate();
const notify = useNotify();
const refresh = useRefresh();
const [openDialog, setOpenDialog] = React.useState(false);
const [voucherId, setVoucherId] = React.useState(null);

  const ListActions = () => {
    return (
      <TopToolbar>
        {hasCreate && <CreateButton label="voucher.create" />}
      </TopToolbar>
    );
  };

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
      <>
    <List actions={<ListActions />}>
      <ConfigurableDatagrid bulkActionButtons={false} rowClick={'show'}>
        <TextField source="id" label={"voucher.id"} />
        <TextField source="name" label={"voucher.name"} />
        <FunctionField source="voucherType" label={"voucher.voucherType"}  render={
            record => record.voucherType === "DISCOUNT_PERCENTAGE" ? translate("voucher.discountPercent") : translate("voucher.fixPrice")
        }/>
        <TextField source="licenseCount" label={"voucher.licenseCount"} />
        <TextField source="validForCycle" label={"voucher.validForCycle"} />
        <DateField source="validFrom" label={"voucher.validFrom"} />
        <DateField source="validTo" label={"voucher.validTo"} />
        <FunctionField render={
            record => {
                return(
                    <IconButton onClick={(e)=>{
                      e.stopPropagation();
                        setVoucherId(record.id)
                        setOpenDialog(true)
                    }}>
                      <DeleteIcon/>
                    </IconButton>
                )
            }
        }
                       label={"Törlés"}
        />
      </ConfigurableDatagrid>
    </List>
        <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {translate("voucher.dialogTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translate("voucher.alert")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
                dataProvider.delete('voucherGenerator', {id: voucherId})
                    .then(() => {
                            notify('Sikeres Törlés!', {type: 'info', autoHideDuration: 1000});
                            setOpenDialog(false)
                            setTimeout(() => {
                                refresh()
                            }, 1000)
                        }
                    )
                    .catch(() => {
                        setOpenDialog(false)
                        notify('Sikertelen Törlés!', {type: 'error', autoHideDuration: 1000});
                    })
            }}>
              {translate("voucher.continue")}</Button>
            <Button onClick={handleClose} autoFocus>
              {translate("voucher.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
        </>
  );
};
