import {createTheme} from "@mui/material/styles";
import { huHU } from '@mui/material/locale'

export const muiDatagridTheme = createTheme(
    {
        palette: {
            primary: { main: '#ff71bc' },
            secondary: { main: '#FF407D' },
        },
    },
    huHU,
);