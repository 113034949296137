import React, {useEffect, useState} from "react";
import {IconButton, TextField, Typography} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import WarehouseProductNameInput from "./WarehouseProductNameInput";
import Modifiers from "./Modifiers";
import dataProvider from "../../../components/dataProvider";
import {useTranslate} from "react-admin";

const IngredientForm = ({posSetId, ingredient, index, setUnitComponents, num}) => {
    const [units, setUnits] = useState([])
    const [quantity, setQuantity] = useState(ingredient.quantity || null);
    const [inputUnits, setInputUnits] = useState({});
    const translate = useTranslate()

    useEffect(() => {
        dataProvider.get(`product/components`, {posSetId: posSetId})
            .then(value => {
                const temp = value.data.find((warehouseProduct) => {
                    return warehouseProduct.id === ingredient.warehouseProductId
                })

                const inputDefaultValue = temp.units.find((unit) => {
                    return unit.id === ingredient.warehouseProductUnitId
                })
                setInputUnits(inputDefaultValue)
                setUnits(temp.units)
                if(ingredient.modifiers){
                    const warehouseProductsWithName = ingredient.modifiers.map((modifier) => {
                        const temp = value.data.find((warehouseProduct) => {
                            return warehouseProduct.id === modifier.warehouseProductId
                        })

                        return {...modifier, name: temp.name}
                    })

                    setUnitComponents((prevUnitComponents) => {
                        return prevUnitComponents.map((prevUnitComponent) => {
                            if ((prevUnitComponent.id ? prevUnitComponent.id === ingredient.id : prevUnitComponent.feId === ingredient.feId) && prevUnitComponent.modifiers.length > 0) {
                                return {...prevUnitComponent, modifiers: warehouseProductsWithName, modifierOpen: true}
                            }
                            return prevUnitComponent
                        })
                    })
                }

            })
            .catch(reason => {
                setInputUnits([])
                setUnits([])
            })
    }, []);

    const ingredientBoxStyle = {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '33rem',
        marginRight: '10px',
    }

    const handleChange = (event) => {
        const numericValue = parseInt(event.target.value);
        if (!isNaN(numericValue) && numericValue >= 0){
            setQuantity(event.target.value.replace("-", ''));
            setUnitComponents((prevUnitComponents) => {
                return prevUnitComponents.map((prevUnitComponent) => {
                    if (prevUnitComponent.id ? prevUnitComponent.id === ingredient.id : prevUnitComponent.feId === ingredient.feId) {
                        return {...prevUnitComponent, quantity: event.target.value.replace("-", '')}
                    }
                    return prevUnitComponent
                })
            })
        }else {
            setQuantity("");
        }
    };

    const handleModifierOpen = () => {
        setUnitComponents((prevUnitComponents) => {
            return prevUnitComponents.map((prevUnitComponent) => {
                if (prevUnitComponent.id ? prevUnitComponent.id === ingredient.id : prevUnitComponent.feId === ingredient.feId) {
                    return {...prevUnitComponent, modifierOpen: !prevUnitComponent.modifierOpen}
                }
                return prevUnitComponent
            })
        })
    }

    const handleDeleteIngredient = (id) => {
       setUnitComponents((prevUnitComponents) => {
           return prevUnitComponents.filter((prevUnitComponent) => {
               return prevUnitComponent.id ? prevUnitComponent.id !==  id : prevUnitComponent.feId !== id
           })
       })
    }
        return (
        <div style={ingredientBoxStyle}>
            <Typography variant={"subtitle1"}>{translate("modifiers.ingredient") + " " +(index+1)}</Typography>
            <WarehouseProductNameInput getResource={"components"} ingredient={ingredient} setUnitComponents={setUnitComponents} setUnits={setUnits} posSetId={posSetId} setInputUnits={setInputUnits}/>
            <Autocomplete
                value={inputUnits}
                getOptionLabel={option => option.unitName || ""}
                renderInput={(params) => <TextField {...params} required={true} label={translate("modifiers.unit")}/>}
                options={units}
                onChange={(event, newValue) => {
                    setInputUnits(newValue);
                    setUnitComponents((prevUnitComponents) => {
                        return prevUnitComponents.map((prevUnitComponent) => {
                            if (prevUnitComponent.id ? prevUnitComponent.id === ingredient.id : prevUnitComponent.feId === ingredient.feId) {
                                return {...prevUnitComponent, warehouseProductUnitId: newValue.id, modifiers:[]}
                            }
                            return prevUnitComponent
                        })
                    })
                }}
                disableClearable
            />
            <TextField
                label={translate("modifiers.quantity")}
                type="number"
                value={quantity}
                onChange={handleChange}
                inputProps={{
                    step: '1',
                    required: true
                }}
            />
            <IconButton size={"small"} onClick={() => {
                handleDeleteIngredient(ingredient.id ? ingredient.id : ingredient.feId)
            }}>
                <DeleteIcon fontSize={"small"} sx={{color: "red"}}/>
            </IconButton>
            {ingredient.modifierOpen ?
                <>
                    <IconButton size={"small"} onClick={handleModifierOpen}>
                        <RemoveCircleOutlineIcon fontSize={"small"} sx={{color: "#ff71bc"}}/>
                        <span
                            style={{fontSize: '11px', marginLeft: '3px'}}>{translate("modifiers.hideModifier")}</span>
                    </IconButton>
                    <Modifiers posSetId={posSetId} num={num} index={index} ingredient={ingredient} setUnitComponents={setUnitComponents}/>
                </>
                :
                <IconButton size={"small"} onClick={handleModifierOpen} sx={{marginBottom: 3}}>
                    <AddCircleOutlineIcon fontSize={"small"} sx={{color: "#ff71bc"}}/>
                    <span
                        style={{fontSize: '11px', marginLeft: '3px'}}>{translate("modifiers.addModifier")}</span>
                </IconButton>
            }
        </div>)
}

export default IngredientForm;