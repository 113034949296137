import React, {useEffect, useState} from 'react';
import {
    ArrayField,
    AutocompleteArrayInput,
    AutocompleteInput,
    ChipField,
    CreateButton,
    Datagrid,
    ExportButton,
    FilterForm,
    FunctionField,
    List,
    required,
    SimpleForm,
    SingleFieldList,
    TextField,
    TextInput,
    TopToolbar,
    useDataProvider,
    useRefresh,
    useTranslate
} from "react-admin";
import ConfigurableDatagrid from "../../../components/ConfigurableDatagrid";
import Dialog from '@mui/material/Dialog';
import {getValidPrivilegesIds} from "../../../components/authentication/PermissionHandler";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ClearIcon from '@mui/icons-material/Clear';
import {Button, DialogTitle, IconButton} from "@mui/material";
import {PinkPagination} from "../../../components/PinkPagination";
import {EmptyPage} from "../../../components/EmtpyPage";
import RedirectToPurchase from "../../../components/RedirectToPurchase";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png"


const WarehouseProductList = ({permissions}) => {
    const dataProvider = useDataProvider()
    const hasEdit = getValidPrivilegesIds("WAREHOUSE", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    const hasCreate = getValidPrivilegesIds("WAREHOUSE", "CREATE").some(r => permissions.includes(r))
    const [tag, setTag] = useState({loading: false, loaded: false, data: [], error: undefined});
    const [selected, setSelected] = useState([])
    const [visible, setVisible] = useState(false)
    const refresh = useRefresh()
    const [open, setOpen] = useState(false)
    const [deleteTagId, setDeleteTagId] = useState(null)
    const [isShowRedirect, setIsShowRedirect] = useState(false)
    const [loading, setLoading] = useState(true)
    const translate = useTranslate()
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [companies, setCompanies] = useState({loading: false, loaded: false, data: [], error: undefined});
    const url = window.location.href.split('?')[0]
    const activeFlag = [
        {id: 1, name: translate("warehouse.all"), activeFlag: "ALL"},
        {id: 2, name: translate("warehouse.active"), activeFlag: "ACTIVE"},
        {id: 3, name: translate("warehouse.inactive"), activeFlag: "INACTIVE"}
    ]
    useEffect(() => {
        if (selectedCompany && selectedCompany !== "undefined" && parseInt(selectedCompany) !== 0) {
            localStorage.setItem("warehouseProductPosSetId", selectedCompany)
            setLoading(true)
        }
    }, [selectedCompany])

    useEffect(() => {
        dataProvider.get('warehouse/pos-sets')
            .then(({data}) => {
                const company = data.list.find(c => c.id === selectedCompany)
                if (company.posSetSelectorPrivileges.getPrivilegeCompany) {
                    setIsShowRedirect(false)
                    setLoading(false)
                } else {
                    setIsShowRedirect(true)
                    setLoading(false)
                }
            })
            .catch(reason => {
                console.log(reason)
            })
    }, [selectedCompany]);

    useEffect(() => {
        if (!companies.loading && !companies.loaded) {
            setCompanies({...companies, loading: true})
            dataProvider.get(`warehouse-product/companies`)
                .then(value => {
                    setVisible(true)
                    if (value.data.defaultPosSetId === null) {
                        setSelectedCompany(value.data.list[0].id)
                    } else {
                        setSelectedCompany(value.data.list.find(obj => obj.id === value.data.defaultPosSetId)?.id || value.data.list[0].id)
                    }
                    setCompanies({loading: false, loaded: true, data: value.data.list, error: undefined})

                })
                .catch(reason => {
                    setCompanies({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, []);

    useEffect(() => {
        if (!tag.loading && !tag.loaded && selectedCompany) {
            setTag({...tag, loading: true});
            dataProvider.get(`warehouse-product/tag`, {posSetId: selectedCompany, sort: "name,ASC"})
                .then(value => {
                    setTag({
                        loading: false,
                        loaded: true,
                        data: value.data.sort((a, b) => a.name > b.name ? 1 : -1),
                        error: undefined
                    });
                })
                .catch(reason => {
                    setTag({loading: false, loaded: true, data: [], error: reason});
                })
        }
    }, [tag.loading, tag.loaded, selectedCompany]);

    const handleDialogClose = toDelete => {
        setOpen(false)
        if (toDelete && deleteTagId != null) {
            dataProvider.delete(`warehouse-product/tag`, {id: deleteTagId})
                .finally(() => {
                    setTag({...tag, loaded: false})
                    refresh()
                });
        }
        setDeleteTagId(null)
    }

    const handleDialogOpen = () => {
        setOpen(true)
    }

    const handleDeleteButton = (removedId) => e => {
        handleDialogOpen()
        e.stopPropagation()
        setDeleteTagId(removedId)
    }

    const optionRenderer = choice => {
        return <span>
        {choice.name}
            {!(selected.some(e => e === choice.id)) && (<ClearIcon className={"notDraggable"}
                                                                   onClick={handleDeleteButton(`${choice.id}`)}
                                                                   fontSize={"small"}
                                                                   sx={{
                                                                       marginBottom: "0px",
                                                                       color: "red",
                                                                       position: "absolute",
                                                                       right: "5px"
                                                                   }}
            />)}
    </span>
    }

    const matchSuggestion = (filter, choice) => {
        return (
            choice.name.toLowerCase().includes(filter.toLowerCase())
        );
    };

    const postFilter = [
        <TextInput label="warehouse.nameFilter" source="nameSearch" alwaysOn
            // sx={{width: "300px", position: "absolute", right: "670px", top: "80px"}}
        />,
        <AutocompleteArrayInput label={'warehouse.filterTags'} onChange={(e) => setSelected(e)} source="tags"
                                choices={tag.data} alwaysOn
            // sx={{width: "300px", position: "absolute", right: "10px", top: "80px"}}
            optionText={optionRenderer} matchSuggestion={matchSuggestion}
        />,
        <TextInput label="warehouse.filterMainProductGroup" source="mainProductGroup" alwaysOn
            // sx={{width: "300px", position: "absolute", right: "670px", top: "80px"}}
        />,
        <AutocompleteInput
            // sx={{width: "300px", position: "absolute", right: "350px", top: "80px"}}
            source={"activeFlag"}
            choices={activeFlag}
            label={"warehouse.active"}
            optionValue={"activeFlag"}
            disableClearable
            alwaysOn
        />
    ];

    const ListActions = () => {
        return (
            <TopToolbar>
                {hasCreate && <CreateButton label="warehouse.create"/>}
            </TopToolbar>
        )
    };

    return (
        <>
            <Dialog open={open} onClose={handleDialogClose}>
                <DialogTitle>{translate("products.dialogTitle")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {translate("products.dialogContextText")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)}>{translate("products.no")}</Button>
                    <Button onClick={() => handleDialogClose(true)}>{translate("products.yes")}</Button>
                </DialogActions>
            </Dialog>
            <div>
                {visible &&
                    <SimpleForm toolbar={false} sx={{marginBottom: "-60px"}}>
                        {selectedCompany ? <AutocompleteInput
                                sx={{width: "300px"}}
                                variant={"standard"}
                                source={"selectedCompany"}
                                choices={companies.data}
                                onChange={e => setSelectedCompany(e)}
                                defaultValue={selectedCompany}
                                label={"company.title"}
                                validate={required()}
                                required={true}
                                disableClearable
                            />
                            :
                            <EmptyPage text={"warehouse.empty"} createEnabled={false}/>
                        }
                    </SimpleForm>
                }
                {isShowRedirect ?
                    <div style={{marginTop: "20px"}}>
                        <RedirectToPurchase resource={"warehouse"}/>
                    </div>
                    :
                    selectedCompany && !loading &&
                    <>
                    <List title={"warehouse.title"}
                          pagination={<PinkPagination trigger={selectedCompany}/>}
                          filterDefaultValues={{posSetId: selectedCompany}}
                          tags={selected}
                          actions={<ListActions/>}>
                        <FilterForm filters={postFilter}/>
                        <ConfigurableDatagrid rowClick={hasEdit} bulkActionButtons={false}>
                            <TextField source="id" label={'warehouse.id'}/>
                            <FunctionField render={record => {
                                return(
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        <span>{record.name}</span>
                                        <IconButton onClick={(event) => {
                                            event.stopPropagation()
                                            window.open(`${url}/${record.id}`, '_blank', 'noopener,noreferrer')
                                        }}>
                                            <OpenInNewIcon/>
                                        </IconButton>
                                    </div>
                                )
                            }} source={"name"} label={'warehouse.name'}/>
                            <ArrayField source={"units"} label={'warehouse.productUnits'} sortable={false}>
                                <Datagrid rowClick={false} bulkActionButtons={false}>
                                    <TextField source="quantity" label={'warehouse.quantity'}/>
                                    <FunctionField render={record => `${record.unitName}`} label={"warehouse.qtyUnit"}
                                                   sortable={false}/>
                                </Datagrid>
                            </ArrayField>
                            <TextField source="mainProductGroup" label={'warehouse.mainProductGroup'}/>
                            <ArrayField source={"tags"} label={'warehouse.tags'} sortable={false}>
                                <SingleFieldList linkType={false}>
                                    <ChipField source={"name"}/>
                                </SingleFieldList>
                            </ArrayField>
                        </ConfigurableDatagrid>
                    </List>
                        <img src={shorticLogo} style={{position: "absolute", bottom: "0", left: "20", width: "150px", paddingBottom: '10px'}}/>
                    </>
                }
            </div>
        </>
    )
}

export default WarehouseProductList