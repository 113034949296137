import {BusinessUnitDetails} from "./BusinessUnitDetails";
import {Edit, useNotify, useRedirect} from 'react-admin';

export const BusinessUnitEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify("activePosSets.setActivePosSet", {type: "success"})
        redirect("/businessUnit")
        window.location.reload()
    }
    return (
        <Edit mutationMode="pessimistic" mutationOptions={{onSuccess}}>
            <BusinessUnitDetails />
        </Edit>
    )
}