import {
    BooleanField,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    List,
    SimpleForm,
    TextField, usePermissions,
    useRecordContext,
    useTranslate
} from "react-admin";
import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import {Typography} from "@mui/material";
import {EmptyPage} from "../../components/EmtpyPage";
import React, {useEffect, useState} from "react";
import SelectCompanyWithBusinessUnitInput from "../../components/SelectCompanyWithBusinessUnitInput";
import {PinkPagination} from "../../components/PinkPagination";


export const DeviceList = () => {
    const { permissions } = usePermissions()
    const hasEdit = getValidPrivilegesIds("DEVICE", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    const hasDelete = getValidPrivilegesIds("DEVICE", "DELETE").some(r => permissions.includes(r)) ? "edit" : undefined
    const [selectedPosSetId, setSelectedPosSetId] = useState(0)

    useEffect(() => {
        if (selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) {
            localStorage.setItem("devicePosSetId", selectedPosSetId)
        }
    }, [selectedPosSetId])

    const DeleteField = (props) => {
        const record = useRecordContext();
        const translate = useTranslate()
        if (hasDelete && !record.isShiftOpen) {
            return (
                <DeleteWithConfirmButton confirmTitle={"device.detachAreYouSure"}
                                         confirmContent={`${record.deviceId} ${record.deviceName}`}
                                         mutationMode={"pessimistic"} label={"device.remove"}/>
            )
        } else {
            if (hasDelete) {
                return (
                    <Typography>{translate("device.cantRemove")}</Typography>
                )
            }
        }

    }

    return (
        <div>
            <SimpleForm toolbar={false} sx={{width: '300px'}}>
                <SelectCompanyWithBusinessUnitInput getResource={"device"} setPosSet={setSelectedPosSetId}/>
            </SimpleForm>
            {(selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) &&
                <List title={"device.title"} empty={<EmptyPage text={"device.empty"}/>}
                      pagination={<PinkPagination trigger={selectedPosSetId}/>}
                      filterDefaultValues={{posSetId: selectedPosSetId}}
                >
                    <Datagrid bulkActionButtons={false} rowClick={hasEdit}>
                        <TextField source="id" label={'device.posId'}/>
                        <TextField source="posName" label={'device.posName'}/>
                        <TextField source="deviceId" label={'device.deviceId'}/>
                        <TextField source="deviceName" label={'device.deviceName'}/>
                        <DateField source="pricelistCheckTime" label={'device.priceListCheckTime'} showTime
                                   locales="hu-HU"/>
                        <DateField source="configCheckTime" label={'device.configCheckTime'} showTime locales="hu-HU"/>
                        <DateField source="lastCommunication" label={'device.lastCommunication'} showTime
                                   locales="hu-HU"/>
                        <BooleanField source={"isShiftOpen"} label={'device.shiftOpen'}/>
                        <DeleteField label={'device.removeShort'}/>
                    </Datagrid>
                </List>}
        </div>
    )
}