import Dialog from "@mui/material/Dialog"
import {Box, Button, CircularProgress, DialogTitle, IconButton} from "@mui/material"
import DialogContent from "@mui/material/DialogContent"
import React, {useEffect, useRef, useState} from "react"
import {SimpleForm, useDataProvider, useNotify, useTranslate} from "react-admin"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import * as XLSX from "xlsx"
import UploadFileIcon from "@mui/icons-material/UploadFile"

export const ProductUploadDialog = (props) => {
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const inputRef = useRef(null)
    const [excelFile, setExcelFile] = useState(null)
    const [fileName, setFileName] = useState("")
    const [units, setUnits] = useState([])
    const [vatGroups, setVatGroups] = useState([])
    const [mainCategories, setMainCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        dataProvider.get('product/unit').then(res => setUnits(res.data.content))
        dataProvider.get('product/vatGroup').then(res => setVatGroups(res.data.content))
        dataProvider.get('product/mainCategory').then(res => setMainCategories(res.data.content))
        dataProvider.get('product/subCategories').then(res => setSubCategories(res.data))
    }, [])

    const findObjectIdByName = (array, name) => {
        return array.find(item => item.name.toLowerCase() === name.toLowerCase())?.id
    }

    const findUnitIdByName = (array, name) => {
        const found = array.some(item => item.name.includes(name))
        if (!found) {
            return -1
        } else {
            return array.find(item => item.name === name)?.id
        }
    }

    const findVatId = vatGroupName => {
        if (vatGroupName === "adójegyes") {
            return findObjectIdByName(vatGroups, vatGroupName)
        }
        return findObjectIdByName(vatGroups, String((vatGroupName * 100).toFixed(0)) + '%')
    }

    const getMainAndSubcategoryId = (mainCategoryName, subCategoryName) => {
        const mainCategoryId = findObjectIdByName(mainCategories, mainCategoryName)
        const subCategoryId = subCategories.find(item => item.name.toLowerCase() === subCategoryName.toLowerCase() && item.parentCategory === mainCategoryId)?.id
        return [mainCategoryId, subCategoryId]
    }

    const getIdsFromErrorMessage = (text) => {
        const regex = /\d+/g;
        const numbers = [];
        let match
        while ((match = regex.exec(text)) !== null) {
            numbers.push(parseInt(match[0]));
        }
        if (numbers.length === 2) {
            return {
                productUnitId: numbers[0],
                productId: numbers[1]
            }
        } else {
            return null
        }
    }

    const handleFileChange = (e) => {
        let fileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']
        let selectedFile = e.target.files[0]
        setFileName(selectedFile.name)
        if (selectedFile && fileTypes.includes(selectedFile.type)) {
            let reader = new FileReader()
            reader.readAsArrayBuffer(selectedFile)
            reader.onload = (e) => {
                setExcelFile(e.target.result)
            }
        } else {
            setExcelFile(null)
            notify("products.fileExtensionError", {type: "error"})
        }
    }

    const handleFileSubmit = () => {
        if (excelFile !== null) {
            setDisabled(prevState => !prevState)
            const workbook = XLSX.read(excelFile, {type: 'array'});
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet, {header: 1});
            const headers = data[0];
            const headerMap = headers.reduce((acc, header, index) => {
                acc[header] = index;
                return acc;
            }, {});
            const keyToHeaderMap = {
                id: 'Termék ID',
                name: 'Termék megnevezése',
                mainCategoryId: 'NTAK főkategória',
                subCategoryId: 'NTAK alkategória',
                customCategory: 'Főkategória',
                vatId: 'Áfa',
                isTakeawayAllowed: 'Lehet-e elviteles?',
                takeawayVatId: 'Elviteles áfa (ha lehet elviteles)',
                units: 'Mértékegység ID',
                tags: 'Címkék'
            };
            const excelData = data.slice(1).filter(item => Object.keys(item).length > 1);
            const posSetId = worksheet['AA1'] ? worksheet['AA1'].v : null;
            if (posSetId !== props.selectedPosSetId) {
                for (let i = 0; i < excelData.length; i++) {
                    excelData[i][headerMap['Mértékegység ID']] = '';
                }
            }

            const products = [];
            for (let rowIndex = 0; rowIndex < excelData.length; rowIndex++) {
                const row = excelData[rowIndex];
                try {
                    const ids = getMainAndSubcategoryId(row[headerMap['NTAK főkategória']], row[headerMap['NTAK alkategória']]);
                    const customCategory = getCustomCategory(row, headerMap);
                    const product = {
                        id: row[headerMap['Termék ID']] || null,
                        name: row[headerMap['Termék megnevezése']],
                        posSetId: props.selectedPosSetId,
                        mainCategoryId: ids[0],
                        subCategoryId: ids[1],
                        customCategory: customCategory,
                        vatId: findVatId(row[headerMap['Áfa']]),
                        isTakeawayAllowed: row[headerMap['Lehet-e elviteles?']].toLowerCase() === "igen",
                        takeawayVatId: findVatId(row[headerMap['Elviteles áfa (ha lehet elviteles)']]) || null,
                        units: [{
                            id: row[headerMap['Mértékegység ID']],
                            unit: findUnitIdByName(units, row[headerMap['Mértékegység']]),
                            quantity: row[headerMap['Mennyiség']],
                            bulk: row[headerMap['Kimért']].toLowerCase() === "igen",
                            depositFee: row[headerMap['Egyutas palack']].toLowerCase() === "igen" ? "FIFTY" : "NONE",
                            defaultPrice: row[headerMap['ár (Ft)']]
                        }],
                        tags: row[headerMap['Címkék']] ? row[headerMap['Címkék']].split(';').map(item => item.trim()) : null
                    };

                    for (const [key, value] of Object.entries(product)) {
                        if (value === undefined) {
                            const columnName = keyToHeaderMap[key];
                            notify(`Hiba a ${rowIndex + 2}. sor, ${columnName} oszlopban`, {type: "error"});
                            return;
                        }
                    }

                    for (const unit of product.units) {
                        if (unit.unit === undefined) {
                            notify(`Hiba a ${rowIndex + 2}. sor, Mértékegység oszlopban: hibás mértékegység`, {type: "error"});
                            return;
                        }
                        if (isNaN(unit.quantity)) {
                            notify(`Hiba a ${rowIndex + 2}. sor, Mennyiség oszlopban: az érték nem szám`, {type: "error"});
                            return;
                        }
                        if (isNaN(unit.defaultPrice)) {
                            notify(`Hiba a ${rowIndex + 2}. sor, ár (Ft) oszlopban: az érték nem szám`, {type: "error"});
                            return;
                        }
                    }

                    if (product.isTakeawayAllowed && (product.takeawayVatId === null || product.takeawayVatId === undefined)) {
                        notify(`Hiba a ${rowIndex + 2}. sor, Elviteles áfa (ha lehet elviteles) oszlopban`, {type: "error"});
                        return;
                    }
                    products.push(product);
                } catch (error) {
                    const columnName = headers[error.columnIndex];
                    notify(`Hiba a  ${rowIndex + 2}. sorban. Helytelen, vagy nemlétező adat.`, {type: "error"});
                    return;
                }
            }

            dataProvider.create("product/saveProducts", {data: products}).then(value => {
                notify("products.saveSuccess", {type: "success"});
                setDisabled(prevState => !prevState)
                closeDialog();
            })
                .catch(reason => {
                    const ids = getIdsFromErrorMessage(reason.message);
                    if (ids !== null) {
                        notify(translate("productImportError", {
                            productUnitId: ids.productUnitId,
                            productId: ids.productId
                        }), {type: "error"});
                    } else if (reason.message.includes('Wrong unit')) {
                        notify("products.wrongUnit", {type: "error"});
                    } else {
                        notify("products.saveError", {type: "error"});
                    }
                    setDisabled(prevState => !prevState)
                });
        } else {
            closeDialog();
        }
    };

    function getCustomCategory(row, headerMap) {
        const categories = [row[headerMap['Főkategória']], row[headerMap['Alkategória']], row[headerMap['Alalkategória']]];

        if (categories.every(item => item === undefined)) {
            return null;
        }

        return categories
            .filter(item => item !== undefined)
            .join('/.')
    }

    const closeDialog = () => {
        setExcelFile(null)
        setFileName(null)
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
            <IconButton
                aria-label="close"
                onClick={() => closeDialog()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogTitle>{translate("products.import")}</DialogTitle>
            <DialogContent sx={{display: 'flex', justifyContent: 'center'}}>
                <SimpleForm toolbar={false} onSubmit={handleFileSubmit}>
                    <Button style={{color: "white", width: "200px"}} variant={"contained"} startIcon={<AddIcon/>}
                            onClick={() => (inputRef.current?.click())}>{translate("products.fileAdd")}</Button>
                    <input ref={inputRef} type="file" onChange={handleFileChange} style={{display: 'none'}}/>
                    <div style={{marginBottom: "20px"}}>{excelFile && fileName}</div>
                    <Box sx={{display: 'flex'}}>
                        <Button startIcon={<UploadFileIcon/>} sx={{color: "white", width: "200px", marginRight: '15px'}} variant={"contained"}
                                disabled={!excelFile || disabled} type={'submit'}>{translate("products.fileUpload")}</Button>
                        {disabled && <CircularProgress />}
                    </Box>
                </SimpleForm>
            </DialogContent>
        </Dialog>
    )
}