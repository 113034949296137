import {DeviceDetails} from "./DeviceDetails";
import {
    Edit
} from "react-admin";
export const DeviceEdit = () => {
    return (
        <Edit mutationMode="pessimistic">
            <DeviceDetails />
        </Edit>
    )
}