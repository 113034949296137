import {Datagrid, useTranslate} from "react-admin";
import React, {Fragment, useState} from "react";
import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";


const KEY_PREFIX = 'pink-conf-data-grid/';

export const clearConfigurableDatagrid = () => {
    const deletionKeys = [];
    for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).startsWith(KEY_PREFIX)) {
            deletionKeys.push(localStorage.key(i));
        }
    }

    for (let i = 0; i < deletionKeys.length; i++) {
        localStorage.removeItem(deletionKeys[i]);
    }
}

/**
 * Data grid with columns that can be hidden. The following attributes can be used by the children:
 *
 * - isHidden: boolean - sets whether the columns is hidden by default (default: false)
 * - canHide: boolean - sets whether the column can be hidden (default: true)
 *
 * @param props
 * @return {*}
 * @constructor
 */
const ConfigurableDatagrid = (props) => {

    const translate = useTranslate();

    const {children: rawChildren, customKey = '', ...rest} = props;

    const children = rawChildren.filter(item => !!item);

    const storageKey = `${KEY_PREFIX}${props.resource || props.basePath}` + (customKey?.length > 0 ? `/${customKey}` : '');

    let defaultValue = JSON.parse(localStorage.getItem(storageKey));

    if (defaultValue) {
        defaultValue = children
            .filter(item => {
                if (item.props.source) {
                    return item.props.canHide === false || defaultValue.indexOf(item.props.source) > -1
                }
                return false;
            })
            .map(item => item.props.source);
    } else {
        defaultValue = children.filter(item => !item.props.isHidden && item.props.source).map(item => item.props.source);
    }

    const [showing, setShowing] = useState(defaultValue);

    const handleChange = (event) => {
        setShowing(event.target.value);
        localStorage.setItem(storageKey, JSON.stringify(event.target.value));
    };

    return (
        <Fragment>
            <div style={{padding: '16px'}}>
                <FormControl variant={"filled"} style={{width: '200px'}}>
                    <InputLabel id="datagrid-columns-label">{translate("common.columns")}</InputLabel>
                    <Select multiple={true}
                            onChange={handleChange}
                            labelId="datagrid-columns-label"
                            value={showing}
                            renderValue={(selected) => `${selected.length} ${translate("common.columnSelected")}`}>
                        {children
                            .filter(item => /*item.props['canHide'] !== false && */item.props.source !== undefined)
                            .map(item => {
                                    const key = item.props.source;
                                    const label = item.props.label !== undefined ? item.props.label : item.props.source;
                                    const canHide = item.props.canHide !== false;

                                    return (
                                        <MenuItem key={key} value={key} disabled={!canHide}>
                                            <Checkbox checked={showing.indexOf(key) > -1}/>
                                            <ListItemText primary={translate(label)}/>
                                        </MenuItem>
                                    )
                                }
                            )}
                    </Select>
                </FormControl>
            </div>
            <Datagrid  {...rest}>
                {
                    children.filter(item => {
                        if (item.props.source === undefined || item.props.canHide === false) {
                            return true;
                        }

                        return showing.indexOf(item.props.source) > -1;
                    })
                }
            </Datagrid>
        </Fragment>
    )
}

export default ConfigurableDatagrid;