import {Button, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import React, {useState} from "react";
import {useTranslate} from "react-admin";

const Resignation = ({sendPurchase}) => {
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

        const [open, setOpen] = useState(false);
        const translate = useTranslate()

        const handleClickOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };

        const handleResignation = () => {
            sendPurchase('resignation')
            setOpen(false)
        }

        return (
            <>
                <Button sx={{marginY: '10px', color: 'red'}} onClick={handleClickOpen}>
                    {translate("resignation.title")}
                </Button>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        {translate("resignation.title")}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {translate("resignation.content")}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleResignation}>
                            {translate("resignation.resignation")}
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </>
        );
    }

export default Resignation