import React, {useEffect, useState, useMemo} from "react";
import Tree from "rc-tree";
import 'rc-tree/assets/index.css'
import './draggable.css'
import {Business, CreditCard, ExpandLess, ExpandMore, LocalBar, Save, Store, Storefront} from "@mui/icons-material";
import {
    AutocompleteInput,
    Create,
    FormDataConsumer,
    required,
    SimpleForm,
    TextInput,
    useDataProvider,
    useLocaleState,
    useNotify,
    useTranslate,
} from "react-admin";
import {Box, Button, Dialog, DialogTitle, FormLabel, LinearProgress, TextField, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useFormContext, useWatch} from "react-hook-form";
import AddIcon from '@mui/icons-material/Add';
import {uuidv4} from "../../tools/generate";
import {PinkToolbar} from "../../components/PinkToolbar";
import {groupTransform, traverse_tree} from "../../tools/groupTransform";
import {Title} from "../../components/Title";
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import UnfoldLessDoubleIcon from '@mui/icons-material/UnfoldLessDouble';
import Autocomplete from "@mui/material/Autocomplete";
import {Link} from "react-router-dom";

const groupTypes = [
    {id: '2', name: 'groups.bar'},
    {id: '3', name: 'groups.area'},
    {id: '4', name: 'groups.businessUnit'},
    {id: '5', name: 'groups.company'},
]


const SwitcherIcon = (obj) => {
    if (obj.isLeaf) {
        return (<div/>)
    }
    if (obj.expanded) {
        return (<div style={{backgroundColor: "#fafafb"}}><ExpandLess/></div>)
    } else {
        return (<div style={{backgroundColor: "#fafafb"}}><ExpandMore/></div>)
    }
}

const Icon = (obj) => {
    if (obj.data.level) {
        switch (obj.data.level) {
            case 1:
                return (<div><CreditCard/></div>)
            case 2:
                return (<LocalBar/>)
            case 3:
                return (<Storefront/>)
            case 4:
                return (<Store/>)
            case 5:
                return (<Business/>)
            default: break;
        }
    } else {
        return <div/>
    }
}


const Group = (reloadData) => {
    const notify = useNotify()
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const locale = useLocaleState()
    const [warehouses, setWarehouses] = useState([]);
    const [gData, setGData] = useState([])
    const [autoExpandParent, setAutoExpandParent] = useState(true)
    const [expandedKeys, setExpandedKeys] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [editNode, setEditNode] = useState({})
    const [addChild, setAddChild] = useState(false)
    const [addStorage, setAddStorage] = useState(false)
    const [warehouseId, setWarehouseId] = useState(null)
    const [expandableIds, setExpandableIds] = useState([])
    const [expanded, setExpanded] = useState(false)
    const [filteredGroupTypes, setFilteredGroupTypes] = useState(groupTypes);
    const {setValue} = useFormContext();
    const [companies, setCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState(null)

    const sortedGData = useMemo(() => {
        const data = [...gData];
        data.forEach(sortChildrenByName);
        return data;
    }, [gData]);

    useEffect(() => {
        dataProvider.get(`group/companies`)
            .then(value => {
                setCompanies(value.data.list)

                let selectedPosSetId
                if(value.data.defaultPosSetId !== null){
                    selectedPosSetId = parseInt(value.data.defaultPosSetId)
                }

                if (value.data.list.map( it => it.id).includes(parseInt(selectedPosSetId))) {
                    setSelectedCompany(value.data.list.find(obj => obj.id === selectedPosSetId))
                    loadGroupData(selectedPosSetId)
                } else {
                    setSelectedCompany(value.data.list[0])
                    loadGroupData(value.data.list[0].id)
                }
            })
            .catch(reason => {
                setCompanies(null)
            })
    }, []);

    useEffect(() => {
        if (selectedCompany !== null) {
            loadGroupData(selectedCompany.id)
        }
    }, [reloadData]);

    useEffect(() => {
        if (selectedCompany !== null) {
            loadGroupData(selectedCompany.id)
            setExpanded(false)
            setExpandedKeys([])
        }
    }, [selectedCompany]);

    function sortChildrenByName(node) {
        if (node.children) {
            node.children.sort((a, b) => a.name.localeCompare(b.name));
            node.children.forEach(sortChildrenByName);
        }
    }

    const loadGroupData = (id) => {
        setExpanded(false)
        dataProvider.get('group', {companyId: id})
            .then(value => {
                traverse_tree(value.data, setExpandableIds)
                Array.isArray(value.data) ? setGData(value.data) : setGData([value.data])
                setValue("trees", value.data)
            })
            .catch(reason => {
                setGData([])
            })
    }

    const handleSetAllWarehouseOnChildren = (element, warehouseId, warehouseName, red) =>{

        element.title = <Title title={element.name} currentName={warehouseName} style={(element.warehouseName && red) ? "redBg" : null}/>
        element.warehouseName = warehouseName
        element.warehouseId = warehouseId
        element.children?.forEach(el => handleSetAllWarehouseOnChildren(el, warehouseId, warehouseName, red))
        return element
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
        setFilteredGroupTypes(groupTypes)
    }

    const onDragStart = info => {
        console.log('start', info);
    };

    const handleExpandAll = () => {
        setExpanded(true)
        setExpandedKeys(expandableIds)
    };

    const handleCollapseAll = () => {
        setExpanded(false)
        setExpandedKeys([])
    };

    const findIdInTree = (subTree, id) => {
        let found = false

        if (subTree.id === id) {
            found = true
        } else {
            subTree.children?.forEach(el => {
                if (findIdInTree(el, id)) {
                    found = true
                }
            })
        }
        return found
    };

    const searchOpenShift = (subTree) => {
        let found = subTree.hasOpenShift

        subTree.children?.forEach(el => {
            if (searchOpenShift(el)) {
                found = true
            }
        })
        return found
    };

    const onDrop = (info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        //updatable alapértelmezetten: legfelső cég és van-e jog áthelyezni benne
        let updatable = gData[0].level === 5 && gData[0].updatable
        let openShift = searchOpenShift(info.dragNode)
        let oneBusinessUnit = false

        if (gData[0].level === 5) {
            gData[0].children.forEach(e => {
                oneBusinessUnit = oneBusinessUnit || (findIdInTree(e, dragKey) && findIdInTree(e, dropKey))
                if (oneBusinessUnit) {
                    updatable = updatable || e.updatable
                }
            })
        } else {
            //nem cég a felső, végig megyünk a bu-kon
            gData.forEach(e => {
                oneBusinessUnit = oneBusinessUnit || (findIdInTree(e, dragKey) && findIdInTree(e, dropKey))
                if (oneBusinessUnit) {
                    updatable = updatable || e.updatable
                }
            })
        }
        //bu alá ne tudja kihelyezni a dolgokat (bu-val egy szintre)
        if (gData[0].level !== 5 && info.node.level === 4 && (dropPosition === 1 || dropPosition === -1)) {
            updatable = false
        }

        if (updatable && (!openShift || oneBusinessUnit)) {
            if (((info.dragNode.level < info.node.level && info.node.level < 5) || // a higher level element can't ba child of a lower level element
                    (info.dragNode.level === info.node.level && dropPosition === 1)) || //same level elements can be reordered
                (info.node.level === 5 && dropPosition !== -1 && info.dragNode.level === 1)) { // there must be a singe root node

                const loop = (data, key, callback) => {
                    data.forEach((item, index, arr) => {
                        if (item.key === key) {
                            callback(item, index, arr);
                            return;
                        }
                        if (item.children) {
                            loop(item.children, key, callback);
                        }
                    });
                };
                const data = [...gData];

                // Find dragObject
                let dragObj;
                loop(data, dragKey, (item, index, arr) => {
                    arr.splice(index, 1);
                    dragObj = item;
                });
                handleSetAllWarehouseOnChildren(dragObj, null, null, true);
                if (!expandableIds.includes(dropKey)) {
                    setExpandableIds([...expandableIds, dropKey])
                    setExpandedKeys([...expandedKeys, dropKey])
                }
                if (dropPosition === 0) {
                    // Drop on the content
                    loop(data, dropKey, item => {
                        // eslint-disable-next-line no-param-reassign
                        item.children = item.children || [];
                        // where to insert
                        item.children.unshift(dragObj);
                    });
                } else {
                    // Drop on the gap (insert before or insert after)
                    let ar;
                    let i;
                    loop(data, dropKey, (item, index, arr) => {
                        ar = arr;
                        i = index;
                    });
                    if (dropPosition === -1) {
                        ar.splice(i, 0, dragObj);
                    } else {
                        ar.splice(i + 1, 0, dragObj);
                    }
                }
                setGData(data)
                setValue("trees", data)
            } else {
                notify(info.dropPosition === 0 ? "groups.cantDragBelowCompany" : "groups.cantDrag", {type: 'error'})
            }
        } else {
            notify(openShift ? "groups.cantDragWithOpenShift" : "groups.cantUpdate", {type: 'error'})
        }
    };

    const onExpand = (expandedKeys) => {
        if (expandedKeys.length === expandableIds.length) {
            setExpanded(true)
        }

        if (expandedKeys.length === 0) {
            setExpanded(false)
        }

        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    };

    const onSelect = (selectedKeys, e) => {
        dataProvider.get(`group/warehouses`, {posSetId: e.node.id, companyId: selectedCompany.id})
            .then(value => {
                setWarehouses(value.data)
                setValue("warehouseId", e.node.children?.length === 0 ? value.data.find(it => it.id === e.node.warehouseId)?.id : null)
            })
            .catch(reason => {
                setWarehouses([])
            })
        setEditNode(e.node)
        setFilteredGroupTypes(filteredGroupTypes.filter(it => it.id < e.node.level))
        setValue("parentName", e.node.name)
        setValue("childName", null)
        setValue("groupType", null)
        setAddChild(false)
        setAddStorage(false)
        setDialogOpen(true)
    }

    const handleEditNode = (e, formData) => {
        let error = false
        const warehouseName = warehouses.find(it => it.id === formData.warehouseId)?.name
        if(e.detail === 1) {
            formData.trees.map(obj => {
                return addChildren(obj, editNode.key, formData.parentName === undefined? editNode.name : formData.parentName, formData.groupType, formData.childName, formData.childId, formData.warehouseId, warehouseName)})
            setGData(formData.trees)
            setValue("trees", formData.trees)
            handleDialogClose()
            setValue("warehouseId", null)
            notify(!error ? "groups.editSuccessful" : "groups.editError", {type: !error ? 'warning' : 'error'})
            setFilteredGroupTypes(groupTypes)
        }
    }

    const handleDelete = () => {
        const tree = [...gData]
        tree.map(e => removeItem(e, editNode.key))
        setGData(tree)
        setValue("trees", tree)
        handleDialogClose()
    }

    const addChildren = (element, selected, title, level, childTitle, childId, warehouseId, warehouseName) => {
        if (element.key === selected) {
            if (title) {
                element.title = <Title title={title} currentName={warehouseName} />
                element.name = title
                element.warehouseId = warehouseId
                element.warehouseName = warehouseName
                if (warehouseName) {
                    handleSetAllWarehouseOnChildren(element, warehouseId, warehouseName, null)
                }
            }
            if (level && childTitle) {
                const key = uuidv4()
                if (element.children && element.children.length > 0) {
                    element.children.push({
                        uuid: key,
                        key: key,
                        title: <Title title={childTitle} currentName={warehouseName} />,
                        level: parseInt(level),
                        posId: childId,
                        name: childTitle,
                        warehouseId: warehouseId,
                        warehouseName: warehouseName
                    })
                } else {
                    element.children = [{
                        uuid: key,
                        key: key,
                        title: <Title title={childTitle} currentName={warehouseName} />,
                        level: parseInt(level),
                        posId: childId,
                        name: childTitle,
                        warehouseId: warehouseId,
                        warehouseName: warehouseName
                    }]
                    setExpandedKeys([...expandedKeys, element.key])
                    setExpandableIds([...expandableIds, element.key])
                }
            }
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = addChildren(element.children[i], selected, title, level, childTitle, childId, warehouseId, warehouseName);
            }
            return result;
        }
        return null;
    }

    const removeItem = (element, selected) => {
        let canBeDeleted = true
        if (element.children) {
            for (let i = element.children.length - 1; i >= 0; i--) {
                let temp = element.children[i]
                if (temp.key === selected) {
                    if (parseInt(temp.level) !== 1) {
                        //children pos should be removed
                        if (temp.children) {
                            temp.children.forEach(it => {
                                if (it.posId) {
                                    notify("groups.deleteUnsuccessful", {type: 'error'})
                                    canBeDeleted = false
                                }
                                if (it.children) {
                                    it.children.forEach(childrenOfChildren => {
                                        //children of children thats the deepest it can be deleted
                                        if (childrenOfChildren.posId) {
                                            notify("groups.deleteUnsuccessful", {type: 'error'})
                                            canBeDeleted = false
                                        }
                                    })
                                }
                                return it
                            })
                        }
                    }

                    if (canBeDeleted) {
                        element.children.splice(i, 1)
                        notify("groups.deleteSuccessful", {type: 'success'})
                    }

                }
            }
            element.children.forEach(child => removeItem(child, selected))
        }
    }

    return (
        <Box className={'container'} sx={{maxHeight:'65vh', minHeight: '35vh', overflowY: 'auto' }}>
        <div className="draggable-demo">
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <div className={"groupDialog"}>
                    <DialogTitle id="alert-dialog-title">
                        {translate('groups.editNode')}
                    </DialogTitle>
                    <TextInput source="parentName" label={"groups.name"} validate={required()}
                               inputProps={{maxLength: 255}} disabled={editNode.level >= 4}/>
                    {(editNode.level > 2 && editNode.level !== 5) ?
                        <Button variant={'contained'} style={{color: "white"}} color={"primary"}
                                onClick={() => setAddChild(!addChild)}>
                            <AddIcon/>
                            {locale[0] === "hu" ?
                                filteredGroupTypes.map(object => " " + translate(object.name)) + translate("groups.addChild") :
                                translate("groups.addChild") + filteredGroupTypes.map(object => " " + translate(object.name))
                            }
                        </Button>
                        : null
                    }
                    {addChild &&
                        <div style={{display: 'flex', flexDirection: 'column'}}>

                            <FormLabel style={{marginTop: '20px'}}>{translate("groups.type")}</FormLabel>
                            <AutocompleteInput source="groupType" label={"groups.type"} validate={required()}
                                               choices={filteredGroupTypes}/>
                            <FormDataConsumer>
                                {({formData, ...rest}) =>
                                    formData.groupType > 1 ?
                                        <TextInput source="childName" label={"groups.name"} validate={required()}
                                                   inputProps={{maxLength: 255}}/>
                                        :
                                        null
                                }
                            </FormDataConsumer>
                        </div>
                    }

                    <Button variant={'contained'} style={{color: "white", marginTop: "10px"}} color={"primary"}
                            onClick={() => setAddStorage(!addStorage)}><AddIcon/> {translate("groups.addStorage")}
                    </Button>

                    {addStorage &&
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <FormLabel style={{marginTop: '20px'}}>{translate("groups.storage")}</FormLabel>
                            <AutocompleteInput source="warehouseId" label={"groups.selectStorage"}
                                               choices={warehouses}
                            />
                        </div>
                    }

                    <FormDataConsumer>
                        {({formData, getSource, ...rest}) =>
                            <div className="editNodeToolbar">
                                <Button style={{marginTop: "8px", marginRight: "8px", color: "white"}}
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={event => handleEditNode(event, formData)}><Save/>{translate(addChild? "ra.action.add" : "ra.action.modify")}
                                </Button>
                                {((editNode.level === 2 || editNode.level === 3) && !addChild) ?
                                    <Button style={{marginTop: "8px", marginRight: "8px"}} variant={"outlined"}
                                            color={"secondary"}
                                            onClick={handleDelete}><DeleteIcon/>{translate("ra.action.delete")}</Button>
                                    :
                                    null
                                }

                            </div>
                        }
                    </FormDataConsumer>
                </div>
            </Dialog>
            <Typography style={{marginRight: "8px", marginBottom: "10px"}}
                        variant={"h4"}>{translate("groups.title")} </Typography>
            <Autocomplete
                options={companies || null}
                getOptionLabel={option => option.name || ""}
                onChange={(event, value) => {
                    setSelectedCompany(value)
                }}
                defaultValue={selectedCompany}
                value={selectedCompany}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={translate("companySelector.selectCompany")}
                        fullWidth
                    />
                )}
                style={{width: "300px"}}
                disableClearable
            />
            <div style={{display: "flex", marginTop: "20px", marginBottom: "10px"}}>
                {expanded ?
                    <>
                        <UnfoldLessDoubleIcon style={{color: "#ff71bc"}} onClick={handleCollapseAll}/>
                        <Typography variant={"p"}
                                    style={{verticalAlign: "middle"}}>{translate("groups.collapseAll")} </Typography>
                    </>
                    :
                    <>
                        <UnfoldMoreDoubleIcon style={{color: "#ff71bc"}} onClick={handleExpandAll}/>
                        <Typography variant={"p"}>{translate("groups.expandAll")} </Typography>
                    </>
                }

            </div>
            <div className="draggable-container">
                {gData && gData.length > 0 ?
                    <Tree
                        treeData={sortedGData}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        autoExpandParent={autoExpandParent}
                        draggable
                        onDragStart={onDragStart}
                        onDrop={onDrop}
                        showLine
                        onSelect={onSelect}
                        icon={Icon}
                        switcherIcon={SwitcherIcon}
                    />
                    :
                    <LinearProgress/>
                }
            </div>
        </div>
        </Box>
    );
}

export const GroupCreate = () => {
    const translate = useTranslate()
    const [reloadData, setReloadData] = useState(false)

    return (
        <>
        <Create title={"groups.title"} transform={groupTransform}>
            <SimpleForm toolbar={<PinkToolbar type={"button"} deleteEnabled={false} alwaysEnableSave={true}
                                              mutationOptions={{
                                                  onSuccess: () => {
                                                      setReloadData(!reloadData)
                                                  }
                                              }}/>}>
                <Group reloadData={reloadData}/>
            </SimpleForm>
        </Create>
            <span style={{marginTop: "40px", display: "flex"}}>
            <Typography variant={"inherit"} style={{marginRight: 5}}>{translate('groups.contact')}</Typography> <Link style={{color: '#ff71bc'}} to="https://pinkpos.com/#uzenet" target="_blank" >{translate("groups.click")}</Link>
            </span>
        </>
    )
}