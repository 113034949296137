import {minLength, regex, TextInput} from "react-admin";
import React from "react";

const validateCompanyRegistrationNumberFormat = regex(/^\d{2}-\d{2}-\d{6}$/, 'Helyes formátum: xx-yy-zzzzzzzz');

const companyRegistrationNumberFormat = (v) => {
    if (v === undefined || v === null) {
        return v;
    }

    v = v.trim().replace(/[^0-9]/g, '');

    const format = 'xx-yy-zzzzzzzz';

    let results = '';

    for (let i = 0, formatOffset = 0; i < v.length; i++) {
        if (format[i + formatOffset] === '-') {
            results += '-';
            formatOffset++;
        }

        results += v[i];
    }

    return results;
}

export const CompanyRegistrationNumberInput = ({...props}) => {
    return <TextInput validate={[validateCompanyRegistrationNumberFormat, minLength(12)]} format={companyRegistrationNumberFormat} parse={companyRegistrationNumberFormat}
                      inputProps={{maxLength: 12}} {...props}/>
}