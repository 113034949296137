import querystring from 'query-string';
import {fetchUtils} from "react-admin";
import {apiUrl} from "../config/SiteConfig";
import authProvider from "./cognito/authProvider";

const httpClient = async (url, options = {}) => {
    await authProvider.checkAuth()
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    options.user = {
        authenticated: true,
        // use the token from local storage
        token: 'Bearer ' + localStorage.getItem('token')
    };
    return fetchUtils.fetchJson(url, options)
};

export default {
    get: (resource, params = {}) => {
        const queryString = {...params};//transformQueryParams(params);

        const url = `${apiUrl}/${resource}?${querystring.stringify(queryString)}`;
        return httpClient(url).then(({json}) => ({data: json}));
    },
    getOne: (resource, params = {}) => {
        const metaJSON = JSON.stringify(params.meta)?.replaceAll('"', "").replaceAll(":", "=").replaceAll("{", "").replaceAll("}", "")
        const url = params.meta ? `${apiUrl}/${resource}/${params.id}?${metaJSON}` : `${apiUrl}/${resource}/${params.id}`;
        return httpClient(url).then(({json}) => ({data: json}));
    },
    getList: (resource, params) => {
        const {field, order} = params.sort || {};
        // mutiple sort params
        const {page, perPage} = params.pagination || {};

        //needs hack for multiple sort
        const queryString = {
            'size': perPage,
            'page': page ? page - 1 : undefined,
            'sort': params.sort ? field + ',' + order : undefined,
            ...params.filter
        };

        const url = `${apiUrl}/${resource}?${querystring.stringify(queryString)}`;

        return httpClient(url).then(({headers, json}) => ({
            data: json.content,
            total: json.totalElements
        }));
    },
    getMany: (resource, params) => {
        const queryString = {
            filter: params.ids ? JSON.stringify({id: params.ids}) : []
        };

        const url = `${apiUrl}/${resource}?${querystring.stringify(queryString)}`;
        return httpClient(url).then(({json}) => ({data: json}));
    },
    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({
            data: {...params.data, id: json.id, response: json},
        })),
    postQuery: (resource, params) =>{
        const string ={...params}
       return httpClient(`${apiUrl}/${resource}?${querystring.stringify(string)}`, {
            method: 'POST',
        }).then(({json}) => ({data: json}))},
    update: (resource, params) =>
        params.id ?
            resource === 'user' && params.data.isTechnical ?
                httpClient(`${apiUrl}/${resource}/technical/${params.id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                }).then(({json}) => ({data: json}))
            :
                httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                }).then(({json}) => ({data: json}))

            : httpClient(`${apiUrl}/${resource}`, {
                method: 'PUT',
                body: JSON.stringify(params),
            }).then(({json}) => ({data: json})),
    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({json}) => ({data: params.id})),
}