import {Edit, SimpleForm, FormDataConsumer, useTranslate} from "react-admin";
import {UserDetails} from "./UserDetails";
import {userTransform} from "../../tools/userTransform";
import {PinkToolbar} from "../../components/PinkToolbar";
import React from "react";

export const UserEdit = () => {
    const posSetId = localStorage.getItem("userPosSetId")
    const translate = useTranslate()

    const validateUserEdit = (values) => {
        const errors = {}
        if(!values.isTechnical){
            if(!(values.updatedPrivileges?.find(it => it.posSetsAdmin?.length > 0 || it.posSetsApi?.length > 0 ))){
                errors.updatedPrivileges = translate("technicalUsers.validation.noSelectedPosSet")
            }
        }else {
            if(!values.name || values.name?.length === 0){
                errors.name = translate("technicalUsers.validation.nameNull")
            }
            if(values.pinCode) {
                //console.log(values)
                if (values.pinCode !== values.pinCodeAgain) {
                    errors.pinCode = translate("technicalUsers.validation.pinDontMatch")
                }else if (values.pinCode?.length !== 4) {
                    errors.pinCode = translate("technicalUsers.validation.pinWrongLength")
                }else if (isNaN(values.pinCode)) {
                    errors.pinCode = translate("technicalUsers.validation.pinNotANumber")
                }
            }
            if(values.hpPosUserId) {
                if(values.hpPosUserId.length < 4){
                    errors.hpPosUserId = translate("technicalUsers.validation.hpPosUserIdWrongLength")
                }else if(isNaN(values.hpPosUserId)){
                    errors.hpPosUserId = translate("technicalUsers.validation.hpPosUserIdNotANumber")
                }
            }
        }
        return errors
    }
    return (
        <Edit mutationMode="pessimistic" transform={userTransform} queryOptions={{ meta:{posSetId:posSetId}}} >
            <SimpleForm mode="onBlur" toolbar={<PinkToolbar alwaysEnableSave={true} deleteEnabled={false} />} validate={validateUserEdit} reValidateMode={"onBlur"}>
                <FormDataConsumer>
                    {({formData}) => (
                    <UserDetails edit={true} formData={formData}/>
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    )
}