import {CreateButton, ExportButton, SelectColumnsButton, TopToolbar} from "react-admin";

export const PinkActions = ({hasCreate}) => {
    return (
        <TopToolbar>
            <SelectColumnsButton />
            {hasCreate &&
            <CreateButton/>
            }
            <ExportButton/>
        </TopToolbar>
    )
}