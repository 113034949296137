import {Create} from 'react-admin';
import {PriceListTemplateDetails} from "./PriceListTemplateDetails";

export const PriceListTemplateCreate = (props) => {
    // FIXME common code with Edit
    const transform = (data) => {
        let sanitizedData = Object.assign({}, data);
        console.log("data:", sanitizedData.products)
        sanitizedData.posSetId = localStorage.getItem("priceListPosSetId")
        if (sanitizedData.products !== undefined) {
            console.log(sanitizedData.products.includes(undefined));
            for (let i = 0; i < sanitizedData.products.length; i++) {
                console.log("item:", sanitizedData.products[i].id)
                if (typeof sanitizedData.products[i].id === "string" && sanitizedData.products[i].id.startsWith('x')) sanitizedData.products[i].id = null;
                //remove 'x' prefix and set null
            }
            console.log("sanitizedData:", sanitizedData)
        }
        return sanitizedData;
    };
    return (
        <Create redirect="list" transform={transform}>
            <PriceListTemplateDetails  {...props} />
        </Create>
    )
}