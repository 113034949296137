import {Card, CardContent, Typography} from "@mui/material";
import {useTranslate} from "react-admin";

const CardWithNoCompany = () => {
    const translate = useTranslate()
    return (
        <Card sx={{ width: 400, marginTop: "40px" }}>
            <CardContent>
                <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                    {translate("purchase.title")}
                </Typography>
                <br/>
                <Typography sx={{fontSize: 16}} variant="body2">
                    {translate(`purchase.noCompany`)}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default CardWithNoCompany