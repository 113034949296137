import React, {useEffect, useState} from "react";
import Tree from "rc-tree";
import 'rc-tree/assets/index.css'
import '../groups/draggable.css'
import {Business, CreditCard, ExpandLess, ExpandMore, LocalBar, Save, Storefront, Store} from "@mui/icons-material";
import {
    useNotify,
    SimpleForm,
    useTranslate,
    FormDataConsumer,
    required,
    AutocompleteInput,
    Create, useDataProvider, useRedirect, useStore
} from "react-admin";
import {
    Button,
    Dialog, DialogContent,
    DialogTitle,
    FormLabel,
    LinearProgress,
    Typography
} from "@mui/material";
import {useFormContext, useWatch} from "react-hook-form";
import AddIcon from '@mui/icons-material/Add';
import {PinkToolbar} from "../../components/PinkToolbar";
import {traverse_tree} from "../../tools/groupTransform";
import CloseIcon from '@mui/icons-material/Close';
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import UnfoldLessDoubleIcon from '@mui/icons-material/UnfoldLessDouble';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import moment from "moment-timezone";
import SelectCompanyWithBusinessUnitInput from "../../components/SelectCompanyWithBusinessUnitInput";
import RedirectToPurchase from "../../components/RedirectToPurchase";


const SwitcherIcon = (obj) => {
    if (obj.isLeaf) {
        return (<div/>)
    }
    if (obj.expanded) {
        return (<div style={{backgroundColor: "#fafafb"}}><ExpandLess/></div>)
    } else {
        return (<div style={{backgroundColor: "#fafafb"}}><ExpandMore/></div>)
    }
}

const Icon = (obj) => {
    if (obj.data.level) {
        switch (obj.data.level) {
            case 1:
                return (<div><CreditCard/></div>)
            case 2:
                return (<LocalBar/>)
            case 3:
                return (<Storefront/>)
            case 4:
                return (<Store/>)
            case 5:
                return (<Business/>)
            default: break;
        }
    } else {
        return <div/>
    }
}


const PriceListToPosSet = () => {
    const notify = useNotify()
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const [data, setData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [priceListData, setPriceListData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [priceListInstancesData, setPriceListInstancesData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [selectedPosSetId, setSelectedPosSetId] = useState(0)
    const [gData, setGData] = useState([])
    const [autoExpandParent, setAutoExpandParent] = useState(true)
    const [expandedKeys, setExpandedKeys] = useStore('priceListToPosSetexpKeys', [])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [editNode, setEditNode] = useState({})
    const [changePriceList, setChangePriceList] = useState(false)
    const [isShowRedirect, setIsShowRedirect] = useState(false)
    const [expandableIds, setExpandableIds] = useState([])
    const [expanded, setExpanded] = useState(false)
    const {setValue} = useFormContext();
    const redirect = useRedirect()
    const priceListId = useWatch({ name: 'priceListId' });
    const priceListInstanceId = useWatch({ name: 'priceListInstanceId' });
    const format = "YYYY.MM.DD HH:mm"

    useEffect(() => {
        if (selectedPosSetId !== 0) {
            setExpanded(false);
            setExpandedKeys([]);
            refreshPriceList()
        }
    }, [selectedPosSetId]);

    useEffect(()=>{
        if (selectedPosSetId !== 0) {
            refreshPriceList()
        }
    }, [data.loaded])

    useEffect(() => {
        if (editNode.id) {
            setPriceListData({...priceListData, loading: true});
            dataProvider.get(`priceListToPosSet/pricelist-non-pageable`, {posSetId:`${editNode.id}`, sort: "name,ASC"})
                .then(value => {
                    setPriceListData({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setPriceListData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [editNode.id]);

    useEffect(() => {
        if (priceListId) {
            setPriceListInstancesData({...priceListInstancesData, loading: true});
            dataProvider.get(`priceListToPosSet/pricelist-instances`, {priceListTemplateId:`${priceListId}`, sort: "id,DESC"})
                .then(value => {
                    setPriceListInstancesData({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setPriceListInstancesData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [priceListId]);


    const handleDialogClose = () => {
        setDialogOpen(false)
        setValue("priceListId", null)
        setValue("priceListInstanceId", null)
    }

    const refreshPriceList=()=>{
        setData({ ...data, loading: true });
        dataProvider.get('priceListToPosSet', { posSetId: selectedPosSetId })
            .then(value => {
                setData({loading: false, loaded: true, data: value.data, error: undefined });
                traverse_tree(value.data, setExpandableIds);
                setGData(value.data);
                setValue("tree", value.data);
            })
            .catch(reason => {
                setData({ loading: false, loaded: true, data: undefined, error: reason });
            });
    }

    const handleChangePriceList = () => {
        setChangePriceList(true)
    };

    const handleRouting = () => {
        if(editNode.priceListId) {
            redirect(`/priceList/${editNode.priceListId}/show`)
        }
    };

    const handleExpandAll = () => {
        setExpanded(true)
        setExpandedKeys(expandableIds)
    };

    const handleCollapseAll = () => {
        setExpanded(false)
        setExpandedKeys([])
    };

    const onExpand = (expandedKeys) => {
        if (expandedKeys.length === expandableIds.length) {
            setExpanded(true)
        }

        if (expandedKeys.length === 0) {
            setExpanded(false)
        }

        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    };

    const onSelect = (selectedKeys, e) => {
        setEditNode(e.node)
        setValue("priceListName", null)
        setChangePriceList(false)
        setDialogOpen(true)
    }

    const handleEditNode = (e) => {
        //to fix double click error
        if (e.detail === 1) {
            const tree = gData[0]
            setGData([tree])
            setValue("tree", tree)

            handleDialogClose()
            dataProvider.create('priceListToPosSet', {data: {posSetId: editNode.id, priceListId: priceListInstanceId}})
                .then(value => {
                    setData({...data, loaded: false})
                    notify("priceListToPosSets.updated", {type: "success"})
                    setValue("priceListId", null)
                    setValue("priceListInstanceId", null)
                })
                .catch(reason => {
                    console.log(reason)
                    notify("priceListToPosSets.error", {type: "error"})

                })

        }
    }

    const matchSuggestion = (filter, choice) => {
        return (
            choice.name.toLowerCase().includes(filter.toLowerCase())
        );
    };

    return (
        <div className="draggable-demo">

            <Dialog open={dialogOpen} fullWidth={true} maxWidth={"xs"} onClose={handleDialogClose}>
                <div className={"groupDialog"}>
                    <DialogTitle id="alert-dialog-title">
                        {editNode.name} - {translate('priceListToPosSets.priceList')}
                    </DialogTitle>
                    <DialogContent>
                        {editNode.priceListName &&
                        <FormLabel>{translate("priceListToPosSets.currentPriceList")}</FormLabel>
                        }
                        <div style={{display: "flex", flexDirection: "column"}}>
                            {editNode.priceListName &&
                            <Button style={{marginBottom: "8px"}} onClick={handleRouting} variant={"outlined"}>
                                <VisibilityIcon/> <Typography>{editNode.priceListName}</Typography>
                            </Button>
                            }
                            {!changePriceList && editNode.priceListName &&
                            <Button style={{marginBottom: "16px", color: "white"}} variant={'contained'} color={"primary"}
                                    onClick={handleChangePriceList}><AutorenewIcon/> {translate("priceListToPosSets.changePriceList")}
                            </Button>
                            }

                            {!changePriceList && !editNode.priceListName &&
                            <Button style={{marginBottom: "16px", color: "white"}} variant={'contained'} color={"primary"}
                                    onClick={handleChangePriceList}><AddIcon/> {translate("priceListToPosSets.newPriceList")}
                            </Button>
                            }
                        </div>
                        {changePriceList &&
                        <>
                            <FormLabel
                                style={{paddingTop: "8px"}}>{translate("priceListToPosSets.newPriceList")}</FormLabel>
                                <AutocompleteInput source="priceListId"
                                                   choices={priceListData.data}
                                                   optionText={"name"}
                                                   fullWidth={true}
                                                   label={"priceListTemplate.template"}
                                                   validate={required()}
                                                   matchSuggestion={matchSuggestion}
                                />
                            {priceListId ?
                                <>
                                    <Typography></Typography>
                                    <AutocompleteInput
                                        source="priceListInstanceId"
                                        choices={priceListInstancesData.data}
                                        optionText={((record)=> {
                                            return  moment(new Date(record.createdAt)).format(format) + ' (' + record.id + ')'
                                        })}
                                        fullWidth={true}
                                        label={"priceListTemplate.nameShort"}
                                    />
                                </>
                                :
                                null
                            }


                            <FormDataConsumer>
                                {({formData, ...rest}) =>
                                    <div className="editNodeToolbar">
                                        {priceListInstanceId ?
                                            <Button style={{marginTop: "8px", marginRight: "8px", color: "white"}}
                                                    variant={"contained"}
                                                    color={"primary"}
                                                    onClick={event => handleEditNode(event, formData)}><Save/>{translate("ra.action.save")}
                                            </Button>
                                            :
                                            null
                                        }

                                        <Button style={{marginTop: "8px", marginRight: "8px"}} variant={"outlined"}
                                                color={"error"}
                                                onClick={event => handleDialogClose(event, formData)}><CloseIcon/>{translate("ra.action.close")}
                                        </Button>

                                    </div>
                                }
                            </FormDataConsumer>
                        </>
                        }
                    </DialogContent>
                </div>
            </Dialog>


            <Typography style={{marginRight: "8px"}} variant={"h4"}>{translate("priceListToPosSets.title")} </Typography>
            <SelectCompanyWithBusinessUnitInput getResource={"priceListToPosSet"} setPosSet={setSelectedPosSetId} setIsShowRedirect={setIsShowRedirect}/>

            <div style={{display: "flex", marginTop: "8px", marginBottom: "8px"}}>
                {expanded ?
                    <>
                        <UnfoldLessDoubleIcon style={{color:"#ff71bc"}}onClick={handleCollapseAll}/>
                        <Typography variant={"p"}
                                    style={{verticalAlign: "middle"}}>{translate("groups.collapseAll")} </Typography>
                    </>
                    :
                    <>
                        <UnfoldMoreDoubleIcon style={{color:"#ff71bc"}} onClick={handleExpandAll}/>
                        <Typography variant={"p"}>{translate("groups.expandAll")} </Typography>
                    </>
                }

            </div>
            <div className="draggable-container">
                {gData && gData.length > 0 ?
                    <Tree
                        treeData={gData}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        autoExpandParent={autoExpandParent}
                        showLine
                        onSelect={onSelect}
                        icon={Icon}
                        switcherIcon={SwitcherIcon}
                    />
                    : isShowRedirect ?
                        <RedirectToPurchase/>
                    :
                    <LinearProgress/>
                }
            </div>
        </div>
    );
}

export const SendPriceListToPosSet = () => {
    return (
        <Create title={"priceListToPosSets.title"} redirect={false}>
            <SimpleForm toolbar={<PinkToolbar noSaveButton={true} deleteEnabled={false} />}>
                <PriceListToPosSet/>
            </SimpleForm>
        </Create>
    )
}