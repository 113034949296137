import React, {useEffect, useState} from "react";
import SelectCompanyWithBusinessUnitInput from "../../../components/SelectCompanyWithBusinessUnitInput";
import MultipleWarehouseSelectWithBox from "../../../components/MultipleWarehouseSelectWithBox";
import StockpileGrid from "./StockpileGrid";
import RedirectToPurchase from "../../../components/RedirectToPurchase";
import {Box} from "@mui/material";
import {Title} from "react-admin";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";

const WarehouseStockList = () => {
    const [posSet, setPosSet] = useState(0)
    const [sendData, setSendData] = useState({selectedWarehouses: [], start: null})
    const [trigger, setTrigger] = useState(false)
    const [isShowRedirect, setIsShowRedirect] = useState(false)

    useEffect(() => {
        if (posSet && posSet !== "undefined" && parseInt(posSet) !== 0) {
            localStorage.setItem("stockpile", posSet)
        }
    }, [posSet])

    return (
        <>
            <Title title={"stockpile.title"}/>
            <div style={{display: 'flex', alignItems:'center', gap: '20px'}}>
            <SelectCompanyWithBusinessUnitInput getResource={"stockpile"} setPosSet={setPosSet}
                                                setIsShowRedirect={setIsShowRedirect}/>
            <img src={shorticLogo}
                 style={{width: '150px', height: '32px'}}/>

            </div>
    {
        isShowRedirect ?
            <RedirectToPurchase resource={"warehouse"}/>
            :
            <Box>
            <MultipleWarehouseSelectWithBox getResource={"stockpile"} getLocales={"stockpile"} posSetId={posSet} setSendData={setSendData} setTrigger={setTrigger}/>
            <StockpileGrid posSetId={posSet} warehouses={sendData.selectedWarehouses} dateTime={sendData.start} trigger={trigger}/>
            </Box>
    }
        </>
    );
}

export default WarehouseStockList;