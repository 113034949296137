import {Create, useDataProvider, useNotify, useRedirect} from "react-admin";
import {BusinessUnitDetails} from "./BusinessUnitDetails";

export const BusinessUnitCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider()

    const onSuccess = (data) => {
        dataProvider.get("businessUnit").then(result => {
                notify("activePosSets.setActivePosSet", {type: "success"})
                redirect("/businessUnit")
                window.location.reload()
            }
                ).catch(reason => {
                notify("activePosSets.setActivePosSet", {type: "error"})
                redirect("/businessUnit")
            }
        )
    };

    return (
        <Create mutationOptions={{onSuccess}}>
            <BusinessUnitDetails />
        </Create>
    )
}