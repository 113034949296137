import {Create, SimpleForm, FormDataConsumer, useRedirect, useNotify} from "react-admin";
import {UserDetails} from "./UserDetails";
import {userTransform} from "../../tools/userTransform";
import {PinkToolbar} from "../../components/PinkToolbar";
import React from "react";

export const TechnicalUserCreate = () => {
    const notify = useNotify()
    const redirect = useRedirect()
    const onSuccess = () => {
        redirect(`/user`);
    };
    const onError = (error) => {
        error.message === "It is not possible to save more users" ? notify("technicalUsers.limit", {type: "error"}) : notify("technicalUsers.error", {type: "error"})
    }
    return (
        <Create transform={userTransform} resource={"user/technical"} mutationOptions={{ onSuccess: onSuccess, onError: onError}}>
            <SimpleForm toolbar={<PinkToolbar alwaysEnableSave={true} deleteEnabled={false} />} mode={"all"}>
                <FormDataConsumer>
                    {({formData}) => (
                     <UserDetails formData={formData} edit={false} />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
}