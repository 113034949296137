import Dialog from "@mui/material/Dialog"
import { Button, DialogTitle, IconButton } from "@mui/material"
import DialogContent from "@mui/material/DialogContent"
import React, { useEffect, useRef, useState } from "react"
import { SimpleForm, useDataProvider, useNotify, useTranslate } from "react-admin"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import * as XLSX from "xlsx"
import UploadFileIcon from "@mui/icons-material/UploadFile"

export const WarehouseOperationsUploadDialog = (props) => {
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const inputRef = useRef(null)
    const [excelFile, setExcelFile] = useState(null)
    const [fileName, setFileName] = useState("")

    const handleFileChange = (e) => {
        let fileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']
        let selectedFile = e.target.files[0]
        setFileName(selectedFile.name)
        if (selectedFile && fileTypes.includes(selectedFile.type)) {
            let reader = new FileReader()
            reader.readAsArrayBuffer(selectedFile)
            reader.onload = (e) => {
                setExcelFile(e.target.result)
            }
        } else {
            setExcelFile(null)
            notify("products.fileExtensionError", { type: "error" })
        }
    }

    const handleFileSubmit = () => {
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, { type: 'array' })
            const worksheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[worksheetName]
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
            const excelData = data.map(row => 
                row.map(cell => (cell === undefined ? '' : cell))
            ).map(row => 
                row.filter((_, index) => ![9, 10, 11].includes(index))
            ).filter(item => Object.keys(item).length > 1);
            const warehouseOperations = excelData.map(prop => ({
                name: prop[0],
                unit: prop[1],
                quantity: prop[2],
                vat: prop[3],
                netUnitPrice: prop[4],
                grossUnitPrice: prop[5],
                discount: prop[6],
                netSum: prop[7],
                grossSum: prop[8]
            })).slice(1)
            const warehouseOperationsWithPositiveQuantites = warehouseOperations.filter(item => item.quantity !== 0)
            localStorage.setItem("importedWarehouseOperations", JSON.stringify(warehouseOperationsWithPositiveQuantites))
            closeDialog()
    }
}

    const closeDialog = () => {
        setExcelFile(null)
        setFileName(null)
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
            <IconButton
                aria-label="close"
                onClick={() => closeDialog()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle>{translate("operations.import")}</DialogTitle>
            <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                <SimpleForm toolbar={false} onSubmit={handleFileSubmit}>
                    <Button style={{ color: "white", width: "200px" }} variant={"contained"} startIcon={<AddIcon />}
                        onClick={() => (inputRef.current?.click())}>{translate("products.fileAdd")}</Button>
                    <input ref={inputRef} type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                    <div style={{ marginBottom: "20px" }}>{excelFile && fileName}</div>
                    <Button startIcon={<UploadFileIcon />} style={{ color: "white", width: "200px" }} variant={"contained"}
                        disabled={!excelFile} type={'submit'}>{translate("products.fileUpload")}</Button>
                </SimpleForm>
            </DialogContent>
        </Dialog>
    )
}

