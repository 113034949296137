import {
    Button,
    Confirm,
    Datagrid,
    FunctionField,
    List, Pagination,
    TextField,
    useNotify,
    useRefresh,
    useTranslate
} from "react-admin";
import React, {useState} from "react";
import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import dataProvider from "../../components/dataProvider";


const ShiftClosureList = ({permissions}) => {

    const translate = useTranslate()
    const notify = useNotify()
    const refresh = useRefresh()
    const [rowId, setRowId] = useState(0)
    const hasClose = getValidPrivilegesIds("SHIFT", "CLOSE", true).some(r => permissions.includes(r))

    const [open, setOpen] = useState(false)
    const handleClick = (id) => {
        setOpen(true)
        setRowId(id)
    }
    const handleDialogClose = ()  => {
        setOpen(false)
    }
    const handleConfirm = () => {
        const data = {shiftId: rowId}
        dataProvider.create(`shift`, {data})
            .then(({data}) => {
                notify(translate("shiftClosure.success"), {type: "success"})
                refresh()
            }).catch(() => {
            notify(translate("shiftClosure.error"), {type: "error"})
        })
        setOpen(false)
    }

    return (
        <div>
            <List title={"shiftClosure.title"} pagination={<Pagination rowsPerPageOptions={[10, 50, 100, 500]}/>} exporter={false}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="posId" label={"shiftClosure.posId"}/>
                    <TextField source="posName" label={"shiftClosure.posName"}/>
                    <TextField source="buId" label={"shiftClosure.buId"}/>
                    <TextField source="buName" label={"shiftClosure.buName"}/>
                    <TextField source="companyId" label={"shiftClosure.companyId"}/>
                    <TextField source="companyName" label={"shiftClosure.companyName"}/>
                    <FunctionField render={record => {
                        return (
                            <div>
                                <Button label={"shiftClosure.closing"} variant={"outlined"} onClick={() => handleClick(record.id)}
                                        disabled={!hasClose}/>
                                <Confirm
                                    isOpen={open}
                                    title={"shiftClosure.closing"}
                                    content={"shiftClosure.warning"}
                                    onConfirm={handleConfirm}
                                    onClose={handleDialogClose}
                                />
                            </div>)
                    }}/>
                </Datagrid>
            </List>
        </div>
    )
}

export default ShiftClosureList