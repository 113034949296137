import {
    TextInput,
    SimpleForm
} from "react-admin";
import {PinkToolbar} from "../../components/PinkToolbar";
import React from "react";

export const DeviceDetails = () => {
    return (
        <SimpleForm toolbar={<PinkToolbar deleteEnabled={false} />}>
            <TextInput source={"deviceName"} inputProps={{maxLength: 255}} label={"device.deviceName"}/>
        </SimpleForm>
    )
}