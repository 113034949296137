import React from 'react';
import {
    DeleteWithConfirmButton,
    Edit,
    SaveButton,
    SimpleForm,
    Toolbar,
    useNotify,
    useRecordContext,
    useTranslate,
} from "react-admin";
import ProductDetails from "./ProductDetails";
import {Button, Typography} from "@mui/material";
import {useFormContext} from "react-hook-form";
import dataProvider from "../../components/dataProvider";

const ProductEdit = (props) => {
    const ToolbarWithDelete = () => {
        const translate = useTranslate();
        const record = useRecordContext();
        const form = useFormContext()
        const notify = useNotify();
        const posSetId = localStorage.getItem("productPosSetId")
        const saveNewProduct = () => {
            const data = form.getValues()
            delete data.id
            data.posSetId = posSetId
            data.units = data.units.map(unit => {
                delete unit.id
                if (unit.unitComponents) {
                    unit.unitComponents = unit.unitComponents.map(component => {
                        delete component.id
                        if (component.modifiers) {
                            component.modifiers = component.modifiers.map(modifier => {
                                delete modifier.id
                                return modifier
                            })
                        }
                        return component
                    })
                }
                return unit
            })
            dataProvider.create("product", {data})
                .then(value => {
                    notify("products.created", {type: "success"})
                })
                .catch(reason => {
                    console.log(reason)
                    notify("products.error", {type: "error"})
                })
        }
        return <Toolbar>
            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
             <div>
            <SaveButton style={{color: "#fff"}} alwaysEnable={true}/>
            <DeleteWithConfirmButton
                translateOptions={{ name: record.name }}
            />
            </div>
             <Button onClick={saveNewProduct}
                     variant="contained"
                     color={"secondary"}>
                    <Typography variant={"button"}>{translate("products.addNew")}</Typography>
             </Button>
            </div>
        </Toolbar>
    }

    return (
        <Edit mutationMode="pessimistic" {...props} redirect={"list"} title={'products.create'}>
            <SimpleForm toolbar={<ToolbarWithDelete />}>
                <ProductDetails permissions={props.permissions} />
            </SimpleForm>
        </Edit>
    )

}
export default ProductEdit