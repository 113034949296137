import React, {useEffect} from "react";
import RecursiveTreeView from "../../components/RecursiveTreeView";
import {useDataProvider,useRecordContext} from "react-admin";
import {useFormContext} from "react-hook-form";
import {LinearProgress} from "@mui/material";


export const DefaultUserRolesWithPosSets = ({index, formData, updatedResourceName, source, disableAlreadySelected}) => {
                const dataProvider = useDataProvider()
                const [data, setData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
                const [selected, setSelected] = React.useState(index.posSets ? index.posSets : []);
                const {setValue} = useFormContext();
                const userPosSetId = localStorage.getItem("userPosSetId")


                useEffect(() => {
                        if (!data.loading && !data.loaded) {
                                setData({...data, loading: true});
                                dataProvider.get(source.concat(`/posSets/${userPosSetId}`))
                                    .then(value => {
                                            setData({loading: false, loaded: true, data: value.data, error: undefined});
                                    })
                                    .catch(reason => {
                                            setData({loading: false, loaded: true, data: undefined, error: reason});
                                    })
                        }
                }, [data.loading, data.loaded]);

                useEffect(() => {
                      index.posSets = selected
                    setValue(updatedResourceName, formData.roles)
                }, [selected, index, formData.roles]);

                if (data.loading || !data.loaded) {
                        return (
                            <LinearProgress/>
                        )
                }
                if(index.id) {
                        return (
                            <div style={{display: 'inline-flex', flexDirection: 'column'}}>
                                    <RecursiveTreeView data={data.data} selected={selected} setSelected={setSelected}
                                                       disabledList={disableAlreadySelected ? index.posSets : []}/>
                            </div>

                        )
                }

}