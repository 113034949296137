import {
    ArrayField,
    BooleanField,
    Button,
    Datagrid,
    DateField,
    FunctionField,
    Show,
    SimpleShowLayout,
    TextField,
    useLocaleState,
    useShowController,
    useTranslate
} from "react-admin";
import React, {useEffect, useState} from "react";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import DownloadIcon from '@mui/icons-material/Download';
import {Box} from "@mui/material";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";

const InventoryShow = () => {
    const translate = useTranslate()
    const locale = useLocaleState()
    const {record} = useShowController()
    const [sumNetAmount, setSumNetAmount] = useState(null)
    const [sumGrossAmount, setSumGrossAmount] = useState(null)

    useEffect(() => {
        if (record) {
            setSumNetAmount(calculateSumNetAmount(record.warehouseProducts))
            setSumGrossAmount(calculateSumGrossAmount(record.warehouseProducts))
        }
    }, [record])

    const calculateSumNetAmount = (warehouseProducts) => {
        return warehouseProducts?.reduce((total, product) => {
            return total + (product.quantity * product.avgNetPrice);
        }, 0);
    }

    const calculateSumGrossAmount = (warehouseProducts) => {
        return warehouseProducts?.reduce((total, product) => {
            return total + (product.quantity * product.avgGrossPrice);
        }, 0);
    }

    const exportInventory = () => {
        const headers = [
            translate("inventory.product"),
            translate("inventory.unitName"),
            translate("inventory.stock"),
            translate("inventory.quantity"),
            translate("operations.netto"),
            translate("operations.brutto"),
            translate("operations.sumNetto"),
            translate("operations.sumBrutto"),
            translate("inventory.difference")
        ]

        const additionalInfo = [
            [translate("inventory.chosenWarehouse"), record.warehouseName],
            [translate("inventory.date"), dayjs(record.inventoryDate).format('YYYY.MM.DD HH:mm'),],
            [translate("inventory.type"), record.partInventory === true ? translate("common.yes") : translate("common.no")],
            [translate("inventory.comment"), record.comment],
            [translate("inventory.sumNetto"), sumNetAmount.toFixed(2)],
            [translate("inventory.sumBrutto"), sumGrossAmount.toFixed(2)],
        ]

        let exportedInventory = []
        record.warehouseProducts.forEach(item => {
            let arr = [
                item.warehouseProductName,
                item.unitName,
                item.stock,
                item.quantity,
                item.avgNetPrice.toFixed(2),
                item.avgGrossPrice.toFixed(2),
                (item.avgNetPrice * item.quantity).toFixed(2),
                (item.avgGrossPrice * item.quantity).toFixed(2),
                item.difference,
            ]
            exportedInventory.push(arr)
        })
        let wscols = [
            {wch: 15}, {wch: 10},
            {wch: 10}, {wch: 12},
            {wch: 12}, {wch: 12},
            {wch: 12}, {wch: 12},
            {wch: 10}, {wch: 10},
            {wch: 19}, {wch: 15},
        ]
        exportedInventory.unshift(headers)
        const ws = XLSX.utils.aoa_to_sheet(exportedInventory);
        ws['!cols'] = wscols;
        XLSX.utils.sheet_add_aoa(ws, additionalInfo, {origin: "K1"})
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "export");
        XLSX.writeFile(wb, "export.xlsx");
    }

    return (
        <>
            <Show>
                <SimpleShowLayout>
                    <TextField source="warehouseName" label={'inventory.chosenWarehouse'}/>
                    <DateField locales={locale[0]} showTime source="inventoryDate" label={"inventory.date"}/>
                    <BooleanField source="partInventory" label={"inventory.type"}/>
                    <TextField source="comment" label={'inventory.comment'}/>
                    <ArrayField source="warehouseProducts" label={false}>
                        <Button startIcon={<DownloadIcon/>} label={'stockpile.exportStockpile'}
                                onClick={() => exportInventory()}/>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="warehouseProductName" label={'inventory.product'} sortable={false}/>
                            <TextField source="unitName" label={"inventory.unitName"} sortable={false}/>
                            <TextField source="stock" label={'inventory.stock'} sortable={false}/>
                            <TextField source="quantity" label={'inventory.quantity'} sortable={false}/>
                            <FunctionField source="avgNetPrice" label={'operations.netto'} sortable={false}
                                           render={record => record.avgNetPrice.toFixed(2)}/>
                            <FunctionField source="avgGrossPrice" label={'operations.brutto'} sortable={false}
                                           render={record => record.avgGrossPrice.toFixed(2)}/>
                            <FunctionField label={'operations.sumNetto'}
                                           render={record => (record.quantity * record.avgNetPrice).toFixed(2)}
                                           sortable={false}/>
                            <FunctionField label={'operations.sumBrutto'}
                                           render={record => (record.quantity * record.avgGrossPrice).toFixed(2)}
                                           sortable={false}/>
                            <FunctionField
                                label="inventory.difference"
                                render={(record) => {
                                    return <span
                                        style={{color: record.difference < 0 ? 'red' : 'green'}}>{record.difference < 0 ? `${translate("inventory.shortage")} ${record.difference}` : `${translate("inventory.surplus")} ${record.difference}`}</span>;
                                }}
                            />
                        </Datagrid>
                    </ArrayField>
                    {sumNetAmount && sumGrossAmount &&
                        <div style={{textAlign: 'right', fontSize: '14px'}}>
                            <Box><b>{translate('inventory.sumNetto').concat(": ").concat(sumNetAmount.toFixed(2))}</b></Box>
                            <Box><b>{translate('inventory.sumBrutto').concat(": ").concat(sumGrossAmount.toFixed(2))}</b></Box>
                        </div>
                    }
                </SimpleShowLayout>
            </Show>
            <img src={shorticLogo}
                 style={{
                     position: "relative",
                     bottom: "0",
                     left: "20",
                     width: "150px",
                     paddingBottom: '10px',
                     paddingTop: '10px'
                 }}/>
        </>
    )
}

export default InventoryShow