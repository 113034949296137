import React, {useEffect, useState} from 'react';
import {
    ArrayField,
    AutocompleteArrayInput,
    BooleanField,
    ChipField,
    CreateButton,
    Datagrid,
    ExportButton,
    FunctionField,
    List,
    ReferenceField,
    SimpleForm,
    SingleFieldList,
    TextField,
    TextInput,
    TopToolbar,
    useDataProvider,
    usePermissions,
    useRefresh,
    useTranslate,
} from "react-admin";
import ConfigurableDatagrid from "../../components/ConfigurableDatagrid";
import SelectCompanyWithBusinessUnitInput from "../../components/SelectCompanyWithBusinessUnitInput";
import Dialog from '@mui/material/Dialog';
import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ClearIcon from '@mui/icons-material/Clear';
import {Box, Button, DialogTitle, IconButton} from "@mui/material";
import {PinkPagination} from "../../components/PinkPagination";
import removeAccents from "../../components/removeAccents";
import {ProductUploadDialog} from "./ProductUploadDialog";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Tooltip from "@mui/material/Tooltip";
import * as XLSX from 'xlsx';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import dataProvider from "../../components/dataProvider";

const ProductList = () => {
    const {permissions} = usePermissions()
    const dataProvider = useDataProvider()
    const hasEdit = getValidPrivilegesIds("PRODUCT", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    const hasCreate = getValidPrivilegesIds("PRODUCT", "CREATE").some(r => permissions.includes(r))
    const [tag, setTag] = useState({loading: false, loaded: false, data: [], error: undefined});
    const [selected, setSelected] = useState([])
    const [open, setOpen] = useState(false)
    const [fileUploadOpen, setFileUploadOpen] = useState(false)
    const [deleteTagId, setDeleteTagId] = useState(null)
    const [nameSearch, setNameSearch] = useState(null)
    const [selectedPosSetId, setSelectedPosSetId] = useState(0)
    const [vatGroups, setVatGroups] = useState(null)
    const [units, setUnits] = useState(null)
    const [simplifiedBu, setSimplifiedBu] = useState(false)
    const refresh = useRefresh()
    const translate = useTranslate()
    const url = window.location.href.split('?')[0]


    useEffect(() => {
        if (!tag.loading && !tag.loaded && selectedPosSetId !== 0) {
            setTag({...tag, loading: true});
            dataProvider.get(`product/tag`, {posSetId: selectedPosSetId, sort: "name,ASC"})
                .then(value => {
                    setTag({
                        loading: false,
                        loaded: true,
                        data: value.data.sort((a, b) => removeAccents(a.name) > removeAccents(b.name) ? 1 : -1),
                        error: undefined
                    });
                })
                .catch(reason => {
                    setTag({loading: false, loaded: true, data: [], error: reason});
                })
        }
    }, [tag.loading, tag.loaded, selectedPosSetId]);

    useEffect(() => {
        if (selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) {
            localStorage.setItem("productPosSetId", selectedPosSetId)
        }
        setTag({...tag, loading: false, loaded: false})
        setSelected([])
    }, [selectedPosSetId])

    useEffect(() => {
        dataProvider.get('product/vatGroup').then(res => setVatGroups(res.data.content))
        dataProvider.get('product/unit').then(res => setUnits(res.data.content))
    }, []);

    const handleDialogClose = toDelete => {
        setOpen(false)
        if (toDelete && deleteTagId != null) {
            dataProvider.delete(`product/tag`, {id: deleteTagId})
                .finally(() => {
                    setTag({...tag, loading: false, loaded: false})
                    refresh()
                });
        }
        setDeleteTagId(null)
    }

    const handleFileUploadDialogClose = () => {
        setFileUploadOpen(false)
        refresh()
        setTag({...tag, loaded: false})
    }

    const handleDialogOpen = () => {
        setOpen(true)
    }

    const handleFileUploadDialogOpen = () => {
        setFileUploadOpen(true)
    }

    const handleDeleteButton = (removedId) => e => {
        handleDialogOpen()
        e.stopPropagation()
        setDeleteTagId(removedId)
    }

    const percentageStringToNumber = (percentageString) => {
        const number = parseFloat(percentageString.replace('%', ''));
        return number / 100;
    }

    const optionRenderer = choice => {
        return <span>
        {choice.name}
            {!(selected.some(e => e === choice.id)) && (<ClearIcon className={"notDraggable"}
                                                                   onClick={handleDeleteButton(`${choice.id}`)}
                                                                   fontSize={"small"}
                                                                   sx={{
                                                                       marginBottom: "0px",
                                                                       color: "red",
                                                                       position: "absolute",
                                                                       right: "5px"
                                                                   }}
            />)}
    </span>
    }

    const matchSuggestion = (filter, choice) => {
        return (
            choice.name?.toLowerCase().includes(filter?.toLowerCase())
        )
    }
    const ListActions = () => {
        return (
            <TopToolbar>
                {hasCreate && <CreateButton label={"products.create"}/>}
                <ExportButton/>
                {hasCreate && <Button onClick={handleFileUploadDialogOpen} sx={{
                    padding: '4px 5px',
                    fontSize: '13px'
                }}><UploadFileIcon/> {translate("products.import")}</Button>}
            </TopToolbar>
        )
    }

    const exporter = products => {
        const headers = ["Termék ID", "Termék megnevezése", "NTAK főkategória", "NTAK alkategória", "Áfa",
            "Lehet-e elviteles?", "Elviteles áfa (ha lehet elviteles)", "Mértékegység ID", "Mértékegység", "Mennyiség", "Kimért", "Egyutas palack", "ár (Ft)", "Címkék"
        ]
        const additionalInfo = [
            ["posSetId", selectedPosSetId]
        ]
        let exportedProducts = []
        products.forEach(product => {
            let tags = product.tags ? product.tags.map(tag => tag.name) : null
            let vat = percentageStringToNumber(vatGroups.find(item => item.id === product.vatId)?.name)
            product.units.forEach(unit => {
                let arr = [
                    product.id,
                    product.name,
                    product.mainCategoryName,
                    product.subCategoryName,
                    isNaN(vat) ? "adójegyes" : vat,
                    product.isTakeawayAllowed ? translate("common.yes") : translate("common.no"),
                    product.takeawayVatId ? percentageStringToNumber(vatGroups.find(item => item.id === product.takeawayVatId)?.name) : null,
                    unit.id,
                    units.find(item => item.id === unit.unit)?.name,
                    unit.quantity,
                    unit.bulk ? translate("common.yes") : translate("common.no"),
                    unit.depositFee === "FIFTY" ? "Igen" : "",
                    unit.defaultPrice,
                    tags ? tags.join('; ') : null
                ]

                exportedProducts.push(arr)
            })
            if (product.units.length === 0) {
                let arr = [
                    product.id,
                    product.name,
                    product.mainCategoryName,
                    product.subCategoryName,
                    isNaN(vat) ? "adójegyes" : vat,
                    product.isTakeawayAllowed ? translate("common.yes") : translate("common.no"),
                    product.takeawayVatId ? percentageStringToNumber(vatGroups.find(item => item.id === product.takeawayVatId)?.name) : null,
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    tags ? tags.join('; ') : null
                ]
                exportedProducts.push(arr)
            }
        })
        exportedProducts.unshift(headers)
        const ws = XLSX.utils.aoa_to_sheet(exportedProducts);
        XLSX.utils.sheet_add_aoa(ws, additionalInfo, {origin: "Z1"})
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "export");
        XLSX.writeFile(wb, "export.xlsx");
    }

    return (
        <>
            <Dialog open={open} onClose={handleDialogClose}>
                <DialogTitle>{translate("products.dialogTitle")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {translate("products.dialogContextText")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)}>{translate("products.no")}</Button>
                    <Button onClick={() => handleDialogClose(true)}>{translate("products.yes")}</Button>
                </DialogActions>
            </Dialog>
            <ProductUploadDialog open={fileUploadOpen} onClose={handleFileUploadDialogClose}
                                 selectedPosSetId={selectedPosSetId}/>
            <SimpleForm toolbar={false}>
                <Box sx={{display: 'flex'}}>
                    <SelectCompanyWithBusinessUnitInput getResource={"product"} setPosSet={setSelectedPosSetId}
                                                        setSimplifiedBu={setSimplifiedBu}/>
                    {simplifiedBu && <Tooltip title={
                        <span style={{fontSize: "14px"}}>
                            {translate("products.setSimplifiedBu")}
                            </span>
                    }
                                              placement="right" arrow
                    >

                        <IconButton>
                            <AutoFixHighIcon sx={{color: "#ff71bc"}}/>
                        </IconButton>
                    </Tooltip>}
                </Box>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <TextInput label="products.nameFilter" name={"nameSearch"}
                               onChange={e => setNameSearch(e.target.value)} source={"nameSearch"}/>
                    <AutocompleteArrayInput label={'products.filterTags'} onChange={(e) => setSelected(e)}
                                            source="tags" choices={tag.data}
                                            optionText={optionRenderer} matchSuggestion={matchSuggestion}
                                            name={"tags"}/>

                </div>
            </SimpleForm>
            {(selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) &&
                <List filter={{nameSearch: nameSearch}}
                      exporter={exporter}
                      actions={<ListActions/>}
                      pagination={<PinkPagination trigger={selectedPosSetId} tags={selected}/>}
                      empty={false} filterDefaultValues={{posSetId: selectedPosSetId}}
                >
                    <ConfigurableDatagrid rowClick={hasEdit} bulkActionButtons={false}>
                        <TextField source="id" label={'products.id'}/>
                        <FunctionField render={record => {
                            return (
                                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                    <span>{record.name}</span>
                                    <IconButton onClick={(event) => {
                                        event.stopPropagation()
                                        window.open(`${url}/${record.id}`, '_blank', 'noopener,noreferrer')
                                    }}>
                                        <OpenInNewIcon/>
                                    </IconButton>
                                </div>
                            )
                        }} source={"name"} label={'products.name'}/>
                        <ReferenceField label={'products.mainCategory'} reference={"product/mainCategory"} link={false}
                                        source={"mainCategoryId"}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <ReferenceField label={'products.subCategory'} reference={"product/subCategory"} link={false}
                                        source={"subCategoryId"}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <ReferenceField label={'products.vatGroup'} reference={"product/vatGroup"} link={false}
                                        source={"vatId"}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <BooleanField source="isTakeawayAllowed" label={'products.isTakeawayAllowed'}/>
                        <ReferenceField label={'products.takeawayVatGroup'} reference={"product/vatGroup"} link={false}
                                        source={"takeawayVatId"}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <ArrayField source={"units"} label={'products.productUnits'} sortable={false}>
                            <Datagrid rowClick={false} bulkActionButtons={false}>
                                <FunctionField render={record => `${record.quantity} ${record.unitName}`}
                                               label={"products.qtyUnit"} sortable={false}/>
                                <TextField source="defaultPrice" label={'products.defaultPrice'} sortable={false}/>
                                <BooleanField source={"bulk"} label={'products.bulkItem'} sortable={false}/>
                            </Datagrid>
                        </ArrayField>
                        <ArrayField source={"tags"} label={'products.tags'} sortable={false}>
                            <SingleFieldList linkType={false}>
                                <ChipField source={"name"}/>
                            </SingleFieldList>
                        </ArrayField>
                    </ConfigurableDatagrid>
                </List>}
        </>
    )
}

export default ProductList