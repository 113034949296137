import React, {useEffect, useState} from "react";
import MultipleWarehouseSelectWithBox from "../../../components/MultipleWarehouseSelectWithBox";
import SelectCompanyWithBusinessUnitInput from "../../../components/SelectCompanyWithBusinessUnitInput";
import {Create, SaveButton, SimpleForm, Toolbar, useNotify, useTranslate} from "react-admin";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {Box, Button, Checkbox, Typography} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RedirectToPurchase from "../../../components/RedirectToPurchase";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";

const CreateStockChangeReport = () => {
    const [posSet, setPosSet] = useState(0)
    const [sendData, setSendData] = useState({selectedWarehouses: [], start: null, end: null})
    const [report, setReport] = useState()
    const [groupingByWarehouse, setGroupingByWarehouse] = useState(false)
    const [isShowRedirect, setIsShowRedirect] = useState(false)
    const translate = useTranslate()
    const notify = useNotify()

    useEffect(() => {
        if (posSet && posSet !== "undefined" && parseInt(posSet) !== 0) {
            localStorage.setItem("stock-change-report", posSet)
        }
    }, [posSet])

    useEffect(() => {
        setReport(null)
    }, [sendData])
    const ReportToolbar = () => {

        return (
            <Toolbar>
                <SaveButton
                    alwaysEnable={sendData.selectedWarehouses.length > 0 && sendData.start && sendData.end}
                    label="report.add"
                    icon={<AssessmentIcon/>}
                    mutationOptions={{
                        onSuccess: (data) => {
                            notify('report.created');
                            const contentType = data.response.contentType
                            const base64Data = data.response.file
                            const fileName = data.response.fileName
                            const linkSource = `data:${contentType};base64,${base64Data}`;
                            setReport({file: linkSource, fileName: fileName})
                        }
                    }
                    }
                    type="button"
                    variant="text"
                />
                {report ?
                    <>
                        <a download={report.fileName} href={report.file}
                           style={{textDecoration: "none", marginLeft: "16px"}}>
                            <Button variant={"contained"} style={{color: "white"}} color={"primary"}>
                                <FileDownloadIcon/> {translate('report.download')}
                            </Button>
                        </a>
                        <Typography style={{marginLeft: "16px"}} variant={"h6"}>{report.fileName}</Typography>
                    </>
                    :
                    null
                }
            </Toolbar>
        )
    }

    const transform = formData => ({
        selectedWarehouses: sendData.selectedWarehouses,
        start: sendData.start.format(),
        end: sendData.end.format(),
        groupingByWarehouse: groupingByWarehouse
    });

    return (
        <>
            <SelectCompanyWithBusinessUnitInput getResource={"stock-change-report"} setPosSet={setPosSet}
                                                setIsShowRedirect={setIsShowRedirect}/>
            {isShowRedirect ?
                <RedirectToPurchase resource={"warehouse"}/>
                :
                <>
                    <Create transform={transform}>
                        <SimpleForm toolbar={<ReportToolbar/>}>
                            <MultipleWarehouseSelectWithBox getResource={"stock-change-report"}
                                                            getLocales={"stockReport"}
                                                            posSetId={posSet} setSendData={setSendData}/>
                            <Box style={{display: "flex", alignItems: "center", marginTop: 20}}>
                                <Checkbox value={groupingByWarehouse} onChange={() => {
                                    setGroupingByWarehouse(!groupingByWarehouse)
                                }}/>
                                <Typography>{translate('report.groupingByWarehouse')}</Typography>
                            </Box>
                        </SimpleForm>
                    </Create>
                    <img src={shorticLogo} style={{
                        position: "relative",
                        bottom: "0",
                        left: "20",
                        width: "150px",
                        paddingBottom: '10px',
                        paddingTop: '10px'
                    }}/>
                </>
            }
        </>
    )
}

export default CreateStockChangeReport;