import {Button, Card, CardActions, CardContent, Typography} from "@mui/material";
import {useRedirect, useTranslate} from "react-admin";

const BuyWarehouseCard = () => {
    const translate = useTranslate()
    const redirect = useRedirect()
    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {translate("products.ingredientsTitle")}
                </Typography>
                <Typography variant="body2">
                    {translate("products.ingredients")}
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={()=>{redirect("/license")}} size="small">{translate("products.continueToBuy")}</Button>
            </CardActions>
        </Card>
    )
}

export default BuyWarehouseCard