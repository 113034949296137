import React, {useEffect, useState} from 'react';
import {DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import {useTranslate} from "react-admin";
import {ThemeProvider} from "@mui/material/styles";
import {muiDatagridTheme} from "../../../components/MuiDatagridTheme";
import {Box, Button, Typography} from "@mui/material";
import dataProvider from "../../../components/dataProvider";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import bigDecimal from "js-big-decimal";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from "xlsx";


const StockpileGrid = ({posSetId, dateTime, warehouses, trigger}) => {
    const [data, setData] = useState([]);
    const translate = useTranslate()
    const [params, setParams] = useState({
        page: 0,
        pageSize: 10,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0)
    const [aggregatedStockValue, setAggregatedStockValue] = useState(0)

    useEffect(() => {
        if (posSetId && dateTime && warehouses?.length > 0) {
            dataProvider.get(`stockpile`, {
                posSetId,
                dateTime: dateTime?.format(),
                warehouses,
                page: params.page,
                size: params.pageSize
            })
                .then((value) => {
                    setData(value.data?.content.map((item) => {
                        item.unit = item.units.find((unit) => unit.id === item.unitId)
                        return item
                    }))
                    setRowCount(value.data?.totalElements)
                    setIsLoading(false)
                })
                .catch((error) => {
                    setData([])
                    console.log(error)
                })

            dataProvider.get(`stockpile/aggregated`, {
                posSetId,
                dateTime: dateTime?.format(),
                warehouses
            })
                .then((value) => {
                    setAggregatedStockValue(value.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [trigger, params]);

    useEffect(() => {
        setData([])
    }, [warehouses, dateTime])

    const columns = [
        {field: 'id', headerName: 'ID', headerClassName: 'pinkHeader', width: 60, sortable: false},
        {
            field: 'warehouseProductName',
            headerName: translate("stockpile.warehouseName"),
            headerClassName: 'pinkHeader',
            width: 230,
            sortable: false
        },
        {
            field: 'tags',
            headerName: translate("stockpile.tags"),
            headerClassName: 'pinkHeader',
            width: 220,
            sortable: false,
            renderCell: (params) => {

                return <div style={{display: "flex", width: "100%", flexWrap: "wrap"}}>
                    {params.row.tags.map((tag, index) => {
                        return (
                            <Typography key={index} sx={{
                                fontSize: "13px",
                                borderRadius: "20px",
                                margin: "2px",
                                backgroundColor: "rgba(231,231,234,0.8)",
                                padding: "8px"
                            }}>{tag}</Typography>
                        )
                    })}
                </div>
            }

        },
        {
            field: 'units',
            headerName: translate("stockpile.packaging"),
            headerClassName: 'pinkHeader',
            width: 200,
            sortable: false,
            renderCell: (params) => {

                return (
                    <Autocomplete
                        sx={{width: "100%"}}
                        value={params.row.unit || null}
                        getOptionLabel={option => option.name || ""}
                        renderInput={(params) => <TextField {...params} label={translate("stockpile.packaging")}
                                                            variant={"standard"}/>}
                        options={params.row.units}
                        onChange={(event, value) => {
                            if (value === null) return
                            dataProvider.get(`stockpile/unit`, {
                                posSetId,
                                warehouses,
                                dateTime: dateTime?.format(),
                                warehouseProductId: params.row.id,
                                unitId: value.id
                            })
                                .then((d) => {
                                    params.row.unit = value
                                    params.row.stock = d.data.stock
                                    params.row.avgUnitNetPrice = d.data.avgUnitNetPrice
                                    params.row.avgUnitGrossPrice = d.data.avgUnitGrossPrice
                                    params.row.lastUnitNetPrice = d.data.lastUnitNetPrice
                                    setData([...data])
                                })

                        }
                        }
                    />
                );
            }
        },
        {
            field: 'stock',
            headerName: translate("stockpile.stock"),
            headerClassName: 'pinkHeader',
            width: 180,
            sortable: false,
            renderCell: (params) => {
                let stock = bigDecimal.round(params.row.stock, 2)
                return (
                    <Typography sx={{fontSize: "15px"}}>{stock}</Typography>
                )
            }
        },
        {
            field: 'avgUnitNetPrice',
            headerName: translate("stockpile.netAveragePrice"),
            headerClassName: 'pinkHeader',
            width: 180,
            sortable: false,
            renderCell: (params) => {
                let avgUnitNetPrice = bigDecimal.round(params.row.avgUnitNetPrice, 2)
                return (
                    <Typography sx={{fontSize: "15px"}}>{avgUnitNetPrice}</Typography>
                )
            }
        },
        {
            field: 'avgUnitGrossPrice',
            headerName: translate("stockpile.grossAveragePrice"),
            headerClassName: 'pinkHeader',
            width: 180,
            sortable: false,
            renderCell: (params) => {
                let avgUnitNetPrice = bigDecimal.round(params.row.avgUnitGrossPrice, 2)
                return (
                    <Typography sx={{fontSize: "15px"}}>{avgUnitNetPrice}</Typography>
                )
            }
        },
        {
            field: 'lastUnitNetPrice',
            headerName: translate("stockpile.lastNetPrice"),
            headerClassName: 'pinkHeader',
            width: 198,
            sortable: false,
            renderCell: (params) => {
                let lastUnitNetPrice = bigDecimal.round(params.row.lastUnitNetPrice, 2)
                return (
                    <Typography sx={{fontSize: "15px"}}>{lastUnitNetPrice}</Typography>
                )
            }
        },
        {
            headerName: translate("stockpile.stockValue"),
            headerClassName: 'pinkHeader',
            width: 198,
            sortable: false,
            renderCell: (params) => {
                let avgUnitNetPrice = new bigDecimal(params.row.avgUnitNetPrice)
                let stock = new bigDecimal(params.row.stock)
                let stockValue = avgUnitNetPrice.multiply(stock)
                return (
                    <Typography sx={{fontSize: "15px"}}>{stockValue.round(2).value}</Typography>
                )
            }
        }
    ]
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Button startIcon={<FileDownloadIcon/>} onClick={() => exportStockPile()}
                        variant='text'>{translate("stockpile.exportStockpile")}</Button>
                <Typography sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    position: "absolute",
                    right: "25rem",
                    bottom: "1rem"
                }}>     {`${translate("stockpile.aggregatedStockValue")}: ${aggregatedStockValue}`}
                </Typography>
            </GridToolbarContainer>
        );
    }

    const exportStockPile = () => {
        const headers = [translate("stockpile.warehouseName"), translate("stockpile.tags"), translate("stockpile.packaging"), translate("stockpile.stock"),
            translate("stockpile.netAveragePrice"), translate("stockpile.grossAveragePrice"), translate("stockpile.lastNetPrice"), translate("stockpile.stockValue")]
        let exportedProducts = []
        dataProvider.get(`stockpile`, {
            posSetId,
            dateTime: dateTime?.format(),
            warehouses,
            page: params.page,
            size: 2000
        }).then(value => {
            value.data.content.forEach(item => {
                let avgUnitNetPrice = new bigDecimal(item.avgUnitNetPrice)
                let stock = new bigDecimal(item.stock)
                let stockValue = avgUnitNetPrice.multiply(stock)
                let arr = [
                    item.warehouseProductName,
                    item.tags ? item.tags.join(', ') : null,
                    item.units.find(unit => unit.id === item.unitId).name,
                    item.stock,
                    item.avgUnitNetPrice ? item.avgUnitNetPrice.toFixed(2) : 0,
                    item.avgUnitGrossPrice ? item.avgUnitGrossPrice.toFixed(2) : 0,
                    item.lastUnitNetPrice ? item.lastUnitNetPrice.toFixed(2) : 0,
                    stockValue.round(2).value
                ]
                exportedProducts.push(arr)
            })
            let wscols = [
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:18},
                {wch:10},
                {wch:24},
            ]
            const additionalInfo = [
                [translate("stockpile.aggregatedStockValue"), aggregatedStockValue],
            ]
            exportedProducts.unshift(headers)
            const ws = XLSX.utils.aoa_to_sheet(exportedProducts);
            ws['!cols'] = wscols;
            XLSX.utils.sheet_add_aoa(ws, additionalInfo, {origin: "J1"})
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "export");
            XLSX.writeFile(wb, "export.xlsx");
        })
    }

    return (
        <>
            {data.length > 0 &&
                <Box
                    sx={{
                        height: 600,
                        width: '100%',
                        '& .pinkHeader': {
                            backgroundColor: '#ff71bc',
                            color: 'white'
                        },
                    }}
                >
                    <ThemeProvider theme={muiDatagridTheme}>
                        <DataGrid
                            rows={data}
                            columns={columns}
                            paginationModel={params}
                            onPaginationModelChange={(newModel) => {
                                setIsLoading(true)
                                setParams(newModel)
                            }}
                            slots={{toolbar: CustomToolbar}}
                            loading={isLoading}
                            rowCount={rowCount}
                            paginationMode="server"
                            pageSizeOptions={[10, 50, 100]}
                            getRowHeight={(params) => 'auto'}
                            disableRowSelectionOnClick
                        />
                    </ThemeProvider>

                </Box>
            }
        </>
    )
}

export default StockpileGrid;