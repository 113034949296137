export const ProductVatData = [
    {id: 1, name: 'Főtt tészták (makaróni, penne, lazagne, gnocchi)', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 2, name: 'Pizza', localVat: '5', takeawayVat: '18', deliveryVat: '18'},
    {id: 3, name: 'Pékáru', localVat: '5', takeawayVat: '18', deliveryVat: '18'},
    {id: 4, name: 'Szendvics (hús<0%)', localVat: '5', takeawayVat: '18', deliveryVat: '18'},
    {id: 5, name: 'Szendvics (hús>20%)', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 6, name: 'Lángos', localVat: '5', takeawayVat: '18', deliveryVat: '18'},
    {id: 7, name: 'Virslis lángos (hús>20%)', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 8, name: 'Gyros', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 9, name: 'Hamburger', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 10, name: 'Tejtermékek, sajtok', localVat: '5', takeawayVat: '18', deliveryVat: '18'},
    {id: 11, name: 'Fánk (töltött is)', localVat: '5', takeawayVat: '18*', deliveryVat: '18*'},
    {id: 12, name: 'Palacsinta, Somlói', localVat: '5', takeawayVat: '18*', deliveryVat: '18*'},
    {id: 13, name: 'Torták, sütemények', localVat: '5', takeawayVat: '18*', deliveryVat: '18*'},
    {id: 14, name: 'Mákos guba, tiramisu', localVat: '5', takeawayVat: '18*', deliveryVat: '18*'},
    {id: 15, name: '>40% kakaótartalmú süti', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 16, name: 'Rigó jancsi', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 17, name: 'Gesztenyepüré, gesztenyegolyó', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 18, name: 'Meggyes linzer, Hókifli', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 29, name: '', localVat: '', takeawayVat: '', deliveryVat: ''},
    {id: 19, name: 'Szeszes italok', localVat: '27', takeawayVat: '27', deliveryVat: '27'},
    {id: 20, name: 'Koktélok (alkoholos)', localVat: '27', takeawayVat: '27', deliveryVat: '27'},
    {id: 21, name: 'Alk. mentes <0.5% koktél', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 30, name: '', localVat: '', takeawayVat: '', deliveryVat: ''},
    {id: 22, name: 'Üdítők (Doboz/palack, kitöltve is)', localVat: '27', takeawayVat: '27', deliveryVat: '27'},
    {id: 23, name: 'Gépi üdítő (keverőgépes, szirupból)', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 24, name: 'Szörpök (helyben kevert / facsart...)', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 25, name: 'Ásványvíz', localVat: '27', takeawayVat: '27', deliveryVat: '27'},
    {id: 26, name: 'Tea', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 27, name: 'Kávé', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
    {id: 28, name: 'Limonádé', localVat: '5', takeawayVat: '27', deliveryVat: '27'},
]