export const userTransform = (data) => {
    data.posSetId = localStorage.getItem("userPosSetId")
    if(data.isTechnical !== undefined && !data.isTechnical) {
        let priv = []
        data.updatedPrivileges.map(obj => {
            if (obj.posSetsAdmin.length !== 0 || obj.posSetsApi.length !== 0) {
                priv = priv.concat(obj)
            }
            return obj
        })
        data.updatedPrivileges = priv
    }else{
        if(data.roles?.length === 0 || !data.roles){
            data.updatedRoles = []
        }else {
            for (let i = 0; i < data.roles.length; i++){
                if(typeof data.roles[i].id == 'number' && data.roles[i].id !== data.updatedRoles[i].id){
                    data.updatedRoles[i].id = data.roles[i].id
                }
            }
        }
    }
    return data;
};