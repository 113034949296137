import {required, TextInput, number, minLength, maxLength, useTranslate, PasswordInput} from "react-admin";
import React, {useState} from "react";
import PatternIcon from '@mui/icons-material/Pattern';
import {Button} from "@mui/material";

export const TechnicalUserData = ({edit,...props}) => {
    const [resetPIN, setResetPIN] = useState(false)
    const translate = useTranslate()

    const handleAddChild = () => {
        setResetPIN(true)
    }

    return (
        <div style={{display: 'inline-flex', flexDirection: 'column'}}  {...props}>
            <TextInput source="name" label={"technicalUsers.name"} inputProps={{maxLength: 255}} validate={[required()]} />
            {edit ?
                <Button style={{marginBottom: '8px', color: "white"}} variant={'contained'} color={"primary"}
                        onClick={handleAddChild}><PatternIcon/> {translate("technicalUsers.resetPIN")}</Button>
                : null
            }
            {(!edit || resetPIN) &&
            <div style={{display: 'inline-flex', flexDirection: 'column'}}>
                <PasswordInput source="pinCode" label={"technicalUsers.pinCodeCreate"} autoComplete={"off"}
                           inputProps={{maxLength: 4}} validate={[required()]} id={"pinCodeInput"}/>
                <PasswordInput source="pinCodeAgain" label={"technicalUsers.pinCodeAgain"}
                           inputProps={{maxLength: 4}} autoComplete={"new-password"}
                           validate={[required()]} onBlur={() => {
                                const element = document.getElementById('pinCodeInput')
                                element.focus()
                                element.blur()
                           }}
                />
            </div>
            }
            <TextInput source="hpPosUserId" label={"technicalUsers.helloPayPosUser"} autoComplete={"off"}
                       inputProps={{maxLength: 20}}
                        helperText={translate("technicalUsers.helloPayPosUserHint")}
            />
        </div>
    )
}