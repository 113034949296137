import {SaveButton, Toolbar, DeleteButton} from "react-admin";
import React from "react";

export const PinkToolbar = ({deleteEnabled, alwaysEnableSave, type, noSaveButton, mutationOptions, disabled}) =>{
    return (
        <Toolbar>
            {!noSaveButton &&
            <SaveButton style={{color: "#fff"}} type={type} alwaysEnable={alwaysEnableSave} mutationOptions={mutationOptions} disabled={disabled}/>
            }
            {deleteEnabled ?
                <DeleteButton />
                :
                null
            }
        </Toolbar>
    )
}