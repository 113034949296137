import {
    Button,
    CreateButton,
    Datagrid,
    ExportButton,
    FunctionField,
    List,
    TextField,
    TopToolbar, useRefresh,
    useTranslate
} from "react-admin";
import {EmptyPage} from "../../../components/EmtpyPage";
import SelectCompanyWithBusinessUnitInput from "../../../components/SelectCompanyWithBusinessUnitInput";
import React, {useEffect, useState} from "react";
import {PinkPagination} from "../../../components/PinkPagination";
import {getValidPrivilegesIds} from "../../../components/authentication/PermissionHandler";
import RedirectToPurchase from "../../../components/RedirectToPurchase";
import * as XLSX from "xlsx";
import {SuppliersUploadDialog} from "./SuppliersUploadDialog";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";


const SuppliersList = ({permissions}) => {
    const [selectedPosSetId, setSelectedPosSetId] = useState(0)
    const hasEdit = getValidPrivilegesIds("WAREHOUSE", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    const hasCreate = getValidPrivilegesIds("WAREHOUSE", "CREATE").some(r => permissions.includes(r))
    const [isShowRedirect, setIsShowRedirect] = useState(false)
    const [fileUploadOpen, setFileUploadOpen] = useState(false)
    const refresh = useRefresh()
    const translate = useTranslate()


    useEffect(() => {
        if (selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) {
            localStorage.setItem("supplierPosSetId", selectedPosSetId)
        }
    }, [selectedPosSetId])

    const handleFileUploadDialogClose = () => {
        setFileUploadOpen(false)
        refresh()
    }

    const handleFileUploadDialogOpen = () => {
        setFileUploadOpen(true)
    }
    const ListActions = () => {
        return (
            <TopToolbar>
                {hasCreate && <CreateButton/>}
                <ExportButton/>
                {hasCreate && <Button onClick={handleFileUploadDialogOpen} sx={{padding: '4px 5px', fontSize: '13px'}}><UploadFileIcon /> {translate("suppliers.import")}</Button>}
            </TopToolbar>
        )
    }

    const exporter = suppliers => {
        const headers = [translate("suppliers.name"), translate("suppliers.address"), translate("suppliers.taxNumber"), translate("suppliers.phonePrefix"),
            translate("suppliers.phone"), translate("suppliers.email"), translate("suppliers.contact")]
        let exportedSuppliers = []
        suppliers.forEach(item => {
            let arr = [
                item.name,
                item.address,
                item.taxNumber,
                item.phonePrefix,
                item.phone,
                item.email,
                item.contact
            ]
            exportedSuppliers.push(arr)
        })
        exportedSuppliers.unshift(headers)
        let wscols = [
            {wch:15},
            {wch:15},
            {wch:15},
            {wch:15},
            {wch:15},
            {wch:15},
            {wch:15}
        ];
        const ws = XLSX.utils.aoa_to_sheet(exportedSuppliers);
        ws['!cols'] = wscols;
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "export");
        XLSX.writeFile(wb, "export.xlsx");
    }

    return (
        <>
            <SelectCompanyWithBusinessUnitInput getResource={"supplier"} setPosSet={setSelectedPosSetId}
                                                setIsShowRedirect={setIsShowRedirect}/>
            <SuppliersUploadDialog open={fileUploadOpen} onClose={handleFileUploadDialogClose} selectedPosSetId={selectedPosSetId} mode={"list"}/>
            {isShowRedirect ?
                <RedirectToPurchase resource={"warehouse"}/>
                :
                selectedPosSetId !== 0 &&
                <>
                <List actions={<ListActions/>}
                      exporter={exporter}
                      pagination={<PinkPagination trigger={selectedPosSetId}/>}
                      filterDefaultValues={{posSetId: selectedPosSetId}}
                      title={"suppliers.title"}
                      empty={<EmptyPage text={"suppliers.empty"} createEnabled={true}/>}>
                    <Datagrid bulkActionButtons={false} rowClick={hasEdit}>
                        <TextField source="id" label={"suppliers.id"} sortable/>
                        <TextField source="name" label={"suppliers.name"} sortable/>
                        <TextField source="address" label={"suppliers.address"} sortable/>
                        <TextField source="taxNumber" label={"suppliers.taxNumber"} sortable={false}/>
                        <FunctionField label={"suppliers.phone"}
                                       render={record => record.phone ? `${record.phonePrefix} ${record.phone}` : null}
                                       sortable={false}/>
                        <TextField source="email" label={"suppliers.email"} sortable={false}/>
                        <TextField source="contact" label={"suppliers.contact"} sortable={false}/>
                    </Datagrid>
                </List>
                <img src={shorticLogo}
            style={{position: "absolute", bottom: "0", left: "20", width: "150px", paddingBottom: '10px'}}/>
</>
}
</>
)
}


export default SuppliersList