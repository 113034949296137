export default {
    Privilege: {}
    /*Privilege: {
        id: 1,
        Admin : {
            id:10,
            BusinessUnit: {
                id: 120,
                Get: {
                    id: 121
                },
                Create: {
                    id: 122
                },
                Update: {
                    id: 123
                },
                Delete: {
                    id: 124
                }
            },
            PosHierarchy: {
                id: 140,
                Get: {
                    id: 141
                },
                Update: {
                    id: 143
                }
            },
            Device: {
                id: 130,
                Get: {
                    id: 131
                },
                Update: {
                    id: 133
                },
                Delete: {
                    id: 134
                }
            },
            Product: {
                id: 110,
                Create: {
                    id: 112
                },
                Get: {
                    id: 111
                },
                Update: {
                    id: 113
                },
                Delete: {
                    id: 114
                }
            },
            User: {
                id: 160,
                Create: {
                    id: 162
                },
                Get: {
                    id: 161
                },
                Update: {
                    id: 163
                },
                Delete: {
                    id: 164
                },
                Invite: {
                    id: 165
                }
            },
            PriceList: {
                id: 100,
                Create: {
                    id: 102
                },
                Get: {
                    id: 101
                },
                Update: {
                    id: 103
                },
                Delete: {
                    id: 104
                }
            },
            PriceListToPosSet: {
                id: 150,
                Get: {
                    id: 151
                },
                Update: {
                    id: 153
                }
            },
            PosWithPriceList: {
                id: 180,
                Get: {
                    id: 181
                }
            },
            Report: {
                id: 190,
                Get: {
                    id: 191
                },
                Create: {
                    id: 192
                }
            },
            Company: {
                id: 200,
                Get: {
                    id: 201
                },
                Update: {
                    id: 203
                }
            },
            Config: {
                id: 230,
                Get: {
                    id: 231
                }
            },
            RMSErrorMessages: {
                id: 240,
                Get: {
                    id: 241
                }
            }
        },
        CorporateAdmin: {
            id: 5000,
            POS: {
                id: 5010,
                Create: {
                    id: 5012
                },
                Delete: {
                    id: 5014
                },
                List: {
                    id: 5015
                }
            }
        }
}*/
}