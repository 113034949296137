import React, {useEffect, useState} from 'react';
import {
    required,
    SimpleForm,
    TextInput, Title,
    useDataProvider,
    useNotify,
    useTranslate
} from 'react-admin';
import {PinkToolbar} from "../../components/PinkToolbar";
import {CircularProgress, TextField} from "@mui/material";
import Button from "@mui/material/Button";


export const ProfileEdit = () => {
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const translate = useTranslate()
    const [data, setData] = useState({loading: false, loaded: false, data: [], error: undefined})
    const [formData, setFormData] = useState();

    useEffect(() => {
        if (!data.loading && !data.loaded) {
            setData({...data, loading: true});
            dataProvider.get(`profile`)
                .then(value => {
                    setData({loading: false, loaded: true, data: value.data, error: undefined});
                    delete value.data.id
                    setFormData(value.data)
                })
                .catch(reason => {
                    setData({loading: false, loaded: true, data: undefined, error: reason});
                    notify("profile.loadingError", {type: "error"})
                })
            }
    }, [data.loading, data.loaded]);

    const handleSave = () => {
        if(formData.helloPayPosUser === '') {
            formData.helloPayPosUser = null
        }
        dataProvider.update(`profile`, formData)
            .then(value => {
                notify("profile.success", {type: "success", autoHideDuration: 1000})
            })
            .catch(reason => {
                notify("profile.savingError", {type: "error"})
            })
    };

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    return (
        <>
            <Title title="profile.title" />
            {!data.loaded ?
                <CircularProgress/>
                :
                <>
                        <TextField
                            name="username"
                            label={translate('profile.username')}
                            value={formData.username}
                            required={true}
                            fullWidth
                            margin="normal"
                        />

                        <TextField
                            name="familyName"
                            label={translate('profile.familyName')}
                            value={formData.familyName}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                            inputProps={{maxLength: 255}}
                        />

                        <TextField
                            name="givenName"
                            label={translate('profile.givenName')}
                            value={formData.givenName}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                            inputProps={{maxLength: 255}}
                        />

                        <TextField
                            name="phone"
                            label={translate('profile.phone')}
                            value={formData.phone}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                            helperText={translate('profile.phoneHint')}
                            inputProps={{inputMode: 'text', pattern: '\\+\\d{5,12}'}}
                        />

                        <TextField
                            name="pin"
                            label={translate('profile.pin')}
                            value={formData.pin}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                            helperText={translate('profile.pinHint')}
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9][0-9][0-9][0-9]',
                                maxLength: 4,
                                minLength: 4
                            }}
                        />

                        <TextField
                            name="helloPayPosUser"
                            label={translate('profile.helloPayPosUser')}
                            value={formData.helloPayPosUser}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            helperText={translate('profile.helloPayPosUserHint')}
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '\\d{4,20}',
                                maxLength: 20,
                                minLength: 4
                            }}
                        />

                        <Button onClick={handleSave} variant="contained" sx={{color:'#ffffff', width: '100px'}}>
                            {translate('profile.save')}
                        </Button>
                </>
            }
        </>
    );
};