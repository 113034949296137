import {
    TextInput,
    FormDataConsumer,
    SimpleForm, required,
    Toolbar,
    SaveButton,
    useRecordContext
} from "react-admin";
import React from "react";
import {ProductToPriceList} from "../../components/ProductToPriceList";
import {useFormState} from "react-hook-form";
import SaveAsIcon from '@mui/icons-material/SaveAs';



export const PriceListTemplateDetails = (props) => {
    let record = useRecordContext()
    if(!record){
        record = {}
    }

    const AlwaysEnableToolbar = () => {
        const { isValid } = useFormState()

        return (
        <Toolbar {...props} >
                <>
                    {record.type === "INSTANCE" ?
                        <SaveButton label={"priceListTemplate.saveAsInstance"} icon={<SaveAsIcon />} variant={"outlined"}  type={"button"}
                                alwaysEnable={isValid}/>
                        :
                        <SaveButton label={"priceListTemplate.saveAsTemplate"} icon={<SaveAsIcon />} variant={"outlined"}  type={"button"}
                                    alwaysEnable={isValid}/>
                    }
                </>

        </Toolbar>
    );
}

    return (
        <SimpleForm toolbar={<AlwaysEnableToolbar />}>
            <div style={{display: 'flex'}}>
                <TextInput source={"name"} label={'priceListTemplate.name'} validate={required()} inputProps={{maxLength: 255}}/>
                <TextInput style={{width: '400px', marginLeft: '20px'}} source={"comment"} label={'priceListTemplate.comment'} />
            </div>
                <FormDataConsumer>
                    {({ formData }) => (
                    <ProductToPriceList formData={formData} />
                        )
                    }
                </FormDataConsumer>
        </SimpleForm>
    )
}