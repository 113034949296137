import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {useFormContext} from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { ThemeProvider } from '@mui/material/styles';
import './ListItems.css'
import {useState} from "react";
import EditIcon from "@mui/icons-material/Edit";
import {useTranslate} from "react-admin";
import {Button, Dialog, DialogContent, TextField, Typography} from "@mui/material";
import {muiDatagridTheme} from "./MuiDatagridTheme";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RecyclingIcon from "@mui/icons-material/Recycling";


export default function EditPriceDataGrid({rows}) {
    const translate = useTranslate()
    const columns = [
        {
            field: 'name',
            headerName: 'Név',
            valueGetter: (value,row) => {
                return row.name
            }
        },
        {
            field: 'quantityUnit',
            headerName: 'Kiszerelés',
            valueGetter: (value, row) => `${row.quantity} ${row.unit}`
        },
        {
            field: 'vatName',
            headerName: 'ÁFA csoport',
            valueGetter: (value, row) => row.vatName
        },
        {
            field: "hasRecipe",
            headerName: translate("products.recipe"),
            renderCell: (params) => {
                return params.row.hasRecipe ? <Tooltip title={translate("products.recipeTooltip")}> <ReceiptLongIcon/> </Tooltip> : ""
            }
        },
        {
            field: "depositFee",
            headerName: translate("products.refundBottle"),
            renderCell: (params) => {
                return params.row.depositFee === "SEVENTY" || params.row.depositFee === "FIFTY" ? <Tooltip title={translate("products.refundBottle")}> <RecyclingIcon/> </Tooltip> : ""
            }
        },
        {
            field: 'isTakeawayAllowed',
            headerName: 'Elvihető',
            type: "boolean",
            valueGetter: (value, row) => row.isTakeawayAllowed
        },
        {
            field: 'takeawayVatName',
            headerName: 'Elviteles ÁFA',
            valueGetter: (value, row) => row.takeawayVatName
        },
        {
            field: 'bulk',
            headerName: translate("products.bulkItem"),
            type: "boolean",
            width: 110,
            valueGetter: (value, row) => row.bulk
        },
        {
            field: 'tags',
            headerName: 'Cimkék',
            width: 300,
            renderCell: (params) => {
                return (
                <div>
                    {params.row.tags?.map((value) => (
                        <Chip key={value.id} label={value.name} />
                    ))}
                </div>
            );
            }
        },
        {
            field: 'price',
            headerName: 'Ár',
            type: "number",
            renderCell: (params) => {
                if (params.value == null) {
                    return '';
                }else{
                    return `${params.value} Ft`
                }
            }

        },
        {
            field: "editAction",
            headerName: "Módosítás",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    setOpen(true)
                    setNewPrice(params.row.price)
                    setEditValue(params.row)
                };
                return <Button disabled={params.row.depositFeeType !== null && params.row.depositFeeType !== undefined} onClick={onClick}><EditIcon/></Button>;
            }
        },
        {
            field: "action",
            headerName: "Törlés",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const newRows = rows.filter(row => {
                        if (row.productUnitId) {
                        return row.productUnitId !== params.id
                    }else {
                        return row.id !== params.id
                    }
                    })
                    setValue('products', newRows)

                };

                return <Button disabled={params.row.depositFeeType !== null && params.row.depositFeeType !== undefined} onClick={onClick}><DeleteIcon/></Button>;
            }
        },

    ];

    const { setValue } = useFormContext();
    const [open, setOpen] = useState(false)
    const [newPrice, setNewPrice] = useState(null)
    const [editValue, setEditValue] = useState(null)


    const processRowUpdate = React.useCallback(
        async (newRow) => {
            const newRows = rows.map(r => (r.id === newRow.id) ? {...r, price: newRow.price} : r )
            setValue('products', newRows)
        },
        [],
    );

    const handleClick = (event) => {
        const newRows = rows.map(row => {
            if(row.id !== editValue.id){
                return row
            }else{
                return {...row, price: newPrice ? parseInt(newPrice) : event.target.value}
            }
        })
        setValue('products', newRows)
        setOpen(false)

    };

    const onProcessRowUpdateError = (error) => {
        console.log(error)
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.key === "Enter" && !(e.target.value < 0 || e.target.value.length > 9)) {
            handleClick(e)
        }
    };

    const handleDialogClose = () => {
        setOpen(false)
        setNewPrice(null)
    }


    const handleTextChange = (event) => {
        //console.log(event.target.value.length)
        if(event.target.value.length === 0) {
            setNewPrice(undefined)
        }
        if(/^(-?\d{1,9})$/.test(event.target.value)) {
            setNewPrice(event.target.value)
        }
    }



    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleDialogClose} >
                <DialogContent style={{width: "250px"}}>
                    <TextField label={translate("priceListTemplate.price")} autoFocus
                               value={newPrice}
                               onKeyPress={handleKeypress} onChange={handleTextChange}
                               error={!newPrice || newPrice?.length > 9}
                    />

                    <Typography>{translate("priceListTemplate.priceHelperText")}</Typography>

                    <div style={{marginTop: "16px", display:"flex", justifyContent: "space-between"}}>
                        <Button style={{paddingRight: "8px", color: "white"}} variant={"contained"} color={"primary"} disabled={!newPrice || newPrice?.length > 9} onClick={handleClick}>{translate("priceListTemplate.modify")}</Button>
                        <Button variant={"outlined"} color={"secondary"} onClick={handleDialogClose}>{translate("priceListTemplate.cancel")}</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <div style={{ height: 400, width: 1400 }}>
                <ThemeProvider theme={muiDatagridTheme}>
                    <DataGrid
                        getRowId={row => row.productUnitId ? row.productUnitId : row.id}
                        pageSizeOptions={[5, 10, 20, 100]}
                        rows={rows}
                        columns={columns}
                        disableRowSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={onProcessRowUpdateError}
                    />
                </ThemeProvider>
            </div>
        </React.Fragment>
    );
}