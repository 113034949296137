import EditIcon from "@mui/icons-material/Edit";
import React, {useState} from "react";
import {Typography} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from "moment-timezone";
import {Menu} from "@mui/icons-material";

export const Title = ({title, currentName, createdAt, style}) => {
    const format = "YYYY.MM.DD HH:mm"
    const date = createdAt ? moment(new Date(createdAt)).format(format) : ""
    const [displayIcon, setDisplayIcon] = useState(false)
    const mouseEnter = () => {
        setDisplayIcon(true)
    }
    const mouseLeave = () => {
        setDisplayIcon(false)
    }

    return (
        <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <Typography width={"18rem"} >{title}</Typography>
                {currentName &&
                        <div style={{display: "flex", width: "18rem"}}>
                            <Menu/><Typography>{currentName}</Typography>
                        </div>}
                {createdAt &&
                        <div style={{display: "flex", width:"10rem"}}>
                            <AccessTimeIcon/> <Typography>{date}</Typography>
                        </div>}
                {displayIcon ?
                    <EditIcon color={"action"} />
                    :
                    <EditIcon style={{color: "#fff"}}/>
                }
                {currentName ? null : <div style={{display: "flex", width:"18rem", backgroundColor: style === "redBg" ? "#FF0000" : null}} />}
                {createdAt ? null : <div style={{display: "flex", width:"10rem"}} />}
            </div>


        </div>
    )
}