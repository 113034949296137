import { AutocompleteInput, required, TextInput, useRecordContext, useRefresh, useTranslate } from "react-admin";
import React, { useEffect, useState } from "react";
import { TaxNumberInput } from "../../../components/TaxNumberInput";
import { countyCodes } from "../../../components/CountyCodes";
import { useFormContext } from "react-hook-form";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dataProvider from "../../../components/dataProvider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { ThemeProvider } from "@mui/material/styles";
import { muiDatagridTheme } from "../../../components/MuiDatagridTheme";
import * as XLSX from "xlsx";
import { SuppliersUploadDialog } from "./SuppliersUploadDialog";

const SuppliersDetails = ({ setSaveButton }) => {

    const { setValue } = useFormContext()
    const [products, setProducts] = useState([])
    const [supplierIn, setSupplierIn] = useState([])
    const [supplierOut, setSupplierOut] = useState([])
    const [fileUploadOpen, setFileUploadOpen] = useState(false)
    const translate = useTranslate()
    const record = useRecordContext()
    const posSetId = localStorage.getItem("supplierPosSetId")



    useEffect(() => {
        dataProvider.get('supplier/pricelist-product', { posSetId: posSetId }).then((response) => {
            setProducts(response.data)
        }).catch((error) => {
            throw error
        })
    }, [])
    
    useEffect(() => {
        if (record && products.length > 0) {

            const fetchSupplier = async (type) => {
                try {
                    const response = await dataProvider.get('supplier/pricelist', { supplierId: record.id, type: type });
                    return response.data.map(row => {
                        const whProduct = products.find(product => product.id === row.warehouseProductId);
                        const unit = whProduct.units.find(unit => unit.id === (row.unitId !== null ? ("unit-" + row.unitId) : row.warehouseProductUnitId.toString()));
                        return {
                            feId: row.id,
                            id: row.id,
                            warehouseProductId: row.warehouseProductId,
                            warehouseProductUnitId: row.warehouseProductUnitId,
                            unitId: row.unitId,
                            netPrice: row.netPrice,
                            warehouseProduct: whProduct,
                            unit: unit,
                            tab: type.toLowerCase()
                        };
                    });
                } catch (error) {
                    throw error;
                }
            };

            fetchSupplier('IN')
                .then(data => {
                    setSupplierIn(data);
                })
                .catch(error => {
                    throw error
                });

            fetchSupplier('OUT')
                .then(data => {
                    setSupplierOut(data);
                })
                .catch(error => {
                    throw error
                });
        }

    }, [products])

    useEffect(() => {
        const allInInputFilled = supplierIn.every(row => row.warehouseProductId && (row.warehouseProductUnitId || row.unitId !== null) && row.netPrice);
        const allOutInputFilled = supplierOut.every(row => row.warehouseProductId && (row.warehouseProductUnitId || row.unitId !== null) && row.netPrice);
        setSaveButton(allInInputFilled && allOutInputFilled);
    }, [supplierIn, supplierOut])

    const addItem = (table) => {
        table === 'in' ? setSupplierIn([...supplierIn, {
            feId: Date.now(),
            warehouseProductId: '',
            warehouseProductUnitId: '',
            unitId: '',
            netPrice: '',
            tab: 'in'
        }]) : setSupplierOut([...supplierOut, {
            feId: Date.now(),
            warehouseProductId: '',
            warehouseProductUnitId: '',
            unitId: '',
            netPrice: '',
            tab: 'out'
        }])
    }
    setValue("inPriceListItems", supplierIn)
    setValue("outPriceListItems", supplierOut)

    const columns = [
        {
            field: 'id', headerName: 'ID', sortable: false,
            renderCell: (params) => {
                return (
                    <span>{params.row.feId}</span>
                )
            }
        },
        {
            field: 'warehouseProduct',
            headerName: translate("suppliers.warehouseProducts"),
            sortable: false,
            minWidth: 200,
            renderCell: (params) => {

                return (
                    <Autocomplete
                        sx={{ width: "100%" }}
                        getOptionLabel={option => option.name || ""}
                        value={params.row.warehouseProduct}
                        renderInput={(params) => <TextField {...params} variant={"standard"}
                            label={translate("suppliers.warehouseProduct")} />}
                        options={products}
                        onChange={(event, newValue) => {
                            const array = params.row.tab === 'in' ? supplierIn : supplierOut
                            const data = array.map(row => {
                                if (row.feId === params.row.feId) {
                                    return {
                                        ...row,
                                        warehouseProduct: newValue,
                                        warehouseProductId: newValue.id
                                    }
                                }
                                return row
                            })
                            params.row.tab === 'in' ? setSupplierIn(data) : setSupplierOut(data)
                        }}
                        disableClearable
                    />)
            }
        },
        {
            field: 'unit', headerName: translate("suppliers.unit"), minWidth: 150, sortable: false,
            renderCell: (params) => {
                const array = params.row.tab === 'in' ? supplierIn : supplierOut
                const product = array.map(row => row.warehouseProductId === params.row.warehouseProductId ? row.unit : null).filter(unit => unit !== null)
                const unitOptions = params.row.warehouseProduct?.units.filter(unit => !product.includes(unit))

                return (
                    <Autocomplete
                        sx={{ width: "100%" }}
                        getOptionLabel={option => option.unitName || ""}
                        renderInput={(params) => <TextField {...params} variant={"standard"}
                            label={translate("suppliers.unit")} />}
                        options={unitOptions || []}
                        value={params.row.unit}
                        onChange={(event, newValue) => {
                            const data = array.map(row => {
                                if (row.feId === params.row.feId) {
                                    if (newValue) {
                                        return {
                                            ...row,
                                            unit: newValue,
                                            warehouseProductUnitId: newValue.id.startsWith('unit') ? null : newValue.id,
                                            unitId: newValue.id.startsWith('unit') ? newValue.id.split("-")[1] : null
                                        }
                                    } else {
                                        return {
                                            ...row,
                                            unit: null,
                                            warehouseProductUnitId: null,
                                            unitId: null

                                        }
                                    }
                                }
                                return row
                            })
                            params.row.tab === 'in' ? setSupplierIn(data) : setSupplierOut(data)
                        }} />)
            }
        },
        {
            field: 'netPrice', headerName: translate("suppliers.price"), sortable: false,
            renderCell: (params) => {
                const array = params.row.tab === 'in' ? supplierIn : supplierOut
                return (
                    <TextField
                        sx={{ width: "100%", marginTop: "24px" }}
                        key={params.row.feId}
                        variant={"standard"}
                        value={params.row.netPrice}
                        onChange={(event) => {
                            const data = array.map(row => {
                                if (row.feId === params.row.feId) {
                                    const netPrice = event.target.value.replace(/[^0-9]/g, '')
                                    return {
                                        ...row,
                                        netPrice: netPrice
                                    }
                                }
                                return row
                            })
                            params.row.tab === 'in' ? setSupplierIn(data) : setSupplierOut(data)
                        }
                        }
                    />
                )
            }
        },
        {
            field: 'delete', headerName: translate("suppliers.delete"), sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton size={"medium"} onClick={() => {
                        const array = params.row.tab === 'in' ? supplierIn : supplierOut
                        const data = array.filter(row => row.feId !== params.row.feId)
                        params.row.tab === 'in' ? setSupplierIn(data) : setSupplierOut(data)
                    }}>
                        <DeleteIcon fontSize={"medium"} sx={{ color: "#ff71bc" }} />
                        <span style={{ fontSize: '11px', marginLeft: '3px' }}>{translate("suppliers.delete")}</span>
                    </IconButton>
                )
            }
        }
    ]

    const handleFileUploadDialogClose = () => {
        setFileUploadOpen(false)
    }

    const handleFileUploadDialogOpen = () => {
        setFileUploadOpen(true)
    }

    const exportSuppliers = () => {
        const headers = ["id", translate("suppliers.warehouseProduct"), translate("suppliers.unit"), translate("suppliers.price"), "", "id", translate("suppliers.warehouseProduct"), translate("suppliers.unit"), translate("suppliers.price")]
        let exportedSupplierIn = []
        let exportedSupplierOut = []
        supplierIn.forEach(supp => {
            const arr = [
                supp.warehouseProduct.id,
                supp.warehouseProduct.name,
                supp.unit.unitName,
                supp.netPrice
            ]
            exportedSupplierIn.push(arr)
        })

        supplierOut.forEach(supp => {
            const arr = [
                supp.warehouseProduct.id,
                supp.warehouseProduct.name,
                supp.unit.unitName,
                supp.netPrice
            ]
            exportedSupplierOut.push(arr)
        })

        let wscols = [
            { wch: 5 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 5 },
            { wch: 5 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 }
        ]
        exportedSupplierIn.unshift(headers)
        const ws = XLSX.utils.aoa_to_sheet(exportedSupplierIn, { origin: "A3" });
        XLSX.utils.sheet_add_aoa(ws, exportedSupplierOut, { origin: "F4" });
        ws['!cols'] = wscols;
        ws['!merges'] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } },
            { s: { r: 0, c: 5 }, e: { r: 0, c: 8 } }
        ];
        XLSX.utils.sheet_add_aoa(ws, [[translate("suppliers.supplierIn")]], { origin: "A1" })
        XLSX.utils.sheet_add_aoa(ws, [[translate("suppliers.supplierOut")]], { origin: "F1" })
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "export");
        XLSX.writeFile(wb, "export.xlsx");
    }


    return (
        <>
            <TextInput label={"suppliers.name"} source={"name"} name={"name"} validate={required()} />
            <TextInput label={"suppliers.address"} source={"address"} name={"address"} />
            <TaxNumberInput label={"suppliers.taxNumber"} source={"taxNumber"} />
            <div style={{ display: "flex" }}>
                <AutocompleteInput style={{ marginRight: "8px" }} label={'suppliers.phonePrefix'}
                    source={"phonePrefix"} choices={countyCodes} optionValue={"dial_code"}
                    name={"phonePrefix"}
                    optionText={((record) => {
                        return `${record.dial_code} (${record.code})`
                    })} defaultValue={"+36"} />
                <TextInput source="phone" inputProps={{ maxLength: 20 }} label={'suppliers.phone'}
                    name={"phone"} />
            </div>
            <TextInput label={"suppliers.email"} source={"email"} name={"email"} />
            <TextInput label={"suppliers.contact"} source={"contact"} name={"contact"} />
            <Box sx={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
                <Button onClick={exportSuppliers}>Export</Button>
                <Button onClick={handleFileUploadDialogOpen}>Import</Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ width: '47%', marginBottom: '55px' }}>
                    <Typography>
                        {translate("suppliers.supplierIn")}
                    </Typography>
                    <ThemeProvider theme={muiDatagridTheme}>
                        <DataGrid
                            autoHeight
                            columns={columns}
                            rows={supplierIn}
                            columnVisibilityModel={{
                                id: false
                            }}
                            getRowId={(row) => row.feId}
                            disableRowSelectionOnClick={true}
                        />
                    </ThemeProvider>
                    <IconButton size={"medium"} sx={{ float: "right" }} onClick={() => {
                        addItem('in')
                    }}>
                        <AddCircleOutlineIcon fontSize={"medium"} sx={{ color: "#ff71bc" }} />
                        <span style={{ fontSize: '11px', marginLeft: '3px' }}>{translate("operations.addProduct")}</span>
                    </IconButton>
                </Box>
                <Box sx={{ width: '47%', marginBottom: '55px' }}>
                    <Typography>
                        {translate("suppliers.supplierOut")}
                    </Typography>
                    <ThemeProvider theme={muiDatagridTheme}>
                        <DataGrid
                            autoHeight
                            columns={columns}
                            rows={supplierOut}
                            columnVisibilityModel={{
                                id: false
                            }}
                            getRowId={(row) => row.feId}
                            disableRowSelectionOnClick={true}
                        />
                    </ThemeProvider>
                    <IconButton size={"medium"} sx={{ float: "right" }} onClick={() => {
                        addItem('out')
                    }}>
                        <AddCircleOutlineIcon fontSize={"medium"} sx={{ color: "#ff71bc" }} />
                        <span style={{ fontSize: '11px', marginLeft: '3px' }}>{translate("operations.addProduct")}</span>
                    </IconButton>
                </Box>
            </Box>
            <SuppliersUploadDialog open={fileUploadOpen} onClose={handleFileUploadDialogClose} selectedPosSetId={posSetId} mode={"create"} products={products} setSupplierIn={setSupplierIn} setSupplierOut={setSupplierOut} />
        </>
    )
}

export default SuppliersDetails