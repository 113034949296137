import React, { useEffect, useState } from 'react'
import {
    AutocompleteInput,
    BooleanInput,
    required,
    SelectInput,
    TextInput,
    useDataProvider,
    useNotify,
    useRecordContext,
    useTranslate
} from "react-admin"
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useFormContext } from "react-hook-form"
import { Box, Stack } from "@mui/material"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import { uuidv4 } from "../../../tools/generate"
import Container from "@mui/material/Container"
import IconButton from "@mui/material/IconButton"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"


const WarehouseProductDetails = ({ setDisabledSave }) => {
    const dataProvider = useDataProvider()
    const { setValue, getValues } = useFormContext()
    const record = useRecordContext()
    const defaultTags = record !== undefined && record.tags.length > 0 ? record.tags.map(value => value.name) : []
    const translate = useTranslate()
    const [tag, setTag] = useState({ loading: false, loaded: false, data: [], error: undefined })
    const [mainProductGroups, setMainProductGroups] = useState([])
    const [selectedTag, setSelectedTag] = useState([])
    const [selectedMainProductGroup, setSelectedMainProductGroup] = useState('')
    const [selectedPosSetId, setSelectedPosSetId] = useState(localStorage.getItem("warehouseProductPosSetId") || 0)
    const [inputFields, setInputFields] = useState([])
    const [sellingUnits, setSellingUnits] = useState([])
    const [tempUnits, setTempUnits] = useState([])
    const [baseUnits, setBaseUnits] = useState([])
    const [trigger, setTrigger] = useState(false)
    const [isDisabledAddButton, setIsDisabledAddButton] = useState(false)
    const [vatGroups, setVatGroups] = useState([])
    const notify = useNotify()
    const unitMap = new Map([
        ["KILOGRAMM", ["g", "dkg", "kg"]],
        ["LITER", ["cl", "dl", "l"]],
        ["DARAB", ["db"]]
    ])
    //Ez kell ide, vagy fefülírja a táblázat, amíg nincs módosítva. Ezt át lehetne rakni backend részre, hogy tag vagy név tömbként vagy név id listaként megy fel
    setValue('tags', selectedTag)
    setValue("units", inputFields)
    setValue("mainProductGroup", selectedMainProductGroup)


    const handleTag = (event, newValue) => {
        //setValue('tags', newValue) //Ez majd akkor kell, ha átkerül be-re a fenti
        setSelectedTag(newValue)
    }

    useEffect(() => {
        if (setDisabledSave) {
            const formValues = getValues()
            if (formValues.name && formValues.defaultUnitId && formValues.defaultWarehouseUnitId) {
                setDisabledSave(false)
            } else {
                setDisabledSave(true)
            }
        }
    }, [trigger])

    useEffect(() => {
        if (defaultTags !== undefined) {
            //setValue('tags', defaultTags) //Ez majd akkor kell, ha átkerül be-re a fenti
            setSelectedTag(defaultTags)
        }
    }, [record])

    useEffect(() => {
        if (!tag.loading && !tag.loaded) {
            setTag({ ...tag, loading: true })
            dataProvider.get(`warehouse-product/tag`, { posSetId: selectedPosSetId })
                .then(value => {
                    //setTag({loading: false, loaded: true, data: value.data, error: undefined})
                    setTag({
                        loading: false,
                        loaded: true,
                        data: (value.data.map(value => value.name)),
                        error: undefined
                    })
                })
                .catch(reason => {
                    setTag({ loading: false, loaded: true, data: undefined, error: reason })
                })
        }
    }, [tag.loading, tag.loaded])

    useEffect(() => {
        const getRecords = async () => {
            try {
                const { data } = await dataProvider.get(`warehouse-product/unit`, { id: record?.id ? record.id : null });
                setBaseUnits(data.content);
                if (!record) {
                    return;
                }

                const firstUnitId = record.response ? record.response.units[0].unitId : record.units[0].unitId;

                const firstUnit = data.content.find(item => item.id === firstUnitId);

                const tempSellingUnits = data.content.filter(item => item.type === firstUnit.type);
                const customUnits = record.response ? record.response.units.map(obj => ({
                    id: obj.id,
                    unitName: obj.name,
                    type: "CUSTOM"
                })) : record.units.map(obj => ({
                    id: obj.id,
                    unitName: obj.name,
                    type: "CUSTOM"
                }))
                const recordInputFields = record.response ? record.response.units : record.units
                setInputFields(recordInputFields.map(item => {
                    item.disabled = true
                    return item
                }));
                setSellingUnits(tempSellingUnits.concat(customUnits));
                setTempUnits(data.content);
                setSelectedMainProductGroup(record.mainProductGroup)
            } catch (error) {
                setBaseUnits(null);
            }
        };

        getRecords();
        dataProvider.get(`warehouse-product/vatGroup`)
            .then(value => {
                setVatGroups(value.data.content)
            })
            .catch(() => {
                const vatGroups = [
                    { id: 10, name: "5%" },
                    { id: 11, name: "18%" },
                    { id: 12, name: "27%" },
                    { id: 13, name: "adójegyes" },
                    { id: 14, name: "0%" }
                ]
                setVatGroups(vatGroups)
            })
        dataProvider.get(`warehouse-product/mainProductGroups`, { posSetId: selectedPosSetId })
            .then(value => {
                setMainProductGroups(value.data)
            })
            .catch(reason => {
                setMainProductGroups([])
            })
    }, [])


    const handleChangeInput = (index, event) => {
        const values = [...inputFields]
        const siUnits = Array.from(unitMap.values()).flat();
        let inputValue = event.target.value.toLowerCase()
        if (siUnits.includes(inputValue)) {
            notify("warehouse.siUnitError", { type: "error", autoHideDuration: 3000 })
        }
        if (event.target.name === "quantity") {
            inputValue = inputValue.replace(/[^0-9.,]/g, '')
            if (inputValue.includes(",")) {
                inputValue = inputValue.replace(",", ".");
            }
        }
        values[index][event.target.name] = inputValue
        setInputFields(values)
        setTrigger(!trigger)
    }
    const handleUnitSelection = (index, unit) => {
        checkUnitCircles(unit, unitMap, index)
        setupSellingUnits(index, unit)
        const values = [...inputFields]
        values[index]["unitId"] = unit.id
        values[index]["unitName"] = unit.unitName
        values[index]["unitType"] = unit.type
        setInputFields(values)
        setTrigger(!trigger)
    }

    const setupSellingUnits = (index, unit) => {
        if (unit.type === inputFields[0].unitType || index === 0) {
            const tempSellingUnits = baseUnits.filter((item) => item.type === unit.type)
            if (unit.type === "CUSTOM") {
                setSellingUnits([...sellingUnits, unit])
            } else {
                const customUnit = sellingUnits.filter((item) => item.type === 'CUSTOM')
                setSellingUnits(tempSellingUnits.concat(customUnit))
            }
        }
    }

    const checkUnitCircles = (unit, unitMap, index) => {
        const key = unit.type
        const firstInputValue = inputFields[0]
        let firstInputValueKey
        if (firstInputValue.unitName !== "") {
            firstInputValueKey = findKeyByValues(firstInputValue.unitName, unitMap)
        } else {
            firstInputValueKey = key
        }
        if (firstInputValueKey !== key && inputFields.length > 1 && unit.type !== "CUSTOM" && index > 0) {
            notify("warehouse.unitDifference", { type: "error", autoHideDuration: 3000 })
        }
        if (firstInputValueKey === key) {
            let values = unitMap.get(key)
            if (inputFields.length === 2) {
                if (values.indexOf(unit.unitName) < values.indexOf(firstInputValue.unitName)) {
                    notify("warehouse.circleError", { type: "error", autoHideDuration: 3000 })
                }
            }
            if (inputFields.length === 3) {
                let secondIndex = values.indexOf(inputFields[1].unitName)
                let currentUnitIndex = values.indexOf(unit.unitName)
                if (currentUnitIndex < secondIndex) {
                    notify("warehouse.circleError", { type: "error", autoHideDuration: 3000 })
                }
            }
        }
        if (inputFields.map(item => item.unitName).includes(unit.unitName)) {
            notify("warehouse.unitError", { type: "error", autoHideDuration: 3000 })
        }
    }
    const findKeyByValues = (unit, unitMap) => {
        for (const [key, values] of unitMap.entries()) {
            if (values.includes(unit)) {
                return key;
            }
        }
    }
    const handleAddFields = () => {
        if (inputFields.length === 0) {
            setInputFields([...inputFields, {
                name: "",
                quantity: "",
                id: uuidv4(),
                unitId: "",
                unitName: "",
                units: baseUnits,
                disabled: false
            }])
        } else {
            setInputFields([...inputFields, {
                name: "",
                quantity: "",
                id: uuidv4(),
                unitId: "",
                unitName: "",
                units: tempUnits,
                disabled: false
            }])
        }
        setIsDisabledAddButton(true)
        setTrigger(!trigger)
    }
    const handleRemoveFields = () => {
        const oldInputFields = [...inputFields]
        const lastOldInputField = oldInputFields[oldInputFields.length - 1]?.name
        oldInputFields.splice(oldInputFields.length - 1, 1)
        const oldTempUnits = [...tempUnits]
        oldTempUnits.splice(oldTempUnits.length - 1, 1)
        setInputFields(oldInputFields)
        if (lastOldInputField) {
            setTempUnits(oldTempUnits)
        }
        if (inputFields.length <= 1) {
            setSellingUnits([])
            setIsDisabledAddButton(false)
            setValue("defaultUnitId", null)
        } else {
            const oldSellingUnits = [...sellingUnits]
            setSellingUnits(oldSellingUnits.filter(item => item.unitName !== lastOldInputField))
        }
        if (inputFields.length > 1) {
            setIsDisabledAddButton(false)
        }
        setTrigger(!trigger)
    }
    const addPackagingToUnits = (index, event) => {
        let inputValue = event.target.value.trim().toLowerCase()
        if (inputValue) {
            let tempArray = [...inputFields[index].units]
            let inputFieldId = inputFields[index]?.id
            tempArray.push({ id: inputFieldId, unitName: inputValue, type: "CUSTOM" })
            const childInputFields = inputFields.map((item, i) => {
                if (i > index) {
                    for (const unit of item.units) {
                        if (unit.id === inputFieldId) {
                            unit.unitName = inputValue
                        }
                    }
                }
                return item
            })
            if (tempArray.length > tempUnits.length || inputFields.length === 1) {
                setTempUnits(tempArray)
            }
            if (!sellingUnits.map(item => item.unitName).includes(inputValue)) {
                const customUnits = inputFields.map((inputField) => {
                    return { id: inputField.id, unitName: inputField.name, type: "CUSTOM" }
                })
                let baseSellingUnits = []
                baseSellingUnits = baseUnits.filter((item) => item.type === findKeyByValues(inputFields[0].unitName, unitMap))
                const units = customUnits.concat(baseSellingUnits)
                setSellingUnits([...units])
            }

            setInputFields(childInputFields)
            setIsDisabledAddButton(false)
        }
        setTrigger(!trigger)
    }

    const handleTagInputBlur = (event) => {
        const inputValue = event.target.value.trim()
        if (inputValue && !selectedTag.includes(inputValue)) {
            setSelectedTag([...selectedTag, inputValue])
        }
    }

    return (
        <>
            <Container sx={{ width: "700px", margin: "0", paddingLeft: "0!important" }}>
                <TextInput fullWidth source="name" label={"warehouse.name"} validate={required()}
                    inputProps={{ maxLength: 255 }} name={"name"} onChange={() => {
                        setTrigger(!trigger)
                    }} />
                <Autocomplete
                    multiple
                    id="tags"
                    getOptionLabel={(data) => data?.toString()}
                    options={tag.data}
                    value={selectedTag}
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    onChange={handleTag}
                    onBlur={handleTagInputBlur}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{ ...params.inputProps, maxLength: 64 }}
                            variant="filled"
                            label={translate('warehouse.tags')}
                        />
                    )}
                />
                <Autocomplete
                    options={mainProductGroups}
                    getOptionLabel={(option) => option?.toString()}
                    onInputChange={(e, newValue) => {
                        setSelectedMainProductGroup(newValue);
                    }}
                    value={selectedMainProductGroup}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={translate('warehouse.mainProductGroup')}
                            inputProps={{ ...params.inputProps, maxLength: 64 }}
                            variant="filled"
                            onBlur={(e) => {
                                if (mainProductGroups.findIndex((o) => o === selectedMainProductGroup) === -1 && e.target.value.trim()) {
                                    setMainProductGroups([...mainProductGroups, e.target.value.trim()]);
                                    setSelectedMainProductGroup(e.target.value.trim())
                                }
                            }}
                        />
                    )}
                />
                <Stack direction="row" spacing={2} sx={{ marginTop: '10px' }}>
                    <SelectInput optionText={"name"} label={"products.vatGroup"} defaultValue={12} source={"vatId"}
                        choices={vatGroups} validate={required()} />
                    <BooleanInput source={"activeFlag"} label={"warehouse.available"} name={"activeFlag"}
                        defaultValue={true} />
                </Stack>
                {inputFields.map((inputField, index) => (
                    <div key={index}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                        <TextField id={`inputField-packaging-${index}`}
                            label={translate("warehouse.packagingUnit")}
                            name={"name"}
                            required={true}
                            defaultValue={inputField.name}
                            value={inputField.name}
                            inputProps={{ style: { textTransform: "lowercase" } }}
                            disabled={inputField.disabled}
                            onBlur={event => addPackagingToUnits(index, event)}
                            onChange={event => {
                                handleChangeInput(index, event)
                            }} />
                        <Box sx={{ marginTop: "20px" }}><DragHandleIcon /></Box>
                        <TextField id={`inputField-quantity-${index}`}
                            label={translate("warehouse.quantity")}
                            inputProps={{
                                inputMode: 'numeric'
                            }}
                            name={"quantity"}
                            required={true}
                            disabled={inputField.disabled}
                            defaultValue={inputField.quantity}
                            value={inputField.quantity}
                            onChange={event => handleChangeInput(index, event)} />
                        <Autocomplete
                            sx={{ width: "150px" }}
                            options={inputField.units}
                            defaultValue={record ? record.units.find(item => item.unitName === inputField.unitName) : null}
                            freeSolo={!!record}
                            disabled={inputField.disabled}
                            getOptionLabel={option => option.unitName}
                            disableClearable
                            renderInput={(params) => <TextField
                                id={`inputField-unit-${index}`}
                                name={"unit"} {...params} required={true}
                                label={translate("warehouse.unit")} />}
                            onChange={(event, unit) => handleUnitSelection(index, unit)}
                        />
                    </div>
                ))}
                <div>
                    <IconButton color={"primary"} onClick={() => handleAddFields()}
                        disabled={isDisabledAddButton}>
                        <AddIcon />
                    </IconButton>
                    <IconButton color={"primary"} onClick={() => handleRemoveFields()}
                        disabled={record ? inputFields.length <= record.units.length : false}
                    >
                        <RemoveIcon />
                    </IconButton>
                </div>
                {inputFields.length > 0 &&
                    <Box>
                        <AutocompleteInput source={"defaultUnitId"} optionText={"unitName"}
                            validate={required()}
                            label={"warehouse.defaultUnit"}
                            choices={sellingUnits}
                            onChange={() => {setTrigger(!trigger)}}
                            name={"defaultUnitId"}></AutocompleteInput>

                        <AutocompleteInput source={"defaultWarehouseUnitId"} optionText={"unitName"}
                            validate={required()}
                            label={"warehouse.defaultWarehouseUnit"}
                            choices={sellingUnits}
                            onChange={() => {setTrigger(!trigger)}}
                            name={"defaultWarehouseUnitId"}></AutocompleteInput>
                    </Box>
                }
            </Container>
        </>
    )
}

export default WarehouseProductDetails