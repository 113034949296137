import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  OutlinedInput,
  ListItemText,
  Box,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio, Card, IconButton, FormGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import {muiDatagridTheme} from "../../components/MuiDatagridTheme";
import { useLocaleState, useTranslate, useNotify, useRedirect, useDataProvider } from "react-admin";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { AddCircle, RemoveCircle } from "@mui/icons-material";

export const VoucherGeneratorDetails = () => {
  const [sendData, setSendData] = useState({
    name: null,
    validFrom: dayjs(),
    validTo: null,
    packageConfigurations: [],
    voucherType: null,
    licenseCount: null,
    maxUsageCount: null,
    customCodes: null,
    validForCycle: null,
    codeCount: null,
    codeLength: null,
  });
  const [licensePackages, setLicensePackages] = useState([]);
  const [isCustomCode, setIsCustomCode] = useState(false);
  const [errors, setErrors] = useState({});
  const locale = useLocaleState();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const types = [
    { id: 1, name: "Százalékos kedvezmény", value: "DISCOUNT_PERCENTAGE" },
    { id: 2, name: "Összeg kedvezmény", value: "FIX_PRICE" },
  ];

  useEffect(() => {
    dataProvider
      .get(`voucherGenerator/licensePackages`)
      .then((value) => {
        setLicensePackages(value.data);
      })
      .catch(() => {
        setLicensePackages([]);
      });
  }, []);

  const validate = () => {
    const newErrors = {};
    if (!sendData.name) newErrors.name = translate("voucher.required");
    if (!sendData.validTo) newErrors.validTo = translate("voucher.required");
    if (!sendData.voucherType) newErrors.voucherType = translate("voucher.required");
    if (!sendData.licenseCount) newErrors.licenseCount = translate("voucher.required");
    if (!sendData.maxUsageCount) newErrors.maxUsageCount = translate("voucher.required");
    if (!sendData.validForCycle) newErrors.validForCycle = translate("voucher.required");
    if (!isCustomCode && !sendData.codeCount) newErrors.codeCount = translate("voucher.required");
    if (!isCustomCode && !sendData.codeLength) newErrors.codeLength = translate("voucher.required");
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const voucherGenerate = (data) => {
    if (!validate()) return;
    dataProvider.create("voucherGenerator", { data })
      .then(() => {
        notify(translate("voucher.success"));
        setTimeout(() => {
          redirect("/voucherGenerator");
        }, 1000);
      })
      .catch((error) => {
        if (error.message === 'Name already exists') {
          notify('Létezik ilyen nevű voucher', { type: 'error' });
        }
        else if (error.message === 'Custom code already exists') {
          notify('Létezik ilyen nevű kuponkód', { type: 'error' });
        }
        else {
          notify(translate("voucher.error"), { type: 'error' });
        }
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSendData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePositiveIntegerChange = (event) => {
    const { name, value } = event.target;
    const positiveInteger = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    setSendData((prevData) => ({
      ...prevData,
      [name]: positiveInteger,
    }));
  };

  const handleDateChange = (name, value) => {
    setSendData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    let newValue = value;
    if (name === "codeCount") {
      if (value < 1) {
        newValue = 1;
      }
    }
    else {
      if (value < 10) {
        newValue = 10;
      } else if (value > 20) {
        newValue = 20;
      }
    }
    setSendData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleRadioChange = (event) => {
    if (event.target.value === "true") {
      setIsCustomCode(true);
      setSendData((prevData) => ({
        ...prevData,
        customCodes: [""],
      }));
    } else {
      setIsCustomCode(false);
      setSendData((prevData) => ({
        ...prevData,
        customCodes: null,
      }));
    }
  };

  const baseStyle = { padding: "10px" };

  return (
    <>
      <Card>
        <Box sx={{ width: "900px", padding: "15px", display: "flex", justifyContent: "space-between" }}>
          <TextField
            variant="standard"
            label={translate("voucher.createName")}
            name="name"
            onChange={handleChange}
            value={sendData.name}
            required={true}
            sx={{ width: 300 }}
            error={!!errors.name}
            helperText={errors.name}
          />
          <ThemeProvider theme={muiDatagridTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale[0]}>
              <DateTimePicker
                label={translate("voucher.validFrom")}
                value={sendData.validFrom}
                onChange={(value) => handleDateChange("validFrom", value)}
                renderInput={(params) => <TextField {...params} />}
                minDateTime={dayjs().set("hour", 0).set("minute", 0)}
              />
            </LocalizationProvider>
          </ThemeProvider>
          <ThemeProvider theme={muiDatagridTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale[0]}>
              <DateTimePicker
                label={translate("voucher.validTo")}
                value={sendData.validTo}
                onChange={(value) => handleDateChange("validTo", value)}
                renderInput={(params) => <TextField {...params} />}
                minDateTime={sendData.validFrom}
                required={true}
                error={!!errors.validTo}
                helperText={errors.validTo}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </Box>
        <Box sx={baseStyle}>
          <TextField
            label={translate("voucher.maxUsageCount")}
            variant="standard"
            type="number"
            name="maxUsageCount"
            value={sendData.maxUsageCount}
            onChange={handlePositiveIntegerChange}
            required={true}
            sx={{ width: 300 }}
            error={!!errors.maxUsageCount}
            helperText={errors.maxUsageCount}
          />
          <Tooltip title={<span style={{ fontSize: "14px" }}>{translate("voucher.maxUsageTooltip")}</span>} placement="right" arrow>
            <IconButton>
              <HelpIcon sx={{ color: "#ff71bc" }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={baseStyle}>
          <TextField
            label={translate("voucher.validForCycle")}
            type="number"
            variant="standard"
            name="validForCycle"
            value={sendData.validForCycle}
            onChange={handlePositiveIntegerChange}
            required={true}
            sx={{ width: 300 }}
            error={!!errors.validForCycle}
            helperText={errors.validForCycle}
          />
          <Tooltip title={<span style={{ fontSize: "14px" }}>{translate("voucher.validForCycleTooltip")}</span>} placement="right" arrow>
            <IconButton>
              <HelpIcon sx={{ color: "#ff71bc" }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={baseStyle}>
          <FormControl sx={{ width: 300 }} required={true} error={!!errors.voucherType}>
            <InputLabel id="demo-multiple-checkbox-label">{translate("voucher.discount")}</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              name="voucherType"
              value={sendData.voucherType}
              onChange={(e) => {
                setSendData((prevData) => ({
                  ...prevData,
                  ["voucherType"]: e.target.value,
                  ["packageConfigurations"]: prevData.packageConfigurations.map((pc) => ({ ...pc, discountPercent: null, fixPrice: null }))
                }));
              }
              }
              input={<OutlinedInput label="Típus" />}
              sx={{ height: 60 }}
              required={true}
            >
              {types.map((type) => (
                <MenuItem key={type.id} value={type.value}>
                  <ListItemText primary={type.name} />
                </MenuItem>
              ))}
            </Select>
            {errors.voucherType && <p style={{ color: "red" }}>{errors.voucherType}</p>}
          </FormControl>
        </Box>
        <Box sx={{ padding: "10px", display: "flex" }}>
          <FormGroup sx={{ flexDirection: "row" }}>
            {licensePackages.map((packageItem) => (
              <Box key={packageItem.id} sx={{ display: "flex", flexDirection: "column" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendData.packageConfigurations.some((pc) => pc.packageId === packageItem.id)}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSendData((prevData) => ({
                            ...prevData,
                            packageConfigurations: [...prevData.packageConfigurations, { packageId: packageItem.id }],
                          }));
                        } else {
                          setSendData((prevData) => ({
                            ...prevData,
                            packageConfigurations: prevData.packageConfigurations.filter((pc) => pc.packageId !== packageItem.id),
                          }));
                        }
                      }}
                    />
                  }
                  label={packageItem.name}
                />
                {sendData.voucherType === "DISCOUNT_PERCENTAGE" && sendData.packageConfigurations.some((pc) => pc.packageId === packageItem.id) ? (
                  <Box sx={baseStyle}>
                    <TextField
                      label={translate("voucher.discountPercent")}
                      variant="standard"
                      name="discountPercent"
                      value={sendData.packageConfigurations.find((pc) => pc.packageId === packageItem.id)?.discountPercent || ""}
                      required={true}
                      onChange={(event) => {
                        const { value } = event.target;
                        const positiveNumber = value.replace(/[^0-9.,]/g, '').replace(',', '.');
                        const updatedPackageConfigurations = [...sendData.packageConfigurations];
                        const pc = updatedPackageConfigurations.find((pc) => pc.packageId === packageItem.id);
                        pc.discountPercent = positiveNumber;
                        pc.fixPrice = null;
                        setSendData((prevData) => ({ ...prevData, packageConfigurations: updatedPackageConfigurations }));
                      }}
                      sx={{ width: 150 }}
                    />
                  </Box>
                ) : sendData.voucherType === "FIX_PRICE" && sendData.packageConfigurations.some((pc) => pc.packageId === packageItem.id) ? (
                  <Box sx={baseStyle}>
                    <TextField
                      label={translate("voucher.fixPrice")}
                      variant="standard"
                      name="fixPrice"
                      required={true}
                      value={sendData.packageConfigurations.find((pc) => pc.packageId === packageItem.id)?.fixPrice || ""}
                      onChange={(event) => {
                        const { value } = event.target;
                        const positiveNumber = value.replace(/[^0-9.,]/g, '').replace(',', '.');
                        const updatedPackageConfigurations = [...sendData.packageConfigurations];
                        const pc = updatedPackageConfigurations.find((pc) => pc.packageId === packageItem.id);
                        pc.fixPrice = positiveNumber;
                        pc.discountPercent = null;
                        setSendData((prevData) => ({ ...prevData, packageConfigurations: updatedPackageConfigurations }));
                      }}
                      sx={{ width: 150 }}
                    />
                  </Box>
                ) : null}
              </Box>
            ))}
          </FormGroup>
          <Tooltip title={<span style={{ fontSize: "14px" }}>{translate("voucher.licenseTooltip")}</span>} placement="right" arrow>
            <IconButton>
              <HelpIcon sx={{ color: "#ff71bc" }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={baseStyle}>
          <TextField
            label={translate("voucher.codeCount")}
            type="number"
            name="licenseCount"
            variant="standard"
            required={true}
            value={sendData.licenseCount}
            onChange={handlePositiveIntegerChange}
            sx={{ width: 300 }}
            error={!!errors.licenseCount}
            helperText={errors.licenseCount}
          />
        </Box>
        <Box sx={baseStyle}>
          <FormControl>
            <FormLabel id="voucher-radio-button">{translate("voucher.custom")}</FormLabel>
            <RadioGroup
              aria-labelledby="voucher-radio-button"
              defaultValue="false"
              name="radio-buttons-group"
              onChange={handleRadioChange}
            >
              <FormControlLabel value="true" control={<Radio />} label={translate("voucher.yes")} />
              <FormControlLabel value="false" control={<Radio />} label={translate("voucher.no")} />
            </RadioGroup>
          </FormControl>
          {isCustomCode && (
            <Tooltip title={<span style={{ fontSize: "14px" }}>{translate("voucher.customTip")}</span>} placement="right" arrow>
              <IconButton>
                <HelpIcon sx={{ color: "#ff71bc" }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {isCustomCode ? (
          <Box sx={baseStyle}>
            {sendData.customCodes.map((code, index) => (
              <div key={index}>
                <TextField
                  label={translate("voucher.voucherCode")}
                  variant="standard"
                  name="customCodes"
                  value={code}
                  required={true}
                  onChange={(event) => {
                    const updatedCustomCodes = [...sendData.customCodes];
                    updatedCustomCodes[index] = event.target.value;
                    setSendData((prevData) => ({
                      ...prevData,
                      customCodes: updatedCustomCodes,
                    }));
                  }}
                  sx={{ width: 300 }}
                />
                <IconButton
                  onClick={() => {
                    setSendData((prevState) => ({
                      ...prevState,
                      customCodes: [...prevState.customCodes, ""],
                    }));
                  }}
                >
                  <AddCircle sx={{ color: "#ff71bc" }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    const updatedCustomCodes = [...sendData.customCodes];
                    updatedCustomCodes.splice(index, 1);
                    setSendData((prevData) => ({
                      ...prevData,
                      customCodes: updatedCustomCodes,
                    }));
                  }}
                  disabled={sendData.customCodes.length === 1}
                >
                  <RemoveCircle sx={{ color: sendData.customCodes.length === 1 ? "gray" : "#ff71bc" }} />
                </IconButton>
              </div>
            ))}
          </Box>
        ) : (
          <Box sx={baseStyle}>
            <TextField
              label={translate("voucher.LicenseCount")}
              type="number"
              name="codeCount"
              variant="standard"
              value={sendData.codeCount}
              required={true}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ width: 300 }}
              inputProps={{ min: 1 }}
              error={!!errors.codeCount}
              helperText={errors.codeCount}
            />
          </Box>
        )}
        {!isCustomCode && (
          <Box sx={baseStyle}>
            <TextField
              label={translate("voucher.codeLength")}
              type="number"
              name="codeLength"
              variant="standard"
              value={sendData.codeLength}
              required={true}
              onChange={handleChange}
              sx={{ width: 300 }}
              onBlur={handleBlur}
              inputProps={{ min: 10, max: 20 }}
              error={!!errors.codeLength}
              helperText={errors.codeLength}
            />
          </Box>
        )}
        <Button onClick={() => voucherGenerate(sendData)} disabled={sendData.packageConfigurations.length === 0 || sendData.validTo === null}>
          {translate("voucher.generate")}
        </Button>
      </Card>
    </>
  );
};
