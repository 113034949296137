import SelectCompanyWithBusinessUnitInput from "../../../components/SelectCompanyWithBusinessUnitInput";
import React, {useEffect, useState} from "react";
import {
    CreateButton,
    Datagrid,
    DateField,
    FunctionField,
    List,
    TextField,
    TopToolbar,
    useLocaleState
} from "react-admin";
import {EmptyPage} from "../../../components/EmtpyPage";
import {PinkPagination} from "../../../components/PinkPagination";
import {getValidPrivilegesIds} from "../../../components/authentication/PermissionHandler";
import RedirectToPurchase from "../../../components/RedirectToPurchase";
import {IconButton} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";

const InventoryList = ({permissions}) => {
    const [posSet, setPosSet] = useState(0)
    const locale = useLocaleState()
    const hasShow = getValidPrivilegesIds("WAREHOUSE", "GET").some(r => permissions.includes(r))
    const hasCreate = getValidPrivilegesIds("WAREHOUSE", "CREATE").some(r => permissions.includes(r))
    const [isShowRedirect, setIsShowRedirect] = useState(false)
    const url = window.location.href.split('?')[0]

    useEffect(() => {
        if (posSet && posSet !== "undefined" && parseInt(posSet) !== 0) {
            localStorage.setItem("inventoryPosSetId", posSet)
        }
    }, [posSet])

    return (
        <>
            <SelectCompanyWithBusinessUnitInput getResource={"inventory"} setPosSet={setPosSet} setIsShowRedirect={setIsShowRedirect}/>
            {isShowRedirect ?
                <RedirectToPurchase resource={"warehouse"}/>
                :
                posSet && posSet !== "undefined" && parseInt(posSet) !== 0 &&
                <>
                <List actions={<TopToolbar>
                    {hasCreate && <CreateButton label={"inventory.create"}/>}
                </TopToolbar>}
                      pagination={<PinkPagination trigger={posSet}/>}
                      filterDefaultValues={{posSetId: posSet}}
                      title={"inventory.title"}
                      empty={<EmptyPage text={"inventory.title"} createEnabled={true}/>}
                >
                    <Datagrid bulkActionButtons={false} rowClick={hasShow ? 'show' : null}>
                        <TextField source="id" label={"inventory.id"} sortable/>
                        <FunctionField render={
                            record => {
                                return (
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        <span>{record.warehouseName}</span>
                                        <IconButton onClick={(event) => {
                                            event.stopPropagation()
                                            window.open(`${url}/${record.id}/show`, '_blank', 'noopener,noreferrer')
                                        }}>
                                            <OpenInNewIcon/>
                                        </IconButton>
                                    </div>
                                )
                            }
                        } source="warehouseName" label={"inventory.warehouse"}/>
                        <DateField locales={locale[0]} showTime source="inventoryDate" label={"inventory.date"}/>
                        <TextField source="comment" label={"inventory.comment"} sortable={false}/>
                    </Datagrid>
                </List>
                <img src={shorticLogo}
            style={{position: "absolute", bottom: "0", left: "20", width: "150px", paddingBottom: '10px'}}/>
</>
}
</>
)
}

export default InventoryList;