import {Edit} from 'react-admin';
import {PriceListTemplateDetails} from "./PriceListTemplateDetails";

export const PriceListTemplateEdit = (props) => {
    const transform = (data) => {
        let sanitizedData = Object.assign({}, data);
        for (let i = 0; i < sanitizedData.products.length; i++) {
            if (typeof sanitizedData.products[i].id === "string" && sanitizedData.products[i].id.startsWith('x')) sanitizedData.products[i].id = null;
            //remove 'x' prefix and set null
        }
        return sanitizedData;
    };
    return (
        <Edit mutationMode="pessimistic" transform={transform}>
            <PriceListTemplateDetails  {...props} />
        </Edit>
    )
}