import {Button, Card, CardActions, CardContent, Typography} from "@mui/material";
import {useTranslate} from "react-admin";
import {useRedirect} from "react-admin";

const RedirectToPurchase = ({resource}) => {
    const redirect = useRedirect()
    const translate = useTranslate()
    return (
    <Card sx={{ width: 400 }}>
        <CardContent>
            <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                {translate("redirectToPurchase.title")}
            </Typography>
            <br/>
            <Typography variant="body2">
                {translate(`${resource}.buy`)}
            </Typography>
        </CardContent>
        <CardActions>
            <Button onClick={()=>{redirect("/license")}} size="small">{translate("products.continueToBuy")}</Button>
        </CardActions>
    </Card>
    )
}

export default RedirectToPurchase