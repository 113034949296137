import {
    BooleanField,
    CreateButton,
    Datagrid,
    ExportButton,
    FunctionField,
    List,
    TextField,
    TopToolbar, useTranslate
} from "react-admin";
import {EmptyPage} from "../../../components/EmtpyPage";
import SelectCompanyWithBusinessUnitInput from "../../../components/SelectCompanyWithBusinessUnitInput";
import React, {useEffect, useState} from "react";
import {PinkPagination} from "../../../components/PinkPagination";
import {getValidPrivilegesIds} from "../../../components/authentication/PermissionHandler";
import RedirectToPurchase from "../../../components/RedirectToPurchase";
import * as XLSX from "xlsx";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";

const WarehouseStorageList = ({permissions}) => {
    const [posSet, setPosSet] = useState(0)
    const hasEdit = getValidPrivilegesIds("WAREHOUSE", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    const hasCreate = getValidPrivilegesIds("WAREHOUSE", "CREATE").some(r => permissions.includes(r))
    const [isShowRedirect, setIsShowRedirect] = useState(false)
    const translate = useTranslate()

    const customRowClick = (id, resource, record) => (record.defaultFlag ? false : hasEdit)
    useEffect(() => {
        if (posSet && posSet !== "undefined" && parseInt(posSet) !== 0) {
            localStorage.setItem("warehousePosSetId", posSet)
        }
    }, [posSet])

    const ListActions = () => {
        return (
            <TopToolbar>
                {hasCreate && <CreateButton/>}
                <ExportButton/>
            </TopToolbar>
        )
    }


    const exporter = warehouses => {
        const headers = [translate("storage.name"), translate("storage.address"), translate("storage.phone"), translate("storage.email"),
            translate("storage.contact"), translate("storage.isLogistics")]
        let exportedProducts = []
        warehouses.forEach(item => {
            let arr = [
                item.name,
                item.address,
                item.phonePrefix + item.phone,
                item.email,
                item.contact,
                item.isLogistics ? "Igen" : "Nem",
            ]
            exportedProducts.push(arr)
        })
        exportedProducts.unshift(headers)
        const ws = XLSX.utils.aoa_to_sheet(exportedProducts);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "export");
        XLSX.writeFile(wb, "export.xlsx");
    }

    return (
        <>
        <SelectCompanyWithBusinessUnitInput getResource={"warehouse"} setIsShowRedirect={setIsShowRedirect}
                                            setPosSet={setPosSet}/>

        {isShowRedirect ?
            <RedirectToPurchase resource={"warehouse"}/> :
            posSet &&
            <>
            <List actions={<ListActions/>}
        title={"storage.title"}
        exporter={exporter}
        empty={<EmptyPage text={"storage.empty"} createEnabled={true}/>}
        pagination={<PinkPagination trigger={posSet}/>}
        filterDefaultValues={{posSetId: posSet}}
        >
        <Datagrid bulkActionButtons={false} rowClick={customRowClick}>
            <TextField source="id" label={"storage.id"} sortable/>
            <TextField source="name" label={"storage.name"} sortable/>
            <TextField source="address" label={"storage.address"} sortable/>
            <FunctionField label={"storage.phone"}
                           render={record => record.phonePrefix == null || record.phone == null ? null : `${record.phonePrefix} ${record.phone}`}/>
            <TextField source="email" label={"storage.email"} sortable={false}/>
            <TextField source="contact" label={"storage.contact"} sortable={false}/>
            <BooleanField source={"isLogistics"} label={"storage.isLogistics"} sortable/>
            <BooleanField source={"activeFlag"} label={"storage.isActive"} sortable/>
        </Datagrid>
        </List>
    <img src={shorticLogo}
         style={{position: "absolute", bottom: "0", left: "20", width: "150px", paddingBottom: '10px'}}/>
            </>
}
</>

)
}

export default WarehouseStorageList