import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import {DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {useDataProvider, useRecordContext, useTranslate} from "react-admin";
import {LinearProgress, Typography} from "@mui/material";
import RecursiveTreeView from "./RecursiveTreeView";

export const PermissionDatagrid = ({scopedFormData, propAdmin, resource}) => {
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    //datagrid
    const [roles, setRoles] = useState([])
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const [selectedRow, setSelectedRow] = useState([])
    //posSets
    const [posSets, setPosSets] = useState({loading: false, loaded: false, data: [], error: undefined});
    const [selectedPosSets, setSelectedPosSets] = useState([]);
    const [isDirty, setIsDirty] = useState(false)


    useEffect( () => {
        if (scopedFormData){
            scopedFormData[propAdmin ? "privilegeIdsAdmin" : "privilegeIdsApi"] = []
        }
        //datagrid
        if (roles.length === 0) {
            let tempColumns;
            dataProvider.get("auth/roles").then((value) => {
                setRoles(value.data)
                tempColumns = [{
                    field: 'name',
                    headerName: '',
                    width: 400,
                    renderCell: (param) => <div style={{textAlign: "center"}}>{translate("invitePermission."+param.value)}</div>,
                    sortable: false
                }];
                tempColumns = tempColumns.concat(value.data.map(x => {
                        return {
                            field: x.role,
                            width: 150,
                            renderCell: (param) => <div style={{width: 150, textAlign: "center"}}>{param.value ? <DoneIcon color={"primary"} /> : <ClearIcon opacity={0.2}/>}</div>,
                            sortable: false
                        }
                    }))
                tempColumns = tempColumns.concat(
                    [{
                        field: "fullAccess",
                        width: 150,
                        renderCell: (param) => <div style={{width: 150, textAlign: "center"}}>{param.value ? <DoneIcon color={"primary"} /> : <ClearIcon opacity={0.2}/>}</div>,
                        sortable: false
                    },
                    {
                        field: 'checkbox',
                        width: 100,
                        ...GRID_CHECKBOX_SELECTION_COL_DEF,
                        sortable: false
                    }])
                getRows(value.data, tempColumns)
            })
        }
        //posSets
        if (!posSets.loading && !posSets.loaded) {
            setPosSets({...posSets, loading: true});
            dataProvider.get(propAdmin ? `${resource}` : `${resource}/api`, {userId: record?.id})
                .then(value => {
                    setPosSets({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setPosSets({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    },[]);

    useEffect(() => {
        scopedFormData[propAdmin ? "posSetsAdmin" : "posSetsApi"] = selectedPosSets || []
    }, [selectedPosSets])

    const privilegeSetter = (privileges, automaticFromPosSetSelector) => {
        if(!automaticFromPosSetSelector || selectedRow.length === 0 || !isDirty) {
            let filteredPrivs = privileges?.filter(obj => rows.some(element => element.id === obj)) || []
            if(filteredPrivs.some(elem => [10030, 10040, 10090].includes(elem))) {
                setRows(rows.map(e => {if(e.id === 10100) {e.disabled = true} return e}))
                if(!filteredPrivs.includes(10100)){
                    filteredPrivs.push(10100)
                }
            }
            else{
                setRows(rows.map(e => {if(e.id === 10100) {e.disabled = false} return e}))
            }

            if(filteredPrivs.some(elem => [220, 240].includes(elem))){
                setRows(rows.map(e => {if(e.id === 160) {e.disabled = true} return e}))
                if(!filteredPrivs.includes(160)){
                    filteredPrivs.push(160)
                }
            }
            else{
                setRows(rows.map(e => {if(e.id === 160) {e.disabled = false} return e}))
            }

            if(filteredPrivs.some(elem => [220].includes(elem))){
                setRows(rows.map(e => {if(e.id === 240) {e.disabled = true} return e}))
                if(!filteredPrivs.includes(240)){
                    filteredPrivs.push(240)
                }
            }
            else{
                setRows(rows.map(e => {if(e.id === 240) {e.disabled = false} return e}))
            }

            if(filteredPrivs.some(elem => [260].includes(elem))){
                setRows(rows.map(e => {if(e.id === 250) {e.disabled = true} return e}))
                if(!filteredPrivs.includes(250)){
                    filteredPrivs.push(250)
                }
            }
            else{
                setRows(rows.map(e => {if(e.id === 250) {e.disabled = false} return e}))
            }

            setSelectedRow(filteredPrivs)
            if (scopedFormData){
                scopedFormData[propAdmin ? "privilegeIdsAdmin" : "privilegeIdsApi"] = filteredPrivs
            }
        }
    }

    const getRows = (data, columns) => {
        dataProvider.get("auth/giveable-privileges").then(value => {
            value.data = propAdmin ?  value.data.admin : value.data.api
                for (let i = 1; i < columns.length-1; i++)
                {
                    columns[i].renderHeader = () =>(
                        <div style={{width: 150, height: 50, textAlign: "center", justifyContent: "center"}}
                             onClick={() => {
                                 let rowsToBeSelect = (i === columns.length-2 ? value.data.map(obj => obj.id) : value.data.filter(obj => data[i-1].privilegeIds.includes(obj.id))?.map(obj => obj.id)) || []
                                 setRows(value.data.map(x => {
                                         return {
                                             id: x.id,
                                             key: x.id,
                                             name: x.name,
                                             Pultos: data[0].privilegeIds.includes(x.id),
                                             Pultfőnök: data[1].privilegeIds.includes(x.id),
                                             Pénzügyes: data[2].privilegeIds.includes(x.id),
                                             Adminisztrátor: data[3].privilegeIds.includes(x.id),
                                             fullAccess: true,
                                             disabled:
                                                 (rowsToBeSelect.some(elem => [10030, 10040, 10090].includes(elem)) && x.id === 10100 ||
                                                 rowsToBeSelect.some(elem => [220, 240].includes(elem)) && x.id === 160 ||
                                                 rowsToBeSelect.some(elem => [220].includes(elem)) && x.id === 240 )
                                         }
                                 }))
                                 setIsDirty(true)
                                 setSelectedRow(rowsToBeSelect)
                                 if (scopedFormData){
                                     scopedFormData[propAdmin ? "privilegeIdsAdmin" : "privilegeIdsApi"] = rowsToBeSelect
                                 }
                             }}
                        >
                            <Typography style={{marginBottom: -10}}>{i !== columns.length-2 ? data[i-1].role : "Teljes hozzáférés"}</Typography>
                            <DoneIcon color={"primary"}/>
                        </div>
                    )
                }
                setRows(value.data.map(x => {
                    return {
                        id: x.id,
                        key: x.id,
                        name: x.name,
                        Pultos: data[0].privilegeIds.includes(x.id),
                        Pultfőnök: data[1].privilegeIds.includes(x.id),
                        Pénzügyes: data[2].privilegeIds.includes(x.id),
                        Adminisztrátor: data[3].privilegeIds.includes(x.id),
                        fullAccess: true,
                        disabled: false
                    }
                }))
            setColumns(columns)
        })
    }

    return <>
        <div style={{width: 1250, marginBottom: 10}}>
            <DataGrid
                rows={rows}
                columns={columns}
                hideFooter
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    privilegeSetter(newRowSelectionModel, false)
                    setIsDirty(true)
                }}
                rowSelectionModel={selectedRow}
                disableColumnFilter
                disableColumnMenu
                isRowSelectable={(row) => !(row.row.disabled)}
            />
        </div>
        {
            (posSets.loading || !posSets.loaded) ?
                <LinearProgress/> :
                <div style={{display: 'inline-flex', flexDirection: 'column', marginBottom: 30}}>
                    {Array.isArray(posSets.data)
                        ?
                            posSets.data?.map((tree, i) =>{
                                return(<RecursiveTreeView key={i} data={tree} selected={selectedPosSets} setSelected={setSelectedPosSets} disabledList={[]} privilegeSetter={privilegeSetter} />)
                            })
                        :
                            <RecursiveTreeView data={posSets.data} selected={selectedPosSets} setSelected={setSelectedPosSets} disabledList={[]} privilegeSetter={privilegeSetter} />
                    }
                </div>
        }
    </>

}