import * as React from 'react';
import {
    LocalesMenuButton,
    AppBar,
    useTranslate,
    UserMenu,
    MenuItemLink,
    useUserMenu,
    useLogout
} from 'react-admin';
import {forwardRef} from "react";
import {Box, MenuItem, Typography} from "@mui/material";
import logo from '../assets/pinkpos-logo.svg';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';

const MyLogoutButton = forwardRef((props, ref) => {
    const translate = useTranslate()
    const logout = useLogout();
    return (
        <MenuItem
            onClick={() => logout()}
            ref={ref}
            // It's important to pass the props to allow MUI to manage the keyboard navigation
            {...props}
        >
            <ExitIcon /> <Typography style={{marginLeft: "15px"}}>{translate("common.logout")}</Typography>
        </MenuItem>
    );
});

const MyEditProfileButton = () => {
    const translate = useTranslate()
    const { onClose } = useUserMenu();
    return (
        <MenuItemLink
            to="/profile"
            primaryText={translate("common.editProfile")}
            onClick={onClose}
            leftIcon={<SettingsIcon />}
            sx={{borderLeft: '10px solid transparent'}}
        />
    );
}

const MyUserMenu = (props) => (
    <UserMenu {...props}>
        <MyEditProfileButton />
        <MyLogoutButton style={{marginLeft: "10px"}} />
    </UserMenu>
);

export const PinkAppBar = props => {
    return(
        <AppBar
            sx={{
                "& .RaAppBar-title": {
                    flex: 1,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                },
            }}
            {...props}
            userMenu={<MyUserMenu />}
        >

            <img style={{maxWidth: "30px", marginRight: "16px"}} src={logo} alt="logo"  />
            <Typography
                variant="h6"
                color="inherit"
                id="react-admin-title"
            />

            <Box style={{position: "absolute", right: "8px" }}>
                <LocalesMenuButton
                    languages={[
                        { locale: 'hu', name: 'Magyar' },
                        { locale: 'en', name: 'English' },
                    ]}
                />
            </Box>
        </AppBar>
);
}
