import {Box, Card, CardContent, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Datagrid, FunctionField, InfiniteList, TextField, useTranslate} from "react-admin";
import {NumericFormat} from "react-number-format";

export const Top10ProductCard = ({title, queryParams, selectedIds, hasActiveWorkday, doRefresh, doneRefresh}) => {
    const translate = useTranslate()
    const [workday, setWorkday] = useState(null)
    const [startPeriod, setStartPeriod] = useState(null)
    const [endPeriod, setEndPeriod] = useState(null)

    useEffect(() => {
        if(hasActiveWorkday !== undefined && selectedIds.length !== 0) {
            setWorkday(queryParams.type === "activeWorkday" && hasActiveWorkday)
            if(doRefresh || !(queryParams.type === "activeWorkday" && hasActiveWorkday)) {
                setStartPeriod((queryParams.type === "dateRangeFilter" || hasActiveWorkday === false) ? queryParams.rangeStart.toISOString() : null)
                setEndPeriod((queryParams.type === "dateRangeFilter" || hasActiveWorkday === false) ? queryParams.rangeEnd.toISOString() : null)
            }
            doneRefresh(false)
        }
    }, [hasActiveWorkday, selectedIds, doRefresh]);

    return (
        <Card sx={{width: "36rem", marginRight: "2rem", marginBottom: "2rem", height: "15rem", overflowY: "auto"}}>
            <CardContent>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    {title}
                </Typography>
                {hasActiveWorkday !== undefined && selectedIds.length !== 0 && workday !== null &&
                    <InfiniteList
                        resource={"dashboard/top-product"}
                        filter={{
                            workday: workday,
                            startPeriod: startPeriod,
                            endPeriod: endPeriod,
                            posSetIds: [selectedIds]
                        }}
                        actions={false}
                        empty={<Box/>}
                        sort={{ field: 'sum', order: 'DESC' }}
                        disableSyncWithLocation
                        title={" "}
                    >

                        <Datagrid bulkActionButtons={false}>
                            <TextField source="id" sortable={false}/>
                            <TextField source="name" label={translate("dashboard.name")}/>
                            <FunctionField label={translate("dashboard.piece")} sortBy="piece" render={record => `${record.piece} db`}/>
                            <FunctionField label={translate("dashboard.sum")} sortBy="sum" render={record => <NumericFormat displayType="text" value={record.sum} thousandSeparator=" " suffix={" Ft"}/>}/>
                        </Datagrid>
                    </InfiniteList>}
            </CardContent>
        </Card>
    )
}