import {useEffect, useState} from "react";
import {Alert, Box, Button, CircularProgress, Typography} from "@mui/material";
import {useRedirect, useTranslate} from "react-admin";
import dataProvider from "../../../components/dataProvider";

const SimpleBack = ({message}) => {

    const [result, setResult] = useState(null);
    const [secret, setSecret] = useState(null);
    const [response, setResponse] = useState(null);
    const translate = useTranslate()
    const redirect = useRedirect()

    useEffect(() => {
        if (message){
            setResponse(message.status)
            setResult({t: message.transactionId})
        }else{
            const query = window.location.href.split('?')[1];
            const urlParams = new URLSearchParams(query);
            const res = urlParams.get('r');
            const secret = urlParams.get('s');
            if (res) {
                setResult(JSON.parse(atob(res)));
            }
            setSecret(secret)
        }
    }, []);

   const getQuery = async () => {
        try {
            return await dataProvider.get(`license/query`, {orderId: result?.o})
        } catch (e) {
            console.log(e)
            return e
        }
    }
    // back esetek:
    // SUCCESS Sikeres kártya authorizáció
    // FAIL Sikertelen kártya authorizáció, vagy sikertelen 3DS ellenőrzés
    // CANCEL A fizetőoldalon megszakítja a vásárló a fizetést
    // TIMEOUT Időtúllépés a fizető oldalon

    // esetek:
    // INIT Létrejött tranzakció a SimplePay rendszerében
    // TIMEOUT Időtúllépés INIT státuszban
    // CANCELLED A fizetőoldalon megszakított fizetés, vagy a vásárló elnavigál a
    // fizető oldalról, vagy bezárja a böngészőt.
    // NOTAUTHORIZED Sikertelen authorizáció
    // INPAYMENT Fizetés alatt, a „Fizetek” gomb megnyomása után
    // INFRAUD Vizsgálat alatt, csalásszűrés futása idejére
    // AUTHORIZED Sikeres authorizáció a kártyaadatok megadása után
    // FRAUD Csalás gyanú
    // REVERSED Zárolt összeg visszafordítva (kétlépcsős)
    // REFUND Visszatérítés (részleges, vagy teljes) Negatív összegű tranzakció
    // FINISHED Sikeres, befejezett tranzakció

    useEffect(() => {
        if (result?.e === "SUCCESS") {
            const interval = setInterval(async () => {
                const res = await getQuery()
                if (res.data.transactions[0]) {
                    switch (res.data.transactions[0].status) {
                        case ("FINISHED"):
                            clearInterval(interval)
                            setResponse('FINISHED')
                            break;
                        case ("FRAUD"):
                            clearInterval(interval)
                            setResponse('FRAUD')
                            break;
                        case ("TIMEOUT"):
                            clearInterval(interval)
                            setResponse('TIMEOUT')
                            break;
                        case ("CANCELLED"):
                            clearInterval(interval)
                            setResponse('CANCELLED')
                            break;
                        case ("NOTAUTHORIZED"):
                            clearInterval(interval)
                            setResponse('NOTAUTHORIZED')
                            break;
                        case ("REVERSED"):
                            clearInterval(interval)
                            setResponse('REVERSED')
                            break;
                        default:
                            break;
                    }
                }
            }, 5000)
            return () => clearInterval(interval)
        }else if (message) {
            return () => {};
        }else {
            getQuery()
            const interval = setInterval(async () => {
                const res = await getQuery()
                if (res.data.transactions[0].status === "CANCELLED" || res.data.transactions[0].status === "FAIL" || res.data.transactions[0].status === "TIMEOUT" || res.data.transactions[0].status === "REVERSED" || res.data.transactions[0].status === "NOTAUTHORIZED" || res.data.transactions[0].status === "FRAUD" || res.data.transactions[0].status === "FINISHED") {
                    clearInterval(interval)
                }
        }, 5000)
            return () => clearInterval(interval)
        }
    }, [result]);

    return (
        result !== null &&
        <Box sx={{width: '70%', marginX: 'auto'}}>
            {
                result?.e === "SUCCESS" || message ?
                    <Box>
                        {response === 'FINISHED' ?
                            <Box sx={{marginTop: '70px', display: 'flex', flexDirection: 'column', height: '250px', justifyContent: 'space-between'}}>
                                    <Alert
                                        severity="success"
                                        sx={{ mb: 2 , fontSize: '20px'}}
                                    >
                                        {translate("back.success")}
                                    </Alert>

                                <Typography variant='h6'>{translate("back.transactionNum") + result?.t}</Typography>
                                <Button sx={{width: '250px'}} variant="contained" color="primary" onClick={()=>{redirect('/dashboard')}} >
                                    {translate("back.backToHome")}
                                </Button>
                            </Box>
                            : response === 'FRAUD' ?
                            <Box sx={{marginTop: '70px', display: 'flex', flexDirection: 'column', height: '250px', justifyContent: 'space-between'}}>
                                <Alert
                                    severity="error"
                                    sx={{ mb: 2 , fontSize: '20px'}}
                                >
                                    {translate("back.fraud")}
                                </Alert>
                                <Typography variant='h6'>{translate("back.transactionNum") + result?.t}</Typography>
                                <Button sx={{width: '250px'}} variant="contained" color="primary" onClick={()=>{redirect('/license')}} >
                                    {translate("back.backToLicense")}
                                </Button>
                            </Box>
                            : response === 'TIMEOUT' ?
                                <Box sx={{marginTop: '70px', display: 'flex', flexDirection: 'column', height: '250px', justifyContent: 'space-between'}}>
                                    <Alert
                                        severity="error"
                                        sx={{ mb: 2 , fontSize: '20px'}}
                                    >
                                        {translate("back.timeout")}
                                    </Alert>
                                    <Typography variant='h6'>{translate("back.transactionNum") + result?.t}</Typography>
                                    <Button sx={{width: '250px'}} variant="contained" color="primary" onClick={()=>{redirect('/license')}} >
                                        {translate("back.backToLicense")}
                                    </Button>
                                </Box>
                            : response === 'CANCELLED' ?
                                <Box sx={{marginTop: '70px', display: 'flex', flexDirection: 'column', height: '250px', justifyContent: 'space-between'}}>
                                    <Alert
                                        severity="error"
                                        sx={{ mb: 2 , fontSize: '20px'}}
                                    >
                                        {translate("back.cancelled")}
                                    </Alert>
                                    <Typography variant='h6'>{translate("back.transactionNum") + result?.t}</Typography>
                                    <Button sx={{width: '250px'}} variant="contained" color="primary" onClick={()=>{redirect('/license')}} >
                                        {translate("back.backToLicense")}
                                    </Button>
                                </Box>
                            : response === 'NOTAUTHORIZED' ?
                                <Box sx={{marginTop: '70px', display: 'flex', flexDirection: 'column', height: '250px', justifyContent: 'space-between'}}>
                                    <Alert
                                        severity="error"
                                        sx={{ mb: 2 , fontSize: '20px'}}
                                    >
                                        {translate("back.notAuthorized")}
                                    </Alert>
                                    <Typography variant='h6'>{translate("back.transactionNum") + result?.t}</Typography>
                                    <Button sx={{width: '250px'}} variant="contained" color="primary" onClick={()=>{redirect('/license')}} >
                                        {translate("back.backToLicense")}
                                    </Button>
                                </Box>
                            : response === 'REVERSED' ?
                                <Box sx={{marginTop: '70px', display: 'flex', flexDirection: 'column', height: '250px', justifyContent: 'space-between'}}>
                                    <Alert
                                        severity="error"
                                        sx={{ mb: 2 , fontSize: '20px'}}
                                    >
                                        {translate("back.reversed")}
                                    </Alert>
                                    <Typography variant='h6'>{translate("back.transactionNum") + result?.t}</Typography>
                                    <Button sx={{width: '250px'}} variant="contained" color="primary" onClick={()=>{redirect('/license')}} >
                                        {translate("back.backToLicense")}
                                    </Button>
                                </Box>
                            :
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '70px'
                            }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {translate("back.progress")}
                                </Typography>
                                <CircularProgress/>
                            </Box>
                        }
                    </Box>

                : result?.e === "CANCEL" ?

                        <Box sx={{marginTop: '70px', display: 'flex', flexDirection: 'column', height: '250px', justifyContent: 'space-between'}}>
                            <Alert
                                severity="error"
                                sx={{ mb: 2 , fontSize: '20px'}}
                            >
                                {translate("back.cancelled")}
                            </Alert>
                            <Typography variant='h6'>{translate("back.transactionNum") + result?.t}</Typography>
                            <Button sx={{width: '250px'}} variant="contained" color="primary" onClick={()=>{redirect('/license')}} >
                                {translate("back.backToLicense")}
                            </Button>
                        </Box>

                : result?.e === "FAIL" ?

                            <Box sx={{marginTop: '70px', display: 'flex', flexDirection: 'column', height: '300px', justifyContent: 'space-between'}}>
                                <Alert
                                    severity="error"
                                    sx={{ mb: 2 , fontSize: '20px'}}
                                >
                                    {translate("back.fail")}
                                </Alert>
                                <Typography variant='h6'>{translate("back.failText")}</Typography>
                                <Typography variant='h6'>{translate("back.transactionNum") + result?.t}</Typography>
                                <Button sx={{width: '250px'}} variant="contained" color="primary" onClick={()=>{redirect('/license')}} >
                                    {translate("back.backToLicense")}
                                </Button>
                            </Box>

                :

                            <Box sx={{marginTop: '70px', display: 'flex', flexDirection: 'column', height: '250px', justifyContent: 'space-between'}}>
                                <Alert
                                    severity="error"
                                    sx={{ mb: 2 , fontSize: '20px'}}
                                >
                                    {translate("back.timeout")}
                                </Alert>
                                <Typography variant='h6'>{translate("back.transactionNum") + result?.t}</Typography>
                                <Button sx={{width: '250px'}} variant="contained" color="primary" onClick={()=>{redirect('/license')}} >
                                    {translate("back.backToLicense")}
                                </Button>
                            </Box>

            }
        </Box>

    );
}

export default SimpleBack;