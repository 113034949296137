import {
    AutocompleteInput,
    Button,
    CreateButton,
    Datagrid,
    FilterButton,
    List,
    SimpleForm,
    TextField,
    TopToolbar,
    usePermissions,
    useRecordContext,
    useTranslate
} from "react-admin";
import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import AddIcon from '@mui/icons-material/Add';
import {Link} from "react-router-dom";
import SelectCompanyWithBusinessUnitInput from "../../components/SelectCompanyWithBusinessUnitInput";
import {PinkPagination} from "../../components/PinkPagination";
import RedirectToPurchase from "../../components/RedirectToPurchase";


const UserList = () => {
    const { permissions } = usePermissions()
    const hasEdit = getValidPrivilegesIds("USER", "EDIT").some(r => permissions.includes(r)) ? "edit" : false
    const hasInvite = getValidPrivilegesIds("USERINVITE", "INVITE").some(r => permissions.includes(r))
    const [selectedPosSetId, setSelectedPosSetId] = useState(0)
    const [userLimit, setUserLimit] = useState(true)
    const [loading, setLoading] = useState(true)
    const [isShowRedirect, setIsShowRedirect] = useState(false)

    useEffect(() => {
        if (selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) {
            localStorage.setItem("userPosSetId", selectedPosSetId)
            setLoading(false)
        }
    }, [selectedPosSetId])

    const UserTypeField = (props) => {
        const record = useRecordContext();
        const translate = useTranslate()
        if (record.isTechnical) {
            return (
                <div style={{display: "flex"}}>
                    <ManageAccountsIcon/>
                    <Typography> {translate("technicalUsers.technical")}</Typography>
                </div>
            )
        } else {
            return (
                <div style={{display: "flex"}}>
                    <VerifiedUserIcon/>
                    <Typography>{translate("technicalUsers.registered")}</Typography>
                </div>
            )
        }
    }

    const ListActions = ({permissions}) => {
        const hasCreate = getValidPrivilegesIds("USER", "CREATE").some(r => permissions.includes(r))
        return (
            <TopToolbar>
                <FilterButton filters={UserFilters}/>
                {hasInvite && <Link to={`/user/userinvite`}>
                    <Button startIcon={<AddIcon/>} label="technicalUsers.inviteUser"/>
                </Link>}
                {hasCreate  && <CreateButton disabled={!userLimit} label="technicalUsers.create"/>}
            </TopToolbar>
        );
    }

    const UserFilters = [
        <AutocompleteInput name={"userType"} source={"userType"} label={"technicalUsers.userTypeFilter"} choices={[
            {id: "TECHNICAL", name: "technicalUsers.technical"},
            {id: "REGULAR", name: "technicalUsers.registered"},
        ]}/>,
    ]

    const postRowClick = (id, resource, record) =>  {
        return ((record.isTechnical && hasEdit) || (!record.isTechnical && record.hasPrivilegeEditorRole)) ?  "edit" : false
    }
    return (
        <div>
            <SimpleForm toolbar={false} sx={{width: '300px'}}>
                <SelectCompanyWithBusinessUnitInput getResource={"user"} setPosSet={setSelectedPosSetId} setUserLimit={setUserLimit} setIsShowRedirect={setIsShowRedirect}/>
            </SimpleForm>
            { isShowRedirect ?
            <RedirectToPurchase resource={"technicalUsers"}/>
            :
            (selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) && !loading &&
                <List title={"technicalUsers.title"}
                      actions={<ListActions permissions={permissions}/>}
                      filters={UserFilters}
                      pagination={<PinkPagination trigger={selectedPosSetId}/>}
                      empty={false}
                      filterDefaultValues={{posSetId: selectedPosSetId}}
                >
                    <Datagrid rowClick={postRowClick} bulkActionButtons={false}>
                        <TextField source={'id'} label={'technicalUsers.id'}/>
                        <TextField source={'name'} label={'technicalUsers.name'}/>
                        <UserTypeField source={"isTechnical"} label={"technicalUsers.type"}/>
                        <TextField source={'hpPosUserId'} label={"technicalUsers.helloPayPosUser"}/>
                    </Datagrid>
                </List>}

        </div>
    )
}

export default UserList