import { useLocaleState, useRecordContext, useTranslate } from "react-admin";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CustomTable from "./WarehouseOpreationsCustomTable";
import dataProvider from "../../../components/dataProvider";
import { useFormContext } from "react-hook-form";
import dayjs from "dayjs";
import { ThemeProvider } from "@mui/material/styles";
import { muiDatagridTheme } from "../../../components/MuiDatagridTheme";
import { Box, Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import XIcon from '@mui/icons-material/X';
import { WarehouseOperationsUploadDialog } from "./WarehouseOperationsUploadDialog";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";


const OperationsDetails = ({ setEnableSave, setDisposalPrivilege, setAllItemsFilled }) => {
    const { setValue } = useFormContext();
    const [sendData, setSendData] = useState({ dateTime: dayjs() })
    const [warehouses, setWarehouses] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [options, setOptions] = useState([])
    const locale = useLocaleState();
    const record = useRecordContext();
    const translate = useTranslate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [fileUploadOpen, setFileUploadOpen] = useState(false)
    const searchOptions = [
        { id: 1, name: translate("operations.fromSupplier"), type: 'FROM_SUPPLIER' },
        { id: 2, name: translate("operations.toSupplier"), type: 'TO_SUPPLIER' },
        { id: 3, name: translate("operations.move"), type: 'MOVE' },
        { id: 4, name: translate("operations.waste"), type: 'WASTE' },
    ];
    const posSetId = localStorage.getItem("operationsPosSetId")
    const styles = {
        display: "flex",
        width: 800,
        justifyContent: "space-between"
    }
    const formStyle = {
        display: "flex",
        width: 1100,
        justifyContent: "space-between"
    }
    const posSet = localStorage.getItem("operationsPosSetId")
    setValue("posSetId", posSet)
    setValue("dateTime", sendData.dateTime.toISOString())

    useEffect(() => {
        dataProvider.get(`warehouse-operation/operationTypes`, { posSetId: posSetId })
            .then(value => {
                setOptions(value.data.map(it => {
                    it.name = translate("operations." + it.name)
                    return it
                }))
            })
            .catch(reason => {
                setOptions([])
            })
    }, []);

    useEffect(() => {
        dataProvider.get(`warehouse-operation/warehouse`, { posSetId: posSetId, size: 2000 })
            .then(value => {
                setWarehouses(value.data.content)
            })
            .catch(reason => {
                setWarehouses([])
            })
        dataProvider.get(`warehouse-operation/supplier`, { posSetId: posSetId, size: 2000})
            .then(value => {
                setSuppliers(value.data.content)
            })
            .catch(reason => {
                setSuppliers([])
            })
    }, [sendData.type])

    useEffect(() => {
        if (record) {
            const fetchData = async () => {
                try {
                    const warehouseResponse = await dataProvider.get(`warehouse-operation/warehouse`, { posSetId: posSetId, size: 2000 });
                    const supplierResponse = await dataProvider.get(`warehouse-operation/supplier`, { posSetId: posSetId, size: 2000});

                    let source = warehouseResponse.data.content.find(warehouse => warehouse.id === record.source);
                    if (!source) {
                        source = supplierResponse.data.content.find(supplier => supplier.id === record.source);
                    }

                    let target = warehouseResponse.data.content.find(warehouse => warehouse.id === record.target);
                    if (!target) {
                        target = supplierResponse.data.content.find(supplier => supplier.id === record.target);
                    }

                    const dateTime = new Date(record.dateTime);
                    setSendData({
                        ...sendData,
                        source: source,
                        target: target,
                        type: searchOptions.find(option => option.type === record.type),
                        dateTime: dayjs(dateTime).locale(locale[0]),
                        invoice: record.invoice,
                        description: record.description,
                    });
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (record) {
            if (record.type === "WASTE" && options.length === 3) {
                setDisposalPrivilege(false)
            }
        }
    }, [options]);

    useEffect(() => {
        sendData.type && sendData.source && sendData.target && sendData.dateTime || sendData.type?.type === 'WASTE' && sendData.source && sendData.dateTime ? setEnableSave(true) : setEnableSave(false)
    }, [sendData])

    const handleFileUploadDialogClose = () => {
        setFileUploadOpen(false)
    }

    const handleFileUploadDialogOpen = () => {
        setFileUploadOpen(true)
    }

    const downloadFile = (url) => {
        return dataProvider.get(url, { warehouseOperationId: record.id })
            .then(value => {
                const decodedData = new Uint8Array(atob(value.data.file).split('').map(char => char.charCodeAt(0)))
                const blob = new Blob([decodedData], { type: value.data.contentType })

                const fileUrl = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = fileUrl
                a.download = value.data.fileName
                document.body.appendChild(a)

                a.click()

                URL.revokeObjectURL(fileUrl)
                document.body.removeChild(a)
            })
            .catch(reason => console.log(reason))
    }

    const downloadPdf = () => {
        downloadFile(`warehouse-operation/pdf`)
        handleClose()
    }

    const downloadXlsx = () => {
        downloadFile(`warehouse-operation/xlsx`)
        handleClose()
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {record ?
                sendData.type &&
                <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', marginBottom: 2.5}}>
                    <Autocomplete
                        sx={{width: 300, marginBottom: 2.5}}
                        value={sendData.type}
                        getOptionLabel={option => option.name}
                        renderInput={(params) => <TextField {...params} label={translate("operations.type")}/>}
                        options={options}
                        onChange={(event, newValue) => {
                            setSendData({...sendData, type: newValue, source: null, target: null})
                            setValue("type", newValue.type)
                        }}
                        disableClearable

                    />
                    <img src={shorticLogo}
                         style={{
                             width: "150px",
                             height: '35px'
                         }}/>
                </Box>
                :
                <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', marginBottom: 2.5}}>
                    <Autocomplete
                        sx={{width: 300,}}
                        value={sendData.type}
                        getOptionLabel={option => option.name || ""}
                        renderInput={(params) => <TextField {...params} label={translate("operations.type")}/>}
                        options={options}
                        onChange={(event, newValue) => {
                            if (newValue !== null) {
                                setSendData({...sendData, type: newValue, source: null, target: null})
                                setValue("type", newValue.type)
                            } else {
                                setSendData({...sendData, type: null, source: null, target: null})
                                setValue("type", null)
                            }
                        }}
                    />
                    <img src={shorticLogo}
                         style={{
                             width: "150px",
                             height: '35px'
                         }}/>
                </Box>
            }
            {sendData.type &&
                <>
                    {sendData.type.type === "FROM_SUPPLIER" ?
                        <>
                            <div style={styles}>
                                <Box>
                                    <div>{translate('operations.source')}</div>
                                    <Autocomplete
                                        sx={{width: 300, marginBottom: 2.5}}
                                        value={sendData.source}
                                        getOptionLabel={option => option.name || ""}
                                        renderInput={(params) => <TextField {...params}
                                                                            label={translate("operations.switchSupplier")}/>}
                                        options={suppliers}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null) {
                                                setSendData({...sendData, source: newValue})
                                                setValue("source", newValue.id)
                                            } else {
                                                setSendData({...sendData, source: null})
                                                setValue("source", null)
                                            }
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <div>{translate('operations.destination')}</div>
                                    <Autocomplete
                                        sx={{width: 300, marginBottom: 2.5}}
                                        value={sendData.target}
                                        getOptionLabel={option => option.name || ""}
                                        renderInput={(params) => <TextField {...params}
                                                                            label={translate("operations.switchWarehouse")}/>}
                                        options={warehouses}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null) {
                                                setSendData({...sendData, target: newValue})
                                                setValue("target", newValue.id)
                                            } else {
                                                setSendData({...sendData, target: null})
                                                setValue("target", null)
                                            }
                                        }}

                                    />
                                </Box>
                            </div>
                            <div style={formStyle}>
                                <ThemeProvider theme={muiDatagridTheme}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale[0]}>
                                        <DateTimePicker
                                            sx={{width: 218.4, marginBottom: 2.5}}
                                            value={sendData.dateTime}
                                            label={translate("operations.date")}
                                            name={"dateTime"}
                                            renderInput={(params) => <TextField {...params} />}
                                            onChange={(newValue) => {
                                                setSendData({...sendData, dateTime: newValue})
                                                setValue("dateTime", newValue.toISOString())
                                            }}
                                        />
                                    </LocalizationProvider>
                                </ThemeProvider>

                                <TextField label={translate("operations.invoice")} value={sendData.invoice}
                                           onChange={
                                               (event) => {
                                                   setSendData({...sendData, invoice: event.target.value})
                                                   setValue("invoice", event.target.value)
                                               }
                                           }/>

                                <TextField label={translate("operations.comment")} value={sendData.description}
                                           onChange={
                                               (event) => {
                                                   setSendData({...sendData, description: event.target.value})
                                                   setValue("description", event.target.value)
                                               }
                                           }/>

                                <TextField label={translate("operations.discountPercent")}
                                           value={sendData.discount}
                                           onChange={
                                               (event) => {
                                                   const discount = event.target.value.replace(/[^0-9]/g, '')
                                                   if (discount > 100) {
                                                       return
                                                   }
                                                   setSendData({...sendData, discount: discount})
                                               }
                                           }/>

                            </div>
                        </>
                        : sendData.type.type === "MOVE" ?
                            <>
                                <div style={styles}>
                                    <Box>
                                        <div>{translate('operations.source')}</div>
                                        <Autocomplete
                                            sx={{width: 300, marginBottom: 2.5}}
                                            value={sendData.source || ""}
                                            getOptionLabel={option => option.name || ""}
                                            renderInput={(params) => <TextField {...params}
                                                                                label={translate("operations.switchWarehouse")}/>}
                                            options={warehouses.filter(item => item.id !== sendData?.target?.id)}
                                            onChange={(event, newValue) => {
                                                if (newValue !== null) {
                                                    setSendData({...sendData, source: newValue})
                                                    setValue("source", newValue.id)
                                                } else {
                                                    setSendData({...sendData, source: null})
                                                    setValue("source", null)
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <div>{translate('operations.destination')}</div>
                                        <Autocomplete
                                            sx={{width: 300, marginBottom: 2.5}}
                                            value={sendData.target}
                                            getOptionLabel={option => option.name || ""}
                                            renderInput={(params) => <TextField {...params}
                                                                                label={translate("operations.switchWarehouse")}/>}
                                            options={warehouses.filter(item => item.id !== sendData?.source?.id)}
                                            onChange={(event, newValue) => {
                                                if (newValue !== null) {
                                                    setSendData({...sendData, target: newValue})
                                                    setValue("target", newValue.id)
                                                } else {
                                                    setSendData({...sendData, target: null})
                                                    setValue("target", null)
                                                }
                                            }}
                                        />
                                    </Box>
                                </div>
                                <div style={styles}>
                                    <ThemeProvider theme={muiDatagridTheme}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}
                                                              adapterLocale={locale[0]}>
                                            <DateTimePicker
                                                sx={{width: 218.4, marginBottom: 2.5}}
                                                value={sendData.dateTime}
                                                label={translate("operations.date")}
                                                name={"dateTime"}
                                                renderInput={(params) => <TextField {...params} />}
                                                onChange={(newValue) => {
                                                    setSendData({...sendData, dateTime: newValue})
                                                    setValue("dateTime", newValue.toISOString())
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>

                                    <TextField label={translate("operations.comment")}
                                               value={sendData.description}
                                               onChange={
                                                   (event) => {
                                                       setSendData({
                                                           ...sendData,
                                                           description: event.target.value
                                                       })
                                                       setValue("description", event.target.value)
                                                   }
                                               }/>

                                    <TextField label={translate("operations.discountPercent")}
                                               value={sendData.discount}
                                               onChange={
                                                   (event) => {
                                                       const discount = event.target.value.replace(/[^0-9]/g, '')
                                                       if (discount > 100) {
                                                           return
                                                       }
                                                       setSendData({...sendData, discount: discount})
                                                   }
                                               }/>
                                </div>
                            </>
                            : sendData.type.type === "TO_SUPPLIER" ?
                                <>
                                    <div style={styles}>
                                        <Box>
                                            <div>{translate('operations.source')}</div>
                                            <Autocomplete
                                                sx={{width: 300, marginBottom: 2.5}}
                                                value={sendData.source}
                                                getOptionLabel={option => option.name || ""}
                                                renderInput={(params) => <TextField {...params}
                                                                                    label={translate("operations.switchWarehouse")}/>}
                                                options={warehouses}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) {
                                                        setSendData({...sendData, source: newValue})
                                                        setValue("source", newValue.id)
                                                    } else {
                                                        setSendData({...sendData, source: null})
                                                        setValue("source", null)
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <div>{translate('operations.destination')}</div>
                                            <Autocomplete
                                                sx={{width: 300, marginBottom: 2.5}}
                                                value={sendData.target}
                                                getOptionLabel={option => option.name || ""}
                                                renderInput={(params) => <TextField {...params}
                                                                                    label={translate("operations.switchSupplier")}/>}
                                                options={suppliers}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) {
                                                        setSendData({...sendData, target: newValue})
                                                        setValue("target", newValue.id)
                                                    } else {
                                                        setSendData({...sendData, target: null})
                                                        setValue("target", null)
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </div>
                                    <div style={formStyle}>
                                        <ThemeProvider theme={muiDatagridTheme}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}
                                                                  adapterLocale={locale[0]}>
                                                <DateTimePicker
                                                    sx={{width: 218.4, marginBottom: 2.5}}
                                                    value={sendData.dateTime}
                                                    label={translate("operations.date")}
                                                    name={"dateTime"}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    onChange={(newValue) => {
                                                        setSendData({...sendData, dateTime: newValue})
                                                        setValue("dateTime", newValue.toISOString())
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </ThemeProvider>

                                        <TextField label={translate("operations.invoice")}
                                                   value={sendData.invoice}
                                                   onChange={
                                                       (event) => {
                                                           setSendData({
                                                               ...sendData,
                                                               invoice: event.target.value
                                                           })
                                                           setValue("invoice", event.target.value)
                                                       }
                                                   }/>

                                        <TextField label={translate("operations.comment")}
                                                   value={sendData.description}
                                                   onChange={
                                                       (event) => {
                                                           setSendData({
                                                               ...sendData,
                                                               description: event.target.value
                                                           })
                                                           setValue("description", event.target.value)
                                                       }
                                                   }/>

                                        <TextField label={translate("operations.discountPercent")}
                                                   value={sendData.discount} onChange={
                                            (event) => {
                                                const discount = event.target.value.replace(/[^0-9]/g, '')
                                                if (discount > 100) {
                                                    return
                                                }
                                                setSendData({...sendData, discount: discount})
                                            }
                                        }/>
                                    </div>
                                </>
                                : sendData.type.type === "WASTE" ?
                                    <>
                                        <Box>
                                            <div>{translate('operations.disposal')}</div>
                                            <Autocomplete
                                                sx={{width: 300, marginBottom: 2.5}}
                                                value={sendData.source}
                                                getOptionLabel={option => option.name || ""}
                                                renderInput={(params) => <TextField {...params}
                                                                                    label={translate("operations.switchWarehouse")}/>}
                                                options={warehouses}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) {
                                                        setSendData({...sendData, source: newValue})
                                                        setValue("source", newValue.id)
                                                    } else {
                                                        setSendData({...sendData, source: null})
                                                        setValue("source", null)
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <div style={styles}>
                                            <ThemeProvider theme={muiDatagridTheme}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}
                                                                      adapterLocale={locale[0]}>
                                                    <DateTimePicker
                                                        sx={{width: 218.4, marginBottom: 2.5}}
                                                        value={sendData.dateTime}
                                                        label={translate("operations.date")}
                                                        name={"dateTime"}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        onChange={(newValue) => {
                                                            setSendData({...sendData, dateTime: newValue})
                                                            setValue("dateTime", newValue.toISOString())
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </ThemeProvider>

                                            <TextField label={translate("operations.comment")}
                                                       value={sendData.description} onChange={
                                                (event) => {
                                                    setSendData({...sendData, description: event.target.value})
                                                    setValue("description", event.target.value)
                                                }
                                            }/>

                                            <TextField label={translate("operations.discountPercent")}
                                                       value={sendData.discount} onChange={
                                                (event) => {
                                                    const discount = event.target.value.replace(/[^0-9]/g, '')
                                                    if (discount > 100) {
                                                        return
                                                    }
                                                    setSendData({...sendData, discount: discount})
                                                }
                                            }/>
                                        </div>
                                    </>
                                    :
                                    ""
                    }
                    {record?.items.length > 0 ? <div>
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            Exportálás
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem sx={{'color': '#ff71bc'}} onClick={() => downloadPdf()}><Button
                                startIcon={<PictureAsPdfIcon/>}>PDF</Button></MenuItem>
                            <MenuItem sx={{'color': '#ff71bc'}} onClick={() => downloadXlsx()}><Button
                                startIcon={<XIcon/>}>Excel</Button></MenuItem>
                        </Menu>
                    </div> : <Button onClick={handleFileUploadDialogOpen}>Importálás</Button>}
                    <WarehouseOperationsUploadDialog open={fileUploadOpen} onClose={handleFileUploadDialogClose}
                                                     selectedPosSetId={posSetId}/>
                    <CustomTable setValue={setValue} record={record} detailedData={sendData}
                                 setAllItemsFilled={setAllItemsFilled}/>
                </>
            }
        </>
    )
}

export default OperationsDetails