import {Card, CardContent, CardActions, Button, Typography} from "@mui/material";
import dataProvider from "../../../components/dataProvider";
import {useNotify} from "react-admin";

const SaveBankCard = ({card}) => {
    const notify = useNotify()

    const cardCancel = (cardId) => {
        dataProvider.postQuery(`license/card-cancel`, {cardId: cardId})
            .then(({data}) => {
                if(data.status === "DISABLED") {
                    notify("Kártya sikeresen törölve", {type: "success"})
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }else{
                    notify("Kártya törlése sikertelen", {type: "error"})
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                }
            })
    }
    return (
        <Card sx={{ width: 275, margin: 2 }}>
            <CardContent>
                <Typography sx={{ fontSize: 16, mb: 1.5 }} >
                    Utoljára használt kártya
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {card.cardMask}
                </Typography>
                <Typography  color="text.secondary">
                    Lejárati dátum: {card.validUntil.split("T")[0]}
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={()=>{cardCancel(card.cardId)}} size="small">Kártya törlése</Button>
            </CardActions>
        </Card>
    )
}

export default SaveBankCard
