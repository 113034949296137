import {AutocompleteInput, regex, required, SimpleForm, TabbedForm, TextInput, useTranslate} from "react-admin"
import React, {useEffect, useState} from "react";
import {countyCodes} from "../../components/CountyCodes";
import {TaxNumberInput} from "../../components/TaxNumberInput";
import {CompanyRegistrationNumberInput} from "../../components/CompanyRegistationNumberInput";
import {PinkToolbar} from "../../components/PinkToolbar";
import dataProvider from "../../components/dataProvider";

export const CompanyDetails = () => {

    const [countries, setCountries] = useState()
    const translate = useTranslate()

    const checkTaxNumber = (taxNumber) => {
        const pattern = /^(\d{7})(\d)-([1-5])-(0[2-9]|[13][0-9]|2[02-9]|4[0-4]|51)$/;
        const matches = taxNumber.match(pattern);
        if (!matches) return false;

        const mul = [9, 7, 3, 1, 9, 7, 3];
        const base = matches[1].split("").map(Number);
        const check = parseInt(matches[2]);
        const sum = base.reduce((acc, digit, i) => acc + digit * mul[i], 0);
        const last = (10 - (sum % 10)) % 10;

        return last === check;
    }
    
    const formValidate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = translate("company.companyNameRequired");
        }
        if (!values.taxNumber && !values.euTaxNumber) {
            errors.taxNumber = translate("company.mustHaveTaxNumber");
            errors.euTaxNumber = translate("company.mustHaveTaxNumber");
        }
        if (values.taxNumber && !checkTaxNumber(values.taxNumber)) {
            errors.taxNumber = translate("company.wrongTaxNumberFormat");
        }
        if (!values.zipCode) {
            errors.zipCode = translate("company.zipCodeRequired");
        }
        if (!values.state) {
            errors.state = translate("company.stateRequired");
        }
        if (!values.country) {
            errors.country = translate("company.countryRequired");
        }
        if (!values.settlement) {
            errors.settlement = translate("company.settlementRequired");
        }
        if (!values.publicDomainName) {
            errors.publicDomainName = translate("company.publicDomainNameRequired");
        }
        if (!values.publicDomainType) {
            errors.publicDomainType = translate("company.publicDomainTypeRequired");
        }
        if (!values.contactName) {
            errors.contactName = translate("company.contactNameRequired");
        }
        if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.contactEmail))) {
            errors.contactEmail = translate("company.wrongEmailFormat");
        }
        if (!values.contactPhone) {
            errors.contactPhone = translate("company.contactPhoneRequired");
        }
        if (!values.number) {
            errors.number = translate("company.numberRequired");
        }


        return errors;

    }

    useEffect(() => {
        dataProvider.get("company/countries").then(result => {
           setCountries(result.data.map(item => {
               return {
                   id: item.id,
                   label:  translate("country." + item.code),
                   code: item.code
               }
           }))
        })
    }, []);

    return (
        <TabbedForm toolbar={<PinkToolbar deleteEnabled={false}/>} mode="onBlur" reValidateMode="onBlur" validate={formValidate}>
            <TabbedForm.Tab label="company.general">
                <TextInput source="name" inputProps={{maxLength: 255}} label={"company.name"} validate={required()}/>
                <TaxNumberInput source="taxNumber" label={'company.taxNumber'}/>
                <TaxNumberInput source="groupTaxNumber" label={'company.groupTaxNumber'}/>
                <TextInput source="euTaxNumber" label={'company.euTaxNumber'} inputProps={{maxLength: 15}}/>
                <CompanyRegistrationNumberInput source="companyRegistrationNumber"
                                                label={'company.companyRegistrationNumber'}/>
            </TabbedForm.Tab>
            <TabbedForm.Tab label={"company.address"}>
                <TextInput source="zipCode" label={"company.zipCode"} type={"number"} inputProps={{maxLength: 10}}
                           validate={[regex(/^[1-9]\d{3,9}$/, "company.wrongZipCodeFormat"), required()]}/>
                <TextInput source="state" label={"company.state"} inputProps={{maxLength: 50}} validate={required()}/>
                <AutocompleteInput validate={required()} style={{marginRight: "8px"}} label={'company.country'}
                                   optionValue={"code"}
                                   optionText={((record) => {
                                       return record.label
                                   })} sx={{width: '230px'}}
                                   source={"country"} choices={countries} defaultValue={"hu"}/>
                <TextInput source="settlement" label={"company.settlement"} inputProps={{maxLength: 50}}
                           validate={required()}/>
                <TextInput source="publicDomainName" label={"company.publicDomainName"} inputProps={{maxLength: 255}}
                           validate={required()}/>
                <TextInput source="publicDomainType" label={"company.publicDomainType"} inputProps={{maxLength: 50}}
                           validate={required()}/>
                <TextInput source="number" label={"company.number"} inputProps={{maxLength: 10}} validate={required()}/>
            </TabbedForm.Tab>
            <TabbedForm.Tab label={"company.contacts"}>
                <TextInput source="contactName" validate={required()} inputProps={{maxLength: 255}}
                           label={'company.contactName'}/>
                <TextInput source="contactEmail" inputProps={{maxLength: 255}}
                           label={'company.contactEmail'}/>
                <div style={{display: "flex"}}>
                    <AutocompleteInput validate={required()} style={{marginRight: "8px"}} label={'company.phonePrefix'}
                                       source={"prefix"} choices={countyCodes} optionValue={"dial_code"}
                                       optionText={((record) => {
                                           return `${record.dial_code} (${record.code})`
                                       })} defaultValue={"+36"}/>
                    <TextInput source="contactPhone" validate={required()} inputProps={{maxLength: 20}}
                               label={'company.contactPhone'}/>
                </div>
            </TabbedForm.Tab>
        </TabbedForm>
    )
}