import {
    Card,
    CardContent,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";
import {NumericFormat} from "react-number-format";

export const Top10BarCard = ({title, value, unit}) => {

    return(
        <Card sx={{ width: "36rem", marginRight: "2rem", marginBottom: "2rem"}}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {title}
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                        <TableBody>
                            { value!== undefined ? value.map
                                ((element, index) =>
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {index+1}
                                        </TableCell>
                                        <TableCell align="left">{element.name}</TableCell>
                                        <TableCell align="right"><NumericFormat displayType="text" value={element.sum} thousandSeparator=" " suffix={" "+unit}/></TableCell>
                                    </TableRow>
                                )
                                : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}