import {AutocompleteArrayInput, AutocompleteInput, ReferenceArrayInput, ReferenceInput} from "react-admin";
import React from "react";

export const AutocompleteSimpleInput = ({
                                            resettable,
                                            disabled,
                                            source,
                                            reference,
                                            label,
                                            initialValue,
                                            sortField = undefined,
                                            optionText = undefined,
                                            multiple = false,
                                            size = 50,
                                            filter,
                                            validate,
                                            ...props
                                        }) => {
    const outsideAttrs = {
        sort: (sortField !== undefined ? {field: sortField, order: 'ASC'} : undefined),
        filterToQuery: v => ({data: v}),
        filter: {size: size, ...filter},
        source: source,
        label: label,
        reference: reference,
        disabled,
        ...props
    }

    const insideAttrs = {
        translateChoice: false,
        optionText: optionText,
        disabled,
        initialValue,
        resettable,
        validate,
        ...props
    }

    if (multiple) {
        return (
            <ReferenceArrayInput {...outsideAttrs}>
                <AutocompleteArrayInput fullWidth={true} label={label} {...insideAttrs} />
            </ReferenceArrayInput>
        )
    } else {
        return (
            <ReferenceInput {...outsideAttrs}>
                <AutocompleteInput fullWidth={true} label={label} {...insideAttrs} />
            </ReferenceInput>
        )
    }
}