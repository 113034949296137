import { defaultTheme } from 'react-admin';
import {renderTimeViewClock} from "@mui/x-date-pickers/timeViewRenderers";
import {merge} from "lodash";
import createPalette from "@mui/material/styles/createPalette";

const palette = createPalette(
    merge({}, defaultTheme.palette, {
            primary: {
                main: '#ff71bc',
            },
            secondary: {
                light: '#5f5fc4',
                main: '#ff71bc',
                dark: '#ff71bc',
                green: '#38BA9B',
                red: '#EF4E69',
                contrastText: '#fff',
            },
            action: {
                active: 'rgba(0,0,0,0.7)',
                disabled: 'rgba(0,0,0,0.26) !important',
            },
        }
    ));

export const theme = {
    ...defaultTheme,
    palette,
    sidebar: {
        width: 250, // The default value is 240
        closedWidth: 60, // The default value is 55
    },
    components: {
        ...defaultTheme.components,
        RaDatagrid: {
            styleOverrides: {
                root: {
                    "& .RaDatagrid-headerCell": {
                        backgroundColor: palette.primary.main,
                        borderBottom: '2px solid',
                        borderBottomColor: palette.primary.main,
                        borderRight: '2px solid',
                        borderRightColor: palette.secondary.contrastText,
                        color: palette.secondary.contrastText
                    },
                }
            }
        },

        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    "& .RaDatagrid-headerCell": {
                        backgroundColor: palette.primary.main,
                        borderBottom: '2px solid',
                        borderBottomColor: palette.primary.main,
                        borderRight: '2px solid',
                        borderRightColor: palette.secondary.contrastText,
                        color: palette.secondary.contrastText
                    },
                }
            }
        },


        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    // invisible border when not active, to avoid position flashs
                    borderLeft: '10px solid transparent',
                    '&.RaMenuItemLink-active': {
                        borderLeft: '10px solid',
                        borderLeftColor: palette.primary.main,
                        //color: palette.primary.main,
                    },
                },
            },
        },

        MuiDesktopTimePicker: {
            defaultProps: {
                viewRenderers: {
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock
                }
            }
        },
        MuiDesktopDateTimePicker: {
            defaultProps: {
                viewRenderers: {
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock}
            }
        }
    }
};