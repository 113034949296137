import React, {useEffect, useState} from 'react';
import {DateField, FunctionField, List, SimpleForm, TextField, useTranslate} from "react-admin";
import ConfigurableDatagrid from "../../components/ConfigurableDatagrid";
import {EmptyPage} from "../../components/EmtpyPage";
import SelectCompanyWithBusinessUnitInput from "../../components/SelectCompanyWithBusinessUnitInput";
import {PinkPagination} from "../../components/PinkPagination";

export const RMSErrorMessagesList = () => {
    const translate = useTranslate()
    const [selectedPosSetId, setSelectedPosSetId] = useState(0)

    useEffect(() => {
        if (selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) {
            localStorage.setItem("ntakPosSetId", selectedPosSetId)
        }
    }, [selectedPosSetId])


    return (
        <div>
            <SimpleForm toolbar={false} sx={{width: '300px'}}>
                <SelectCompanyWithBusinessUnitInput getResource={"ntak"} setPosSet={setSelectedPosSetId} companySelectDisabled={true}/>
            </SimpleForm>
        {(selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) &&
        <List title={"rMSErrorMessagesList.title"} actions={false} resource="ntak/errorMessages"
              pagination={<PinkPagination trigger={selectedPosSetId}/>}
              empty={<EmptyPage text={"rMSErrorMessagesList.empty"} createEnabled={false} />}
              filterDefaultValues={{posSetId: selectedPosSetId}}
        >
            <ConfigurableDatagrid rowClick={false} bulkActionButtons={false} >
                <TextField source="id" label={'rMSErrorMessagesList.id'} sortable={false}/>
                <TextField source="processId" label={'rMSErrorMessagesList.processId'} sortable={false}/>
                <FunctionField label="rMSErrorMessagesList.status" render={record => {return translate("rMSErrorMessagesList."+record.status.toString())}} />
                <FunctionField label="rMSErrorMessagesList.trxType" render={record => {return translate("rMSErrorMessagesList."+record.trxType.toString())}} />
                <TextField source="trxId" label={'rMSErrorMessagesList.trxId'} sortable={false}/>
                <TextField source="stornoTrxId" label={'rMSErrorMessagesList.stornoTrxId'} sortable={false}/>
                <TextField source="workdayId" label={'rMSErrorMessagesList.workdayId'} sortable={false}/>
                <TextField source="rmsId" label={'rMSErrorMessagesList.rmsId'} sortable={false}/>
                <DateField source="closedDay" label={'rMSErrorMessagesList.closedDay'} locales="hu-HU" sortable={false}/>
                <DateField source="sendingTime" label={'rMSErrorMessagesList.sendingTime'} showTime locales="hu-HU" sortable={false}/>
                <DateField source="transactionTimestamp" label={'rMSErrorMessagesList.transactionTimestamp'} showTime locales="hu-HU" sortable={false}/>
                <DateField source="workdayOpenedAt" label={'rMSErrorMessagesList.workdayOpenedAt'} showTime locales="hu-HU" sortable={false}/>
                <DateField source="periodicClosedAt" label={'rMSErrorMessagesList.periodicClosedAt'} showTime locales="hu-HU" sortable={false}/>
            </ConfigurableDatagrid>
        </List>}
        </div>
    )
}
