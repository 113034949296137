import dataProvider from "../../components/dataProvider";
import React, {useEffect, useState} from "react";
import {Box, Card, Checkbox, Typography} from "@mui/material";
import {
    List,
    useLocaleState,
    useTranslate,
    TextField,
    DateField,
    TopToolbar,
    SelectColumnsButton, ExportButton, DatagridConfigurable
} from "react-admin";
import {uuidv4} from "../../tools/generate";
import {ThemeProvider} from "@mui/material/styles";
import {muiDatagridTheme} from "../../components/MuiDatagridTheme";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MuiTextField from "@mui/material/TextField";
import * as XLSX from "xlsx"

const FinanceList = () => {
    const [companies, setCompanies] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedDate, setSelectedDate] = useState({
        start: dayjs().set('hour', 0).set('minute', 0),
        end:  dayjs().set('hour', 23).set('minute', 59)
    })
    const translate = useTranslate()
    const locale = useLocaleState()
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const compIds = selectedCompanies.map(c => c.id)

    useEffect(() => {
        dataProvider.get('finance/companies')
            .then(response => {
                response.data.list.unshift({id: uuidv4(), name: translate(`finance.all`), all: true})
                setCompanies(response.data.list)
            })
            .catch(reason => {
                console.log(reason)
                setCompanies([])
            })
    }, [])
    const ListActions = () => (
        <TopToolbar>
            <SelectColumnsButton />
            <ExportButton/>
        </TopToolbar>
    );

    const Empty = () => {
        return(
            <Card sx={{
                width: '100%',
                margin: 'auto',
                color: 'black',
                padding: '20px'
            }}>
                <Typography> {translate('finance.listTitle')} </Typography>
            </Card>
        )
    }

    const autoInputChange = (event, value) => {
        if (value.some(v => v.all)) {
            if (selectedCompanies.length === companies.length - 1) {
                setSelectedCompanies([]);
            } else {
                setSelectedCompanies(companies.filter(w => !w.all));
            }
        } else {
            setSelectedCompanies(value);
        }
    }

    const exporter = finances => {
        const headers = [
            translate("finance.name"),
            translate("finance.licenseName"),
            translate("finance.supportName"),
            translate("finance.licenseCount"),
            translate("finance.totalAmount"),
            translate("finance.purchaseDate"),
            translate("finance.expirationDate"),
            translate("finance.stornoDate"),
            translate("finance.paymentStatus"),
            translate("finance.invoiceNumber"),
            translate("finance.trxId"),
            translate("finance.address"),
            translate("finance.email"),
            translate("finance.taxNumber"),
            translate("finance.groupTaxNumber")
        ];
        let exportedFinances = []
        finances.forEach(item => {
            let arr = [
                item.name,
                item.licenseName,
                item.supportName,
                item.licenseCount,
                item.total,
                item.purchaseTimestamp ? dayjs(item.purchaseTimestamp).format('YYYY.MM.DD HH:mm'): null,
                item.validUntil? dayjs(item.validUntil).format('YYYY.MM.DD HH:mm'): null,
                item.stornoTimestamp ? dayjs(item.stornoTimestamp).format('YYYY.MM.DD HH:mm'): null,
                item.status,
                item.invoiceNumber,
                item.trxId,
                item.address,
                item.email,
                item.taxNumber,
                item.groupTaxNumber
            ]
            exportedFinances.push(arr)
        })
        exportedFinances.unshift(headers)
        let wscols = [
            {wch:15}, {wch:15},
            {wch:15}, {wch:15},
            {wch:15}, {wch:15},
            {wch:15}, {wch:15},
            {wch:15}, {wch:15},
            {wch:15}, {wch:15},
            {wch:15}, {wch:15},
            {wch:15}
        ];
        const ws = XLSX.utils.aoa_to_sheet(exportedFinances);
        ws['!cols'] = wscols;
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "export");
        XLSX.writeFile(wb, "export.xlsx");
    }

    return (
        <>
            <Box sx={{display: 'flex', maxWidth: '1000px', justifyContent: 'space-around', margin: '15px'}}>
                <Autocomplete
                    multiple
                    id="checkboxes-finance"
                    options={companies}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={selectedCompanies}
                    limitTags={6}
                    onChange={autoInputChange}
                    renderOption={(props, option, { selected }) => {
                        return (<li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option.all ? selectedCompanies.length === companies.length - 1 : selectedCompanies.includes(option)}
                            />
                            {option.name}
                        </li>)
                    }}
                    sx={{width: 500}}
                    renderInput={(params) =>{
                        return <MuiTextField {...params} label={translate('finance.companies')} variant={"standard"} autoComplete="off" />
                    }}
                />
                <ThemeProvider theme={muiDatagridTheme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale[0]}>
                        <DateTimePicker
                            sx={{width: '200px'}}
                            value={selectedDate.start}
                            label={translate(`finance.dateStart`)}
                            renderInput={(params) => <TextField {...params} />}
                            maxDate={dayjs()}
                            onChange={(newValue) => {
                                setSelectedDate({...selectedDate, start: newValue})
                            }}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale[0]}>
                        <DateTimePicker
                            sx={{width: '200px'}}
                            value={selectedDate.end}
                            label={translate(`finance.dateEnd`)}
                            renderInput={(params) => <TextField {...params} />}
                            minDateTime={selectedDate.start}
                            maxDate={dayjs()}
                            onChange={(newValue) => {
                                setSelectedDate({...selectedDate, end: newValue})
                            }}
                        />
                    </LocalizationProvider>
                </ThemeProvider>
            </Box>
            { selectedCompanies.length > 0 &&
                <List
                    actions={<ListActions/>}
                    empty={<Empty/>}
                    exporter={exporter}
                    filter={{companyIds: compIds, start: selectedDate.start.format(), end: selectedDate.end.format()}}
                >
                    <DatagridConfigurable size={"small"} omit={['id', 'paymentStatus', 'invoiceNumber', 'trxId', 'address', 'email', 'taxNumber', 'groupTaxNumber']} rowClick={false} bulkActionButtons={false} >
                        <TextField name={'id'} source="id" label={"finance.id"} sortable={false} />
                        <TextField name={'name'} source="name" label={"finance.name"} sortable={false}/>
                        <TextField name={'licenseName'} source="licenseName" label={"finance.licenseName"} sortable={false}/>
                        <TextField name={'supportName'} source="supportName" label={"finance.supportName"} sortable={false}/>
                        <TextField name={'licenseCount'} source="licenseCount" label={"finance.licenseCount"} sortable={false}/>
                        <TextField name={'totalAmount'} source="total" label={"finance.totalAmount"}/>
                        <DateField name={'purchaseDate'} source="purchaseTimestamp" label={"finance.purchaseDate"}/>
                        <DateField name={'expirationDate'} source="validUntil" label={"finance.expirationDate"}/>
                        <DateField name={'stornoDate'} source="stornoTimestamp" label={"finance.stornoDate"}/>
                        <TextField name={'paymentStatus'} source="status" label={"finance.paymentStatus"}/>
                        <TextField name={'invoiceNumber'} source="invoiceNumber" label={"finance.invoiceNumber"} sortable={false}/>
                        <TextField name={'trxId'} source="trxId" label={"finance.trxId"} sortable={false}/>
                        <TextField name={'address'} source="address" label={"finance.address"} sortable={false}/>
                        <TextField name={'email'} source="email" label={"finance.email"} sortable={false}/>
                        <TextField name={'taxNumber'} source="taxNumber" label={"finance.taxNumber"} sortable={false}/>
                        <TextField name={'groupTaxNumber'} source="groupTaxNumber" label={"finance.groupTaxNumber"} sortable={false}/>
                        <TextField name={'voucherType'} source="voucherType" label={"finance.voucherType"}/>
                        <TextField name={'voucherCode'} source="voucherCode" label={"finance.voucherCode"}/>
                        <TextField name={'discountPercent'} source="discountPercent" label={"finance.discountPercent"}/>
                        <TextField name={'fixPrice'} source="fixPrice" label={"finance.fixPrice"}/>
                    </DatagridConfigurable>
                </List>
            }
        </>
    )
}

export default FinanceList;