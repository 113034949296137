import englishMessages from 'ra-language-english';
import {mergeDeep} from "../tools/mergeDeep";

const missingTranslations = {
    ra: {
        action: {
            confirm: 'Confirm',
            search: 'Search',
            next: 'Next',
            finish: 'Finish',
            edit: 'Edit',
            save: 'Save',
            add: 'Add',
            modify: "Modify"
        },
        navigation: {
            page_rows_per_page: 'Rows per page'
        },
        boolean: {
            null: ' '
        },
        configurable: {
            customize: "Customize"
        }
    }
};
const data = {
    common: {
        columns: "Columns",
        columnSelected: "column selected",
        logout: "Logout",
        editProfile: "Edit profile",
        search: "Search",
        cancel: "Cancel",
        yes: "Yes",
        no: "No"
    },
    menu: {
        administration: "Administration",
        priceList: "Price Lists",
        storage: "Storage",
        corporateAdmin: "Corporate Admin"
    },
    products : {
        title: "Products",
        id: "ID",
        name: "Name",
        create: "Add new product",
        mainCategory: "NTAK Main Category",
        subCategory: "NTAK Subcategory",
        vatGroup: "VAT group",
        price: "Price",
        productUnits: "Product units",
        quantity: "Quantity",
        unit: "Unit",
        qtyUnit: "Packaging",
        defaultPrice: "Default gross price",
        greaterThanZero: "Value must be greater than 0",
        empty: "No products to add",
        tags: "Tags",
        filterTags: "Filter tags",
        dialogTitle: "Confirm delete",
        dialogContextText: "Are you sure want to delete this tag? It will remove from all the products",
        yes: "Yes",
        no: "No",
        isTakeawayAllowed: "Takeaway allowed",
        takeawayVatGroup: "Takeaway VAT group",
        fivePercentText: "5% VAT on food and locally produced non-alcoholic beverages in canteens",
        wrongQuantityFormat: "Wrong quantity. The right format: greater than 0, max 9 number (optionally 2 decimal places)",
        wrongPriceFormat: "Wrong price. The right format: max 9 number.",
        showVatHint : "Show VAT helper",
        hideVatHint : "Hide VAT helper",
        nameFilter: "Search product",
        import: "Import products",
        fileAdd: "Select file",
        fileUpload: "Upload file",
        fileUploadSuccess: "File has been uploaded successfully",
        fileExtensionError: "File extension is incorrect",
        saveSuccess: "Products have been saved successfully",
        saveError: "Failed to save products",
        addNew: "Save as new product",
        created: "Product successfully created",
        error: "Failed to create product",
        bulkItem: "Unpackaged product",
        recipe: "Recipe",
        recipeTooltip: "has warehouse product with components",
        productImportError: 'Cannot find unit id with: ${productUnitId} and product id with: ${productId}',
        wrongUnit: "Unit does not exist",
        refundBottle: "Refundable bottle",
        depositFeeHint: "The deposit fee is added to the set price",
        setSimplifiedBu: "The business unit is in simplified view, products are automatically placed on the price list",

    },
    priceListTemplate : {
        title: "Edit price lists",
        id: "ID",
        name: "Unique name for the pirce list template",
        create: "Create new price list template",
        createTemplate: "Create template",
        created: "Price list created",
        createFail: "Price list create failed",
        generalData: "General data",
        managingProducts: "Adding / removing products",
        changePrices: "Change prices",
        addProductsToPriceList: "Add products to price list",
        noProductInPriceList: "Currently there aren't any products in the price list",
        edit: "Edit",
        delete: "Delete",
        saveAndActivate : "Save and activate",
        saveAsTemplate : "Save",
        saveAsInstance : "Price list save",
        successfulSave: "Saved Successfully",
        draft: "Draft",
        activated: "Activated",
        uniqueNameRequired: "Unique name required",
        state: "State",
        price: "Price",
        modify: "Edit",
        cancel: "Cancel",
        empty: "No price lists",
        nameShort: "Price lists",
        createPriceList: "Create new price list",
        createdAt: "Created at",
        template: "Price list template",
        templates: "Price list templates",
        priceHelperText: "Max 9 digit",
        tooMuch: "The number of products exceeds 2000, please use filters",
        comment: "Comment",
    },
    businessUnits : {
        title: "Business Units",
        id: "ID",
        name: "Name",
        create: "Create a new business unit",
        publicDomainName: "Public domain name",
        publicDomainType: "Public domain type",
        number: "Number",
        settlement: "Settlement",
        zipCode: "Zip Code",
        address: "Address",
        empty: "No business units found",
        company: "Company",
        wrongZipCodeFormat: "Wrong format (needed: 4-10 number)",
        ntak: "NTAK registration number",
        generalData: "General data",
        ntakData: "NTAK data",
        ntakNumber: "NTAK registration number",
        generateCSR: "Generate CSR validation file",
        certUpload: "Upload certificate",
        certAdd: "Select certificate",
        fileSelectError: "Error while selecting the certificate",
        fileUploadError: "Error while uploading the certificate",
        fileUploadSuccess: "Certificate successfully uploaded",
        download: "Download CSR file",
        loading: "CSR file generating in progress, please wait",
        csrGenerateError: "an error occurred while generating the file",
        ntakDataProvision: "NTAK data provision",
        ntakChangeHint: "Switching the data service on/off is only possible if there are no open workday in the business unit",
        certHint: 'Only the last generated and saved CSR contains the valid data. If you create a new CSR, it must be uploaded in the "Certificate management" menu of ntak.hu.',
        certExpired: 'The expiration date of the most recently uploaded certificate: ',
        ntakCsrHint: "Save data before generating CSR file",
        companyChangeHint: "Switching the company is only possible if there are no open workday in the business unit",
        isSimplified: "Automatic price list sending",
        simplifiedFlag: "Automatic price list sending",

    },
    technicalUsers : {
        title: "Technical users",
        id: "ID",
        name: "Name",
        create: "Add a new technical user",
        pinCode: "Pin code",
        pinCodeCreate: "Pin code (4 digits)",
        pinCodeAgain: "Pin code again (4 digits)",
        technicalUserData: "Technical user info",
        technicalUserRole: "User's roles",
        posSets: "Groups for the role",
        resetPIN: "Reset PIN",
        userTypeFilter: "User type filter",
        technical: "Technical user",
        registered: "Registered user",
        type: "User Type",
        validation :{
            nameNull: "Name cant be null",
            pinDontMatch: "Pin Codes don't match",
            pinWrongLength: "PIN code must be 4 character",
            pinNotANumber: "PIN code can only contains numbers",
            noSelectedPosSet: "Select at least 1 hierarchy element",
            hpPosUserIdWrongLength: "The HelloPay POS user must be minimum 4 and maximum 20 character",
            hpPosUserIdNotANumber: "The HelloPay POS user can only contain numbers"
        },
        helloPayPosUser: "HelloPay POS user",
        helloPayPosUserHint: "HelloPay POS login user. Minimum 4 number",
        inviteUser: "Invite user",
        company: "Select company",
        businessUnit: "Select business unit",
        privilegesAdmin: "Admin privileges",
        privilegesApi: "Waiter privileges",
        limit: "It is not possible to save more users",
        buy: "To invite more users and create technical users, you need to purchase the user module",
        error: "Check your privileges and try again",
    },
    groups: {
        title: "Groups",
        cantDrag: "Can't be dragged to an element with a lower hierarchy",
        cantUpdate: "Can't be dragged, permission denied",
        cantDragWithOpenShift: "Can't be dragged with open shift",
        cantDragBelowCompany: "Can't be dragged below company",
        editNode: "Edit Group",
        addChild: "Add",
        name: "Name",
        type: "Group type",
        pos: "POS",
        bar: "Bar",
        area: "Area",
        company: "Company",
        businessUnit: "Business Unit",
        editSuccessful : "Successful modification, click the save button to apply the changes",
        editError : "An error occurred while group update",
        deleteSuccessful : "Item has been deleted",
        poss: "POS-s",
        edit: "Click on the group to edit",
        deleteUnsuccessful: "Group can't be deleted until it has a POS as a child",
        expandAll: "Expand all groups",
        collapseAll: "Collapse all groups",
        add: "Add",
        contact: "To purchase more POS systems",
        click: "click here",
        storage: "Storage",
        addStorage: "Add storage",
        selectStorage: "Select storage"
    },
    activePosSets: {
        title: "Active Business Unit",
        posSets: "Business Units",
        change: "Select active business unit",
        noActive: "You don't have an active business unit",
        setActivePosSet: "Select an Active Business Unit",
        changeSuccess: "Active business unit successfully changed"
    },
    priceListToPosSets: {
        title: "Activate Price list",
        priceLists: "Price lists",
        priceList: "Price list",
        empty: "No price list found",
        currentPriceList : "Current price list",
        changePriceList: "Change price List",
        newPriceList: "New Price List",
        updated: "Price List updated",
        error: "Error while refresh the price lists"
    },
    device: {
        title: "Devices",
        empty: "No devices found",
        posId: "POS ID",
        posName: "POS Name",
        deviceId: "Device ID",
        deviceName: "Device Name",
        remove: "Remove from POS",
        shiftOpen: "Currently Has Open Shift",
        cantRemove: "Can't detach Device",
        detachAreYouSure: "Are you sure to detach this device",
        removeShort: "Detach",
        priceListCheckTime: "Last price list sync",
        configCheckTime: "Last configuration date",
        lastCommunication: "Last communication date",
        mobileVersion: "Mobile version"

    },
    pos: {
        title: "Create POS",
        add: "Create a POS",
        name: "POS name",
        edit: "Edit",
        cancel: "Cancel"
    },
    report: {
        title: "Reports",
        add: "Create report",
        groupingByWarehouse: "Grouping by warehouse",
        type: "Report type",
        created: "Report successfully created",
        download: "Download",
        reset: "Reset",
        noSelectedPosSet: "Select at least 1",
        date:{
            start: "Start Date",
            end: "End Date",
            endBeforeStartError: "End date is before start date"
        }
    },
    quickMenu: {
        title: "Quick Menu",
        add: "Add product to quck menu",
        addShort: "Add",
        success: "Product successfully added",
        full: "Quick menu is full, you can't add more products",
        error: "Error while adding a new product",
        addText: "To add product click in the table",
        choosePriceList: "Choose a price list, then you can edit it",
        width: "Width",
        height: "Height",
        tooMuch: "The number of products exceeds 2000, please use search filter",
    },
    priceListsOfPos: {
        title: "Price lists on Pos",
        id: "POS Id",
        name: "POS Name",
        priceListId: "Price list Id",
        priceListName: "Price list Name",
        priceListValidFrom: "ValidFrom",
        priceLists: "Price lists",
        open: "Open",
        search : "Search",
        createdAt: "Created at"
    },
    company: {
        title: "Company",
        id: "ID",
        name: "Name",
        create: "Create a new company",
        publicDomainName: "Public Domain Name",
        publicDomainType: "Public Domain Type",
        number: "Public Domain Number",
        settlement: "Settlement",
        zipCode: "Zip Code",
        state: "State",
        country: "Country",
        address: "Address",
        empty: "empty",
        taxNumber: "Tax Number",
        groupTaxNumber: "VAT group identification number",
        companyRegistrationNumber: "Company Registration Number",
        euTaxNumber: "EU Tax Number",
        contactName: "Contact Name",
        contactEmail: "Contact Email",
        contactPhone: "Contact Phone",
        phonePrefix: "Prefix",
        general: "General",
        contacts: "Contacts",
        created: "Company created successfully",
        wrongZipCodeFormat: "Wrong format (needed: 4-10 number)",
        wrongTaxNumberFormat: "Only valid tax number! Correct format: xxxxxxxx-y-zz",
        wrongEmailFormat: "Wrong email format",
        mustHaveTaxNumber: "Tax number or EU tax number is required",
        companyNameRequired: "Company name is required",
        zipCodeRequired: "Zip code is required",
        stateRequired: "State is required",
        countryRequired: "Country is required",
        settlementRequired: "Settlement is required",
        publicDomainNameRequired: "Public domain name is required",
        publicDomainTypeRequired: "Public domain type is required",
        contactNameRequired: "Contact name is required",
        contactEmailRequired: "Contact email is required",
        contactPhoneRequired: "Contact phone is required",
        numberRequired: "Number is required",
    },
    dashboard:{
        title: "Dashboard",
        activeWorkday: "Active Workday",
        dateRangeFilter: "Date range filter",
        date:{
            start: "Date range start",
            end: "Date Range end",
            endBeforeStartError: "End date is before start date"
        },
        refresh: "Refresh",
        filter: "Filter",
        sumPurchase: "Purchase Sum",
        sumTip: "Tip Sum",
        sumCanceled: "Storno Sum",
        total: "Total Sum",
        averageCartValue: "Average Cart Value",
        averageTip: "Average Tip",
        averageTipPercent: "Average Tip Percent",
        countPurchase: "Purchase Count",
        product: "TOP Product",
        bar: "TOP 10 Bar",
        distributionRatio: "Distribution Ratio",
        cash: "Cash",
        helloPay: "HelloPay",
        card: "Credit card",
        otp: "Credit card",
        otp_manual: "Credit card manual",
        mixed: "Mixed",
        eurCash: "Cash EUR (expressed in HUF)",
        szepCard: "SZÉP card",
        bankTransfer: "Bank transfer",
        helloPayVip: "HelloPay VIP",
        other: "Other",
        voucher: "Voucher",
        roomLoan: "Room loan",
        fiveMin: "5 min",
        fifteenMin: "15 min",
        oneHour: "1 hour",
        trafficCount: "Traffic - Count",
        trafficSum: "Traffic - Sum",
        endBeforeStartError: "End date is before start date",
        moreThanSevenDayInterval: "Interval must be less than 7 days",
        endIsTooMuch: "End date is too much",
        trxCount: "Transaction count",
        tip: "Tip",
        noWorkday: "No open workday found",
        name: "Name",
        piece: "Piece",
        sum: "Sum",
        selectCompanyOrBu : "Select company or business unit",

    },
    unit:{
        pieces: "pcs",
        percent: "%",
        huf: "HUF"
    },
    profile:{
        title: "Edit profile",
        inviteTitle: "Invite profil",
        username: "Email address",
        givenName: "Given name",
        familyName: "Family name",
        phone: "Phone number",
        pin: "PIN",
        helloPayPosUser: "HelloPay POS user",
        helloPayPosUserHint: "HelloPay POS login user. Minimum 4 number",
        phoneHint: "Format: +xxxxxxxxxxx e.g.:+36201234567",
        pinHint: "It can only contain 4 digits",
        success: "Profile data saved",
        loadingError: "Error while loading the profile data",
        savingError: "Error while saving the profile data",
        noSelectedPosSet: "Select at least one",
        noSelectedPosSetOrPrivilege: "Select at least one privilege and location",
        inviteButtonLabel: "Send invite",
        inviteSuccessfullySend: "Invitation link successfully send to the email",
        invSuccessfullyAccepted: "Invite Successfully accepted",
        invError: "Error while invitation accept"
    },
    config:{
        title: "POS configuration",
        config: "Configuration",
        currentConfig: "Current configuration",
        updated: "Configuration updated",
        error: "Error while save the configuration",
        paymentMethods: "PaymentMethods",
        CASH: "Cash",
        CARD: "Card",
        CARD_MANUAL: "Card (manual input)",
        VOUCHER: "Voucher",
        SZEP_CARD: "SZÉP card",
        EUR_CASH: "Cash EURO",
        ROOM_LOAN: "Room loan",
        BANK_TRANSFER: "Bank transfer",
        HELLO_PAY: "HelloPay",
        MIXED: "Mixed",
        HELLO_PAY_VIP: "HelloPay VIP",
        OTHER: "Other",
        paymentDevice: "Payment device",
        none: "None",
        helloPayTerminal: "HelloPay terminal",
        soft_pos: "SoftPOS",
        notIntegrated: "Not integrated",
        clientDisplay: "Client display",
        helloPayCD: "HelloPay display",
        integratedCD: "Integrated display",
        taxPrinter: "NAV",
        taxPrinterConnected: "Tax printer connected",
        secondScreenVideo: "Enable video playback",
        cashRegisterConnected: "Cash register connected",
        discount: "Discount",
        discountAllowed: "Discount allowed",
        serviceFeePercent: "Service fee (0-100%)",
        maxDiscountPercent: "Discount max percent (0-100%)",
        pickMinimumOne: "Pick minimum 1 payment method",
        hideItemsFromCustomer:  "Hide flagged products",
    },
    rMSErrorMessagesList:{
        title: "RMS error messages",
        id: "ID",
        empty: "There is no RMS error message",
        processId: "Process ID",
        status: "Status",
        trxType: "Transaction type",
        trxId: "Transaction ID",
        stornoTrxId: "Storno transaction ID",
        workdayId: "Workday ID",
        rmsId: "RMS ID",
        closedDay: "Closed day",
        sendingTime: "Sending time",
        transactionTimestamp: "Transaction timestamp",
        workdayOpenedAt: "Workday opened at",
        periodicClosedAt: "Periodic close at",
        name: "Name",
        TO_BE_SENT: "To be sent",
        SENT: "Sent",
        ERROR_IN_SENDING: "Error in sending",
        RECEIVED: "Received",
        ERROR_IN_CHECKING: "Error in checking",
        TO_BE_SENT_AGAIN: "To be sent again",
        FULLY_WRONG: "Fully wrong",
        PARTIALLY_SUCCESSFUL: "Partially successful",
        FULLY_SUCCESSFUL: "Fully successful",
        NORMAL: "Normal",
        STORNO: "Storno",
        WORKDAY: "Workday",
        CLOSED_DAY: "Closed day",
        PERIODIC_CLOSED_DAY: "Periodic closed day"
    },
    warehouse:{
        title: "Warehouse products",
        id: "ID",
        name: "Name",
        empty: "No available features",
        items: "Warehouse Items",
        create: "Add new product",
        nameFilter: "Search product",
        productUnits: "Product units",
        packagingUnit: "Packaging unit",
        quantity: "Quantity",
        qtyUnit: "Packaging",
        tags: "Tags",
        mainProductGroup: "Main product group",
        filterTags: "Filter tags",
        filterMainProductGroup: "Filter Main product group",
        unit: "Unit",
        greaterThanZero: "Value must be greater than 0",
        wrongQuantityFormat: "Wrong quantity. The right format: greater than 0, max 9 number (optionally 2 decimal places)",
        unitError: "A unit of measurement can only be ordered for one package",
        circleError: "The units of measurement should be in ascending order.",
        vatGroup: "VAT group",
        isTakeawayAllowed: "Takeaway allowed",
        takeawayVatGroup: "Takeaway VAT group",
        fivePercentText: "5% VAT on food and locally produced non-alcoholic beverages in canteens",
        unitDifference: "Different selected units",
        defaultUnit: "Default unit",
        defaultWarehouseUnit: "Inventory packaging",
        fieldRequired: "Required fields!",
        serverError: "Server communication error",
        siUnitError: "Packaging unit cannot be SI unit",
        duplicateNameError: "The packaging unit must be unique",
        unitAndQuantityError: "unit and quantity can only be null if the other also null",
        duplicateUnitType: "Duplicate unit type",
        active: "Active products",
        inactive: "Inactive products",
        all: "All products",
        products: "Products",
        available: "Available product",

    },
    storage:{
        title: "Manage Warehouses",
        id: "ID",
        name: "Warehouse Name",
        phone: "Phone Number",
        contact: "Warehouse Manager's Name",
        create: "Add New Warehouse",
        address: "Address",
        phonePrefix: "Prefix",
        email: "Contact email",
        isLogistics: "Logistics warehouse",
        isActive: "Active warehouse"
    },
    suppliers:{
        title: "Suppliers",
        id: "ID",
        name: "Name",
        create: "Add New Supplier",
        address: "Address",
        taxNumber: "Tax Number",
        phone: "Phone Number",
        email: "Email",
        contact: "Contact Name",
        phonePrefix: "Prefix",
        empty: "There are no suppliers yet.",
        saveSuccess: "Suppliers have been saved successfully",
        saveError: "Failed to save suppliers",
        import: "Import suppliers",
        supplierIn: "Receipt price list",
        supplierOut: "Withdrawal price list",
        warehouseProducts: "Warehouse products",
        warehouseProduct: "Warehouse product",
        unit: "Unit",
        price: "Net price",
        delete: "Delete",
        wrongImportedFile: "Wrong imported file"

    },
    modifiers: {
        addIngredient: "Add ingredient(s)",
        ingredient: "Ingredient",
        quantity: "Quantity",
        unit: "Unit",
        hideModifier: "Hide modifier",
        addModifier: "Add modifier",
        modifier: "Modifier",
        priceDifference: "Price difference",
        delete: "Delete",
        tags: "Tags",
        product: "Warehouse item",
        active: "Active",
    },
    operations:{
        title: "Warehouse operations",
        id: "ID",
        create: "Add new warehouse operation",
        type: "Warehouse operation type *",
        date: "Date",
        source: "Source",
        destination: "Destination",
        invoice: "Invoice number",
        comment: "Comment",
        sum: "Total (Gross)",
        empty: "No displayable warehouse operation",
        fromSupplier: "Receipt from supplier",
        move: "Inter-Warehouse transfer",
        toSupplier: "Withdrawal to supplier",
        waste: "Disposal",
        switchSupplier: "Select supplier *",
        switchWarehouse: "Select warehouse *",
        product: "Warehouse item",
        quantity: "Quantity",
        unit: "Unit",
        netto: "Net unit price",
        brutto: "Gross unit price",
        vat: "VAT",
        sumNetto: "Net total",
        sumBrutto: "Gross total",
        delete: "Delete",
        discount: "Net discount",
        total: "Total",
        addProduct: "Add warehouse item",
        stock: "Stock",
        sourceStock: "Source warehouse stock",
        targetStock: "Destination warehouse stock",
        alert: "If you change the Source/Destination supplier, the prices of the given supplier in the table below will overwrite the currently entered prices. Are you sure you want to continue?",
        continue: "Continue",
        cancel: "Cancel",
        dialogTitle: "Change supplier",
        import: "Import warehouse operations"
    },
    inventory:{
        title: "Inventory",
        empty: "No inventories to display",
        id: "ID",
        create: "Create inventory",
        type: "Partial inventory",
        switchWarehouse: "Select warehouse",
        date: "Inventory date",
        comment: "Comment",
        warehouse: "Warehouse",
        product: "Product name",
        unitName: "Unit",
        stock: "Stock",
        quantity: "Quantity",
        difference: "Difference",
        search: "Search product",
        surplus: "Surplus:",
        shortage: "Shortage:",
        chosenWarehouse: "Selected warehouse:",
        items: "Warehouse products",
        alert: "Alert",
        dialogContent: "The inventory cannot contain a negative value. Items with a negative value will be automatically inventoried to a stock level of 0.",
        continue: "Continue",
        cancel: "Cancel",
        success: "Inventory created successfully",
        error: "Error during inventory creation",
        invalidNumber: "Invalid number",
    },
    stockReport: {
        title: "Stock changes",
        warehouse: "Select warehouses",
        sup: "Select suppliers",
        dateStart: "Start date",
        dateEnd: "End date",
        name: "Name",
        delete: "Delete",
        create: "Create excel",
        all: "Select all",
        wh: "Warehouse",
        supplier: "Supplier",
        type: "Type",
    },
    stockpile: {
        title: "Stockpile",
        id: "ID",
        warehouseName: "Warehouse item",
        warehouse: "Select warehouses",
        sup: "Select suppliers",
        tags: "Tags",
        date: "Date",
        name: "Name",
        delete: "Delete",
        create: "Create list",
        packaging: "Packaging",
        stock: "Stock",
        netAveragePrice: "Net average price",
        grossAveragePrice: "Gross average price",
        lastNetPrice: "last net price on receipt",
        stockValue: "Net stock value",
        aggregatedStockValue: "Aggregated net stock value",
        exportStockpile: "Export",
        all: "Select all",
        wh: "Warehouse",
        supplier: "Supplier",
        type: "Type",
    },
    purchase: {
        title: "Purchase",
        lite: "LITE",
        standard: "STANDARD",
        professional: "PROFESSIONAL",
        proPlus: "PRO PLUS",
        unique: "UNIQUE",
        db: "db",
        sum: "Total:",
        free: "Free module",
        user: "User module",
        tech: "Technical users",
        int: "Integration module",
        warehouse: "Warehouse management module",
        report: "Reporting module",
        pay: "Payment",
        license: "Number of licenses to purchase:",
        company: "Company *",
        quantity: "Quantity",
        price: "Price",
        liteCount: "The number of Lite licenses cannot exceed 1",
        date: "Date",
        save: "Save",
        success: "Successful purchase",
        error: "Error during purchase",
        support: "Support",
        continue: "Continue to finalize",
        step1: "Select package",
        step2: "Summary and payment",
        details: "The number of licenses determines how many devices can be used simultaneously in the system.",
        noCompany: "You don't have a company yet. You can't buy a license without a company.",
        purchaseInProgress: "A purchase is already in progress for this company",
        continueOnSimple: "Complete or cancel the purchase on the SimplePay page",
        continueOnSimpleButton: "Go to SimplePay",
    },
    purchaseHistory: {
        title: "Purchase History",
        package: "Package name",
        id: "ID",
        purchaseDate: "Purchase date",
        expirationDate: "Expiration date",
        cancel: "Cancel",
        canceled: "Canceled",
        dialogTitle: "Cancel Purchase",
        dialogContent: "Are you sure you want to cancel the purchase?",
        dialogCancel: "Cancel",
        dialogConfirm: "Cancel",
        success: "Successful cancellation",
        error: "Error during cancellation",
        inProgress: "A purchase is already in progress for this company, please try again later."
    },
    redirectToPurchase: {
        title: "Insufficient license for feature usage",
        buy: "Purchase a license to use the feature",
        clickToBuy: "To purchase, ",
        click: "click here"
    },
    resignation: {
        title: "Subscription Cancellation",
        content: "If you are sure you want to cancel your current subscription, please click the 'Cancellation' button.",
        resignation: "Cancellation",
        success: "Successful cancellation"
    },
    shiftClosure: {
        title: "Shift Closure",
        closing: "Close shift",
        warning: "Performing a shift closure here can bring the device into an inconsistent state and transactional data may be lost. Are you sure you want to close the shift?",
        posId: "POS ID",
        posName: "POS name",
        buId: "Business unit ID",
        buName: "Business unit name",
        companyId: "Company ID",
        companyName: "Company name",
        edit: "Edit",
        cancel: "Cancel",
        success: "Successful shift closure",
        error: "Error during shift closure"
    },
    finance: {
        title: "Finance",
        all: "Select all",
        companies: "Select companies",
        id: "id",
        name: "Company name",
        licenseName: "License",
        supportName: "Support",
        licenseCount: "Number of licenses",
        totalAmount: "Total amount",
        purchaseDate: "Purchase date",
        expirationDate: "Expiration date",
        stornoDate: "Storno date",
        paymentStatus: "Payment status",
        invoiceNumber: "Invoice number",
        trxId: "Transaction ID",
        address: "Address",
        email: "E-mail",
        taxNumber: "Tax number",
        groupTaxNumber: "Group tax number",
        selectCompany: "Select company",
        dateStart: "Start date",
        dateEnd: "End date",
    },
    country:{
        hu: "Hungary",
        gb: "United Kingdom",
        us: "United States",
        af: "Afghanistan",
        ax: "Åland Islands",
        al: "Albania",
        dz: "Algeria",
        as: "American Samoa",
        ad: "Andorra",
        ao: "Angola",
        ai: "Anguilla",
        aq: "Antarctica",
        ag: "Antigua and Barbuda",
        ar: "Argentina",
        am: "Armenia",
        aw: "Aruba",
        au: "Australia",
        at: "Austria",
        az: "Azerbaijan",
        bs: "Bahamas",
        bh: "Bahrain",
        bd: "Bangladesh",
        bb: "Barbados",
        by: "Belarus",
        be: "Belgium",
        bz: "Belize",
        bj: "Benin",
        bm: "Bermuda",
        bt: "Bhutan",
        bo: "Bolivia",
        ba: "Bosnia and Herzegovina",
        bw: "Botswana",
        bv: "Bouvet Island",
        br: "Brazil",
        io: "British Indian Ocean Territory",
        bn: "Brunei",
        bg: "Bulgaria",
        bf: "Burkina Faso",
        bi: "Burundi",
        kh: "Cambodia",
        cm: "Cameroon",
        ca: "Canada",
        cv: "Cape Verde",
        ky: "Cayman Islands",
        cf: "Central African Republic",
        td: "Chad",
        cl: "Chile",
        cn: "China",
        cx: "Christmas Island",
        cc: "Cocos (Keeling) Islands",
        co: "Colombia",
        km: "Comoros",
        cg: "Congo",
        cd: "Democratic Republic of the Congo",
        ck: "Cook Islands",
        cr: "Costa Rica",
        hr: "Croatia",
        cu: "Cuba",
        cy: "Cyprus",
        cz: "Czech Republic",
        dk: "Denmark",
        dj: "Djibouti",
        dm: "Dominica",
        do: "Dominican Republic",
        ec: "Ecuador",
        eg: "Egypt",
        sv: "El Salvador",
        gq: "Equatorial Guinea",
        er: "Eritrea",
        ee: "Estonia",
        et: "Ethiopia",
        fk: "Falkland Islands",
        fo: "Faroe Islands",
        fj: "Fiji",
        fi: "Finland",
        fr: "France",
        gf: "French Guiana",
        pf: "French Polynesia",
        tf: "French Southern and Antarctic Lands",
        ga: "Gabon",
        gm: "Gambia",
        ge: "Georgia",
        de: "Germany",
        gh: "Ghana",
        gi: "Gibraltar",
        gr: "Greece",
        gl: "Greenland",
        gd: "Grenada",
        gp: "Guadeloupe",
        gu: "Guam",
        gt: "Guatemala",
        gg: "Guernsey",
        gn: "Guinea",
        gw: "Guinea-Bissau",
        gy: "Guyana",
        ht: "Haiti",
        hm: "Heard Island and McDonald Islands",
        va: "Vatican City",
        hn: "Honduras",
        hk: "Hong Kong",
        is: "Iceland",
        in: "India",
        id: "Indonesia",
        ir: "Iran",
        iq: "Iraq",
        ie: "Ireland",
        im: "Isle of Man",
        il: "Israel",
        it: "Italy",
        jm: "Jamaica",
        jp: "Japan",
        je: "Jersey",
        jo: "Jordan",
        kz: "Kazakhstan",
        ke: "Kenya",
        ki: "Kiribati",
        kp: "North Korea",
        kr: "South Korea",
        kw: "Kuwait",
        kg: "Kyrgyzstan",
        la: "Laos",
        lv: "Latvia",
        lb: "Lebanon",
        ls: "Lesotho",
        lr: "Liberia",
        ly: "Libya",
        li: "Liechtenstein",
        lt: "Lithuania",
        lu: "Luxembourg",
        mo: "Macau",
        mk: "North Macedonia",
        mg: "Madagascar",
        mw: "Malawi",
        my: "Malaysia",
        mv: "Maldives",
        ml: "Mali",
        mt: "Malta",
        mh: "Marshall Islands",
        mq: "Martinique",
        mr: "Mauritania",
        mu: "Mauritius",
        yt: "Mayotte",
        mx: "Mexico",
        fm: "Micronesia",
        md: "Moldova",
        mc: "Monaco",
        mn: "Mongolia",
        me: "Montenegro",
        ms: "Montserrat",
        ma: "Morocco",
        mz: "Mozambique",
        mm: "Myanmar",
        na: "Namibia",
        nr: "Nauru",
        np: "Nepal",
        nl: "Netherlands",
        nc: "New Caledonia",
        nz: "New Zealand",
        ni: "Nicaragua",
        ne: "Niger",
        ng: "Nigeria",
        nu: "Niue",
        nf: "Norfolk Island",
        mp: "Northern Mariana Islands",
        no: "Norway",
        om: "Oman",
        pk: "Pakistan",
        pw: "Palau",
        ps: "Palestine",
        pa: "Panama",
        pg: "Papua New Guinea",
        py: "Paraguay",
        pe: "Peru",
        ph: "Philippines",
        pn: "Pitcairn Islands",
        pl: "Poland",
        pt: "Portugal",
        pr: "Puerto Rico",
        qa: "Qatar",
        re: "Réunion",
        ro: "Romania",
        ru: "Russia",
        rw: "Rwanda",
        bl: "Saint Barthélemy",
        sh: "Saint Helena, Ascension and Tristan da Cunha",
        kn: "Saint Kitts and Nevis",
        lc: "Saint Lucia",
        mf: "Saint Martin (French part)",
        pm: "Saint Pierre and Miquelon",
        vc: "Saint Vincent and the Grenadines",
        ws: "Samoa",
        sm: "San Marino",
        st: "São Tomé and Príncipe",
        sa: "Saudi Arabia",
        sn: "Senegal",
        rs: "Serbia",
        sc: "Seychelles",
        sl: "Sierra Leone",
        sg: "Singapore",
        sx: "Sint Maarten (Dutch part)",
        sk: "Slovakia",
        si: "Slovenia",
        sb: "Solomon Islands",
        so: "Somalia",
        za: "South Africa",
        gs: "South Georgia and the South Sandwich Islands",
        ss: "South Sudan",
        es: "Spain",
        lk: "Sri Lanka",
        sd: "Sudan",
        sr: "Suriname",
        sj: "Svalbard and Jan Mayen",
        sz: "Swaziland",
        se: "Sweden",
        ch: "Switzerland",
        sy: "Syria",
        tw: "Taiwan",
        tj: "Tajikistan",
        tz: "Tanzania",
        th: "Thailand",
        tl: "Timor-Leste",
        tg: "Togo",
        tk: "Tokelau",
        to: "Tonga",
        tt: "Trinidad and Tobago",
        tn: "Tunisia",
        tr: "Turkey",
        tm: "Turkmenistan",
        tc: "Turks and Caicos Islands",
        tv: "Tuvalu",
        ug: "Uganda",
        ua: "Ukraine",
        ae: "United Arab Emirates",
        um: "United States Minor Outlying Islands",
        uy: "Uruguay",
        uz: "Uzbekistan",
        vu: "Vanuatu",
        ve: "Venezuela",
        vn: "Vietnam",
        vg: "British Virgin Islands",
        vi: "U.S. Virgin Islands",
        wf: "Wallis and Futuna",
        eh: "Western Sahara",
        cw: "Curaçao",
        bq: "Caribbean Netherlands",
        ye: "Yemen",
        zm: "Zambia",
        zw: "Zimbabwe"
    },
    summary: {
        title: "Summary",
        email: "Email",
        simpleAszf: "I acknowledge that the personal data stored in the admin.pinkpos.com user database by Pink POS Kft (2120 Dunakeszi, Kismarton utca 19.) data controller will be transferred to OTP Mobil Kft., as data processor. The scope of data transmitted by the data controller includes the buyer's email address, billing name, country, county, city, zip code, and address. The nature and purpose of data processing performed by the data processor can be found in the SimplePay Data Processing Information, which can be viewed at the following link: ",
        simpleLink: "http://simplepay.hu/vasarlo-aff",
        company: "Company",
        payable: "Payable",
        pay: "Payment",
        cancel: "Cancel",
        finalization: "Finalization",
        netto: "Net amount to be paid:",
        monthly: "Monthly amount to be paid:",
        pinkAszf: `The recurring card payment (hereinafter: "Recurring payment") is a feature provided by SimplePay related to card acceptance, which means that future payments can be initiated with the card details provided by the Buyer during the registration transaction without re-entering them. The so-called "case-by-case consent" type of recurring payment is done with the Buyer's case-by-case approval for each transaction, so you must approve the transaction for all future payments. You will be notified of the successful payment through the same channels as traditional card payments. By accepting this statement, you consent to future payments initiated from your user account on this webshop (admin.pinkpos.com) after the successful registration transaction to be made without re-entering the card details. Attention(!): The handling of card data is in accordance with card company rules. Neither the Merchant nor SimplePay has access to the card data. The Merchant is directly responsible for recurring payment transactions initiated incorrectly or unlawfully, any claim against the Merchant's payment service provider (SimplePay) is excluded. I have read this information, I acknowledge its content and accept it.`,
        summary: "The subscription will be valid until the cancellation or for a maximum of 24 months, monthly, maximum 24 times ",
        summary2: " EUR will be invoiced at the current rate.",
        reqMail: "* Email field is mandatory",
        back: "Back",
        success: "Successful payment",
        error: "Error during payment",
        timeout: "Payment timed out",
        cancelled: "Payment canceled",
        fraud: "Payment canceled due to suspicious transaction",
    },
    back: {
        progress: "Processing in progress",
        backToHome: "Back to homepage",
        transactionNum: "SimplePay transaction identifier: ",
        success: "Successful payment",
        backToLicense: "Back to purchase page",
        timeout: "Transaction timed out and was terminated",
        fail: "Failed payment",
        failText: "Please check the accuracy of the data provided during the transaction. If you have provided all the data correctly, please contact your card-issuing bank to investigate the reason for the rejection.",
        cancelled: "Payment cancelled",
        fraud: "Payment cancelled due to suspicious transaction",
        notAuthorized: "Unauthorized transaction",
        reversed: "Reversed locked transaction"
    }


}

const signUp  = {
    signUp : {
        login: "Login",
        forgotPassword: "Forgot password",
        registration: "Sign up",
        verification: "Verification",
        email: "Email",
        password: "Password",
        newPassword: "New password",
        newPasswordAgain: "New password again",
        passwordDifferentException: "Passwords are different",
        verificationCodeRequest: "Verification code request",
        save: "Save",
        givenName: "Given name",
        familyName: "Family name",
        phone: "Phone number",
        pin: "PIN",
        helloPayPosUser: "HelloPay POS user",
        helloPayPosUserHint: "HelloPay POS login user. Minimum 4 number",
        phoneHint: "Format: +xxxxxxxxxxx e.g.:+36201234567",
        pinHint: "It can only contain 4 digits",
        passwordHint: " Must be at least 8 long and must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character (^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = )",
        verificationCode: "Verification code",
        verificationCodeSent: "Verification code sent. You can find the code in your email account.",
        signIn: "Login",
        signUp: "Sign up",
        readAndAccepted: "I read and accepted",
        termsAndConditions: "Conditions of Use.",
        privacyPolicy: "Privacy Notice.",
        notAuthorizedException: "Incorrect username or password.",
        passwordEmpty: "The password cannot be empty.",
        passwordPolicy: "The password is incorrect. Must be at least 8 long and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character(^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = ) ",
        accountExist: "There is already a user with this email address.",
        givenNameEmpty: "The given name cannot be empty.",
        familyNameEmpty: "The family name cannot be empty.",
        termsAndConditionsUnaccepted: "Please make sure to accept the Terms and Conditions before proceeding",
        phoneNotValid: "Phone number format is incorrect. Correct format: +36xxxxxxxxx",
        pinEmpty: "PIN format is not correct. You can only enter numbers.",
        invalidVerificationCode: "Invalid verification code, please try again.",
        expiredVerificationCode: "Expired verification code, please try again.",
        invalidCode: "Invalid code provided, please request a code again.",
        limitExceededException: "Attempt limit exceeded, please try after some time.",
        successfulRegistration: "Successful registration. You will find a verification code in your email account.",
        successfulVerification: "Verification successful. You can now log in to the site."
    }
}

const components = {
    emptyPageOrAccessDenied: {
        empty: "There are no items",
        accessDenied: "No page or access denied"
    },
    hierarchySingleSelector: {
        selectCompanyOrBu: "Select a company or business unit",
        selectBu: "Select business unit"
    },
    invitePermission : {
        hu: {
            ppos: {
                pposauth: {
                    Privilege: {
                        Admin: {
                            PriceList: "Price list",
                            Product: "Product",
                            BusinessUnit: "Businessunit",
                            Device: "Device",
                            PosHierarchy: "POS hierarchy",
                            PriceListToPosSet: "Price list on POS",
                            User: "User",
                            Pos: "Price lists on POS",
                            Report: "Report",
                            Company: "Company",
                            Dashboard: "Dashboard",
                            Invitation: "Invitation",
                            PosConfig: "POS config",
                            PrivilegeEditor: "Privilege editor",
                            WarehouseProduct: "Warehouse",
                            Disposal: "Disposal"
                        },
                        Api: {
                            CashRegister: "Cash register",
                            Shift: "Shift",
                            Workday: "Workday",
                            Storno: "Storno",
                            Seller: "Seller"
                        }
                    }
                }
            }
        }
    },
    companySelector: {
        selectCompany: "Select company"
    }
}

const engMessages = mergeDeep(englishMessages, missingTranslations, data, signUp, components);


export default engMessages;