import {IconButton,} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {useEffect, useState} from "react";
import IngredientForm from "./IngredientForm";
import {useTranslate} from "react-admin";
import dataProvider from "../../../components/dataProvider";
import BuyWarehouseCard from "./BuyWarehouseCard";

const Ingredients = ({scopedFormData}) => {

    const [unitComponents, setUnitComponents] = useState(scopedFormData.unitComponents || [])
    const posSetId = localStorage.getItem("productPosSetId")
    const [hasWarehouseAccess, setHasWarehouseAccess] = useState(false)
    const translate = useTranslate()



    useEffect(() => {
        scopedFormData.unitComponents = unitComponents
    }, [unitComponents])

    useEffect(() => {
        dataProvider.get(`warehouse/pos-sets`)
            .then(value => {
                const companyAndBu = []
                for (const company of value.data.list){
                    const obj = {id: company.id, name: company.name, posSetSelectorPrivileges: company.posSetSelectorPrivileges}
                    companyAndBu.push(obj)
                    for (const bu of company.children){
                        companyAndBu.push(bu)
                    }
                }
                companyAndBu.find(it => it.id === parseInt(posSetId))?.posSetSelectorPrivileges.getPrivilegeCompany ? setHasWarehouseAccess(true) : setHasWarehouseAccess(false)
            })
            .catch(reason => {
                console.log(reason)
            })
    },[])

    const handleAddIngredient = () => {
        const newId = Date.now()
        setUnitComponents([...unitComponents, {feId: newId,
            warehouseProductId: null,
            warehouseProductUnitId: null,
            quantity: null,
            modifiers: [],
            modifierOpen: false}])
        }

    const ingredientsStyle = {
        position: 'relative',
        overflowX: 'auto',
        display: 'flex',
        marginLeft: '13rem',
        marginTop: '-8rem',
        whitespace: 'nowrap',
        width: '39rem',
    }

    return (
        <>
            <IconButton size={"small"} onClick={handleAddIngredient}>
                <AddCircleOutlineIcon fontSize={"small"} sx={{color: "#ff71bc"}}/>
                <span style={{fontSize: '11px', marginLeft: '3px'}}>{translate("modifiers.addIngredient")}</span>
            </IconButton>

            {unitComponents && unitComponents.length > 0 &&
                !hasWarehouseAccess ?
                <div style={{
                    position: 'relative',
                    marginLeft: '13rem',
                    width: '20rem',
                    marginTop: '-8rem',
                }}>
                <BuyWarehouseCard/>
                </div>
                :
                <div style={ingredientsStyle}>
                    {unitComponents.map((ingredient, index) => {
                        return <IngredientForm posSetId={posSetId} ingredient={ingredient} index={index} setUnitComponents={setUnitComponents} num={unitComponents.length} key={ingredient.id ? ingredient.id : ingredient.feId}/>
                    })
                    }
                </div>

            }
        </>
    )
}

export default Ingredients