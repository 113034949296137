import {regex, TextInput} from "react-admin";
import React from "react";

const taxNumberFormat = (v) => {
    if (v === undefined || v === null) {
        return v;
    }

    v = v.trim().replace(/[^0-9]/g, '');

    const format = 'xxxxxxxx-y-zz';

    let results = '';

    for (let i = 0, formatOffset = 0; i < v.length; i++) {
        if (format[i + formatOffset] === '-') {
            results += '-';
            formatOffset++;
        }

        results += v[i];
    }

    return results;
}

export const TaxNumberInput = ({...props}) => {
    return <TextInput format={taxNumberFormat} parse={taxNumberFormat}
                      inputProps={{maxLength: 13}} {...props}/>
}