import {Create} from "react-admin";
import {VoucherGeneratorDetails} from "./VoucherGeneratorDetails";

export const VoucherGeneratorCreate = () => {

    return (
        <Create>
            <VoucherGeneratorDetails />
        </Create>
    )
}