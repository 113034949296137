import React from "react";
import { TreeView , TreeItem} from '@mui/x-tree-view';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import {Business, CreditCard, LocalBar, Storefront, Store} from "@mui/icons-material";

export default function RecursiveTreeView({data, selected, setSelected, disabledList, privilegeSetter}) {

    const selectedSet = React.useMemo(() => new Set(selected), [selected]);

    const parentMap = React.useMemo(() => {
        return goThroughAllNodes(data);
    }, []);

    const Icon = ({obj, style}) => {
        if (obj) {
            switch (obj) {
                case "POS":
                    return (<div><CreditCard style={style}/></div>)
                case "BAR":
                    return (<LocalBar variant={"body1"} style={style}/>)
                case "AREA":
                    return (<Storefront  style={style}/>)
                case "BUSINESS_UNIT":
                    return (<Store  style={style}/>)
                case "COMPANY":
                    return (<Business  style={style}/>)
                default: break;
            }
        } else {
            return <div/>
        }
    }

    function goThroughAllNodes(nodes, map= {}) {
        if (!nodes.children) {
            return null;
        }

        map[nodes.id] = getAllChild(nodes).splice(1);

        for (let childNode of nodes.children) {
            goThroughAllNodes(childNode, map);
        }

        return map;
    }

    // Get all children from the current node.
    function getAllChild(
        childNode,
        collectedNodes = []
    ) {
        if (childNode === null) return collectedNodes;

        collectedNodes.push(childNode.id);

        if (Array.isArray(childNode.children)) {
            for (const node of childNode.children) {
                getAllChild(node, collectedNodes);
            }
        }

        return collectedNodes;
    }

    const getChildById = (nodes, id) => {
        let array = [];
        let path = [];

        // recursive DFS
        function getNodeById(node, id, parentsPath) {
            let result = null;

            if (node.id === id) {
                return node;
            } else if (Array.isArray(node.children)) {
                for (let childNode of node.children) {
                    result = getNodeById(childNode, id, parentsPath);

                    if (!!result) {
                        parentsPath.push(node.id);
                        return result;
                    }
                }

                return result;
            }

            return result;
        }

        const nodeToToggle = getNodeById(nodes, id, path);

        return { childNodesToToggle: getAllChild(nodeToToggle, array), path };
    };

    function getOnChange(checked, nodes) {
        if(checked && privilegeSetter){
            privilegeSetter(nodes.privilegeIds, true)
        }
        const { childNodesToToggle, path } = getChildById(data, nodes.id);
        //console.log("childNodesToChange", { childNodesToToggle, checked });

        let array = checked
            ? [...selected, ...childNodesToToggle]
            : selected
                .filter((value) => !childNodesToToggle.includes(value))
                .filter((value) => !path.includes(value));

        array = array.filter((v, i) => array.indexOf(v) === i);

        setSelected(array);
    }

    const renderTree = ({nodes, disabledList}) => {
        let allSelectedChildren = false
        let indeterminate = false
        //Ha minden child ki van szelektálva akkor kijelöli önmagát. Ha csak részlete van kijelöléve akkor sima csíkkal jelöli, nem pipával.
        if(parentMap) {
            //allSelectedChildren = parentMap[nodes.id]?.every((childNodeId) => selectedSet.has(childNodeId));
            indeterminate = parentMap[nodes.id]?.some((childNodeId) => selectedSet.has(childNodeId)) || false;
        }
        const checked = selectedSet.has(nodes.id) || allSelectedChildren || false;
        const disabled = disabledList.includes(nodes.id)
        



        if (allSelectedChildren && !selectedSet.has(nodes.id)) {
            //console.log("if allSelectedChildren");

            setSelected([...selected, nodes.id]);
        }

        return (
            <TreeItem
                key={nodes.id}
                nodeId={JSON.stringify(nodes.id)}
                label={
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked || disabled}
                                disabled={disabled}
                                indeterminate={!checked && indeterminate}
                                onChange={(event) =>
                                    getOnChange(event.currentTarget.checked, nodes)
                                }
                                onClick={(e) => e.stopPropagation()}
                            />
                        }
                        label={<div style={{display: "flex"}}><Icon  style={{marginRight: "8px"}} obj={nodes.type} /> <Typography variant={"body1"} > {nodes.name} </Typography></div>}
                        key={nodes.id}
                    />
                }
             itemId={nodes.id}>
                {Array.isArray(nodes.children)
                    ? nodes.children.map((node) => renderTree({nodes: node, disabledList: disabledList}))
                    : null}
            </TreeItem>
        );
    };

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {renderTree({nodes: data, disabledList: disabledList})}
        </TreeView>
    );
}
