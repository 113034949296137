import {Create, SimpleForm, useNotify, useRedirect, useTranslate} from "react-admin";
import InventoryDetails from "./InventoryDetails";
import {PinkToolbar} from "../../../components/PinkToolbar";
import {useState} from "react";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useDataProvider} from "react-admin";
import bigDecimal from "js-big-decimal";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";


const InventoryCreate = ()=>{
    const [enableSave, setEnableSave] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const provider = useDataProvider()
    const [formData, setFormData] = useState()
    const translate = useTranslate()
    const notify = useNotify()
    const redirect = useRedirect()
    const transform = (data) => {
        data.posSetId = localStorage.getItem("inventoryPosSetId")
        return data
    }

    const calcDifference = (values) => {
        const items = values.inventoryItems.map(item => {
            const quantity = (item.quantity >= 0 && item.quantity !== '') ? new bigDecimal(item.quantity) : (item.stock >= 0 ? new bigDecimal(item.stock) : new bigDecimal(0))
            const difference = quantity.subtract(new bigDecimal(item.stock))
            item.quantity = quantity.value
            item.difference = difference.round(10).value
            item.netAmount = difference.multiply(new bigDecimal(item.netPrice)).value
            item.grossAmount = difference.multiply(new bigDecimal(item.grossPrice)).value
            return item
        })
        return {...values, inventoryItems: items}
    }

    const handleSubmit = (values) => {
        const allQuantitiesPositive = values.inventoryItems.every(item =>{
            if (item.quantity === '' && item.stock < 0){
                return false
            } else{
                return item.quantity >= 0
            }
        } )
        if (!allQuantitiesPositive) {
            setOpenDialog(true)
            setFormData(values)
        }else{
            const data = calcDifference(values)
            provider.create('inventory', {data: data})
                .then(() => {
                    redirect("/inventory")
                    notify(translate("inventory.success") , 'success')
                }) .catch(() => {
                notify(translate("inventory.error"), 'error')
            })
        }
    }

    const handleClose = () => {
        setOpenDialog(false)
    }

    const handleConfirm = () => {
        setOpenDialog(false)
        const data = calcDifference(formData)
        provider.create('inventory', {data: data})
            .then(() => {
              redirect("/inventory")
              notify(translate("inventory.success") , 'success')
            }) .catch(() => {
                notify(translate("inventory.error"), 'error')
                })
        }

    return(
        <>
        <Create redirect={"list"} transform={transform}>
            <SimpleForm toolbar={<PinkToolbar alwaysEnableSave={enableSave} deleteEnabled={false}/>} onSubmit={handleSubmit}>
                <InventoryDetails isChecked={isChecked} setIsChecked={setIsChecked} setEnableSave={setEnableSave}/>
                <Dialog open={openDialog} onClose={handleClose}>
                    <DialogTitle>{translate("inventory.alert")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {translate("inventory.dialogContent")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            {translate("inventory.cancel")}
                        </Button>
                        <Button onClick={handleConfirm} color="primary">
                            {translate("inventory.continue")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </SimpleForm>

        </Create>
            <img src={shorticLogo}
                    style={{
                        position: "relative",
                        bottom: "0",
                        left: "20",
                        width: "150px",
                        paddingBottom: '10px',
                        paddingTop: '10px'
                    }}/>
        </>
    )
}

export default InventoryCreate