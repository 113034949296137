import {Create} from "react-admin";
import WarehouseStorageDetails from "./WarehouseStorageDetails";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";
import React from "react";

const WarehouseStorageCreate = ()=>{
    const transform = (data) => {
        data.posSetId = localStorage.getItem("warehousePosSetId")
        if(data.phone == null) {
            data.phonePrefix = null
        }
        return data
    }
    return (
        <>
        <Create redirect={"list"} title={"storage.title"} transform={transform}>
            <WarehouseStorageDetails/>
        </Create>
    <img src={shorticLogo}
         style={{
             position: "relative",
             bottom: "0",
             left: "20",
             width: "150px",
             paddingBottom: '10px',
             paddingTop: '10px'
         }}/>
        </>
)
}

export default WarehouseStorageCreate