import {Edit, SimpleForm} from "react-admin";
import OperationsDetails from "./WarehouseOperationsDetails";
import {PinkToolbar} from "../../../components/PinkToolbar";
import React, {useState} from "react";


const OperationsEdit = () => {
    const [enableSave, setEnableSave] = useState(false)
    const [disposalPrivilege, setDisposalPrivilege] = useState(true)
    const [allItemsFilled, setAllItemsFilled] = useState(false)
    const enableSaveButton = enableSave && disposalPrivilege && allItemsFilled
    return (<Edit redirect={"list"}>
        <SimpleForm toolbar={<PinkToolbar alwaysEnableSave={enableSaveButton} deleteEnabled={false}/>}>
            <OperationsDetails setEnableSave={setEnableSave} setDisposalPrivilege={setDisposalPrivilege} setAllItemsFilled={setAllItemsFilled}/>
        </SimpleForm>
        </Edit>
    )
}

export default OperationsEdit