import {Card, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {Bar, CartesianGrid, Tooltip, XAxis, YAxis, BarChart} from "recharts";
import React from "react";
import {useTranslate} from "react-admin";

export const BarChartCard = ({data, xAxisKey, title, queryParams, setQueryParams, refresh, bar1Name, bar2Name, type}) => {
const translate = useTranslate()

    const handleToggle = (event, newAlignment) => {
    if(type === "SUM"){
        setQueryParams({...queryParams, sumInterval: newAlignment});
        refresh(["SUM_BY_INTERVAL"])
    }
    if(type === "COUNT") {
        setQueryParams({...queryParams, counterInterval: newAlignment});
        refresh(["COUNT_BY_INTERVAL"])
    }

    };
    return(
        <Card sx={{ width: "600px", height: "300px", marginRight: "2rem", marginBottom: "2rem"}}>
            <div style={{display:"flex",  flexDirection: "row"}}>
                <Typography sx={{marginLeft: "1rem", marginTop: "1rem", fontSize: 14 }} color="text.secondary" gutterBottom>
                    {title}
                </Typography>
                <ToggleButtonGroup
                    color="primary"
                    value={type === "COUNT" ? queryParams.counterInterval : queryParams.sumInterval}
                    exclusive
                    onChange={handleToggle}
                    style={{marginLeft: "1rem", marginRight: "1rem", marginTop: "0.5rem"}}
                >
                    <ToggleButton size={"small"} value={5}>{translate("dashboard.fiveMin")}</ToggleButton>
                    <ToggleButton size={"small"}
                                  value={15}>{translate("dashboard.fifteenMin")}</ToggleButton>
                    <ToggleButton size={"small"} value={60}>{translate("dashboard.oneHour")}</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <BarChart
                width={600}
                height={250}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xAxisKey} interval={"preserveStartEnd"} fontSize={12}>
                </XAxis>
                <YAxis />
                <Tooltip />
                <Bar dataKey={bar1Name} stackId="a" fill="#00C49F" name={translate(`dashboard.${bar1Name}`)}/>
                {bar2Name ?
                    <Bar dataKey={bar2Name} stackId="a" fill="#ff71bc" name={translate(`dashboard.${bar2Name}`)}/>
                    :
                    null
                }
            </BarChart>
        </Card>
    )
}