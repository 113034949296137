import React, {useEffect, useState} from 'react';
import {
    DatagridConfigurable,
    List,
    SimpleForm,
    TextField,
    TextInput,
    useRecordContext,
    useRedirect,
    useTranslate
} from "react-admin";
import {DataGrid} from "@mui/x-data-grid";
import moment from "moment-timezone";
import {Button, Paper, ThemeProvider, Typography} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {muiDatagridTheme} from "../../components/MuiDatagridTheme";
import {uuidv4} from "../../tools/generate";
import SelectCompanyWithBusinessUnitInput from "../../components/SelectCompanyWithBusinessUnitInput";
import {PinkPagination} from "../../components/PinkPagination";


const PriceListPanel = () => {
    const record = useRecordContext()
    console.log(record)
    const priceLists = record.priceLists.map(it => {
        return {...it, internalId: uuidv4()}
    });
    const format = "YYYY.MM.DD HH:mm"
    const translate = useTranslate()
    const redirect = useRedirect()

    const columns = [
        {field: 'id', headerName: translate("priceListsOfPos.priceListId"), width: 70},
        {field: 'name', headerName: translate("priceListsOfPos.priceListName"), width: 300},
        {
            field: "createdAt", headerName: translate("priceListsOfPos.createdAt"), width: 300,
            renderCell: (params) => {
                if (params.value == null) {
                    return '';
                } else {
                    return moment(new Date(params.value)).format(format);
                }
            }
        },
        {
            field: "validFrom", headerName: translate("priceListsOfPos.priceListValidFrom"), width: 300,
            renderCell: (params) => {
                if (params.value == null) {
                    return '';
                } else {
                    return moment(new Date(params.value)).format(format);
                }
            }
        },
        {
            field: "action",
            headerName: translate("priceListsOfPos.open"),
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    redirect(`/priceList/${params.row.id}/show`)
                };
                return <Button onClick={onClick}><VisibilityIcon/></Button>;
            }
        },
    ]

    return (
        <Paper elevation={5} style={{height: "340px"}}>
            <Typography style={{paddingTop: "8px", marginBottom: "8px", marginLeft: "8px"}}
                        variant={"h4"}>{translate("priceListsOfPos.priceLists")}</Typography>
            <ThemeProvider theme={muiDatagridTheme}>
                <DataGrid autoHeight={false} style={{height: "290px"}}
                          rows={priceLists}
                          columns={columns}
                          disableRowSelectionOnClick={true}
                          getRowId={(row) => row.internalId}
                          initialState={{
                              sorting: {
                                  sortModel: [{field: 'validFrom', sort: 'desc'}],
                              },
                          }}
                />
            </ThemeProvider>
        </Paper>
    )
}

export const ListPriceListsOfPos = () => {
    const [selectedPosSetId, setSelectedPosSetId] = useState(localStorage.getItem("posPosSetId") || 0)

    useEffect(() => {
        if (selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) {
            localStorage.setItem("posPosSetId", selectedPosSetId)
        }
    }, [selectedPosSetId])

    const PosFilters = [
        <TextInput label="priceListsOfPos.search" source="q" alwaysOn/>
    ];
    return (
        <div>
            <SimpleForm toolbar={false} sx={{width: '300px'}}>
                <SelectCompanyWithBusinessUnitInput getResource={"pos"} setPosSet={setSelectedPosSetId}/>
            </SimpleForm>

            {(selectedPosSetId && selectedPosSetId !== "undefined" && parseInt(selectedPosSetId) !== 0) &&
                <List filters={PosFilters} title={"priceListsOfPos.title"}
                      pagination={<PinkPagination trigger={selectedPosSetId}/>}
                      filterDefaultValues={{posSetId: selectedPosSetId}}
                >
                    <DatagridConfigurable bulkActionButtons={false} expand={<PriceListPanel/>} expandSingle={true}
                                          sx={{
                                              '& .column-id': {width: "100px"},
                                          }}
                    >
                        <TextField source="id" label={'priceListsOfPos.id'}/>
                        <TextField source="name" label={'priceListsOfPos.name'}/>
                    </DatagridConfigurable>
                </List>}
        </div>
    )
}