import {
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer, useTranslate, useRecordContext, ReferenceInput, AutocompleteInput
} from "react-admin";
import React, {useState} from "react";
import {PermissionDatagrid} from "../../components/PermissionDatagrid";
import {Typography} from "@mui/material";
import {useWatch} from "react-hook-form";
import {DefaultUserRolesWithPosSets} from "./DefaultUserRolesWithPosSets";

export const SetRoles = (props) => {
    const translate = useTranslate()
    const record = useRecordContext()


    return (record && !record?.isTechnical) ?
        <div style={{display: 'inline-flex', flexDirection: 'column'}}>
            <ArrayInput source={"updatedPrivileges"} label={"technicalUsers.technicalUserRole"}>
                <SimpleFormIterator disableReordering={true}>
                    <FormDataConsumer>
                        {({scopedFormData, index}) => {
                                return(
                                    <div>
                                        <Typography variant={"h5"} style={{ width: "300px"}}>{translate("technicalUsers.privilegesAdmin")} </Typography>
                                        <PermissionDatagrid propAdmin={true} scopedFormData={scopedFormData} resource={"user/editor"} />
                                        <Typography variant={"h5"} style={{ width: "300px"}}>{translate("technicalUsers.privilegesApi")} </Typography>
                                        <PermissionDatagrid propAdmin={false} scopedFormData={scopedFormData} resource={"user/editor"} />
                                    </div>
                                )
                        }
                        }
                    </FormDataConsumer>

                </SimpleFormIterator>
            </ArrayInput>
        </div>
    : <div style={{display: 'inline-flex', flexDirection: 'column'}}>
            <ArrayInput source={"roles"} label={"technicalUsers.technicalUserRole"}>
                <SimpleFormIterator disableReordering={true}>
                    <ReferenceInput source={"id"} reference={'user/roles'}>
                        <AutocompleteInput optionText={"name"} label={"technicalUsers.technicalUserRole"} />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {({getSource, scopedFormData, formData}) => {
                            if(scopedFormData) {
                                return(
                                    <DefaultUserRolesWithPosSets index={scopedFormData} formData={formData} source={"user"} updatedResourceName={"updatedRoles"} disableAlreadySelected={false} />
                                )
                            }else {
                                return(
                                    <div></div>
                                )
                            }
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </div>
}