import {useTranslate,} from "react-admin";
import Autocomplete from "@mui/material/Autocomplete";
import {useFormContext} from "react-hook-form";
import {useEffect, useState} from "react";
import dataProvider from "../../../components/dataProvider";
import {Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import {DataGrid,} from "@mui/x-data-grid";
import "./datagrid.css"
import {ThemeProvider} from "@mui/material/styles";
import {muiDatagridTheme} from "../../../components/MuiDatagridTheme";
import bigDecimal from "js-big-decimal";
import {useNotify} from "react-admin";

const InventoryDetails = ({setEnableSave, isChecked, setIsChecked}) => {

    const {setValue} = useFormContext();
    const translate = useTranslate()
    const [warehouses, setWarehouses] = useState([])
    const [selectedWarehouse, setSelectedWarehouse] = useState([0])
    const [filterText, setFilterText] = useState('')
    const [rows, setRows] = useState([])
    const notify = useNotify()
    const posSetId = localStorage.getItem("inventoryPosSetId")
    const filteredRows = rows.filter(row => row.warehouseProductName.toLowerCase().includes(filterText.toLowerCase()))
    setValue("posSetId", posSetId)
    setValue("inventoryItems", rows)
    setValue("partInventory", isChecked)

    useEffect(() => {
        dataProvider.get("inventory/warehouses", {posSetId: posSetId})
            .then(value => {
                setWarehouses(value.data)
            })
            .catch(reason => {
                setWarehouses([])
            })
    }, [])

    useEffect(() => {
        dataProvider.get("inventory/warehouse-products", {warehouseId: selectedWarehouse, posSetId: posSetId})
            .then(value => {
                const data = value.data.warehouseProducts.map(row => {
                    return {
                        warehouseProductId: row.id,
                        stock: row.stock,
                        unitId: row.unitId,
                        warehouseProductName: row.warehouseProductName,
                        unitName: row.unitName,
                        warehouseProductUnitId: row.warehouseProductUnitId,
                        warehouseProductUnitName: row.warehouseProductUnitName,
                        feQuantity: null,
                        quantity: row.stock,
                        difference: null,
                        feDifference: null,
                        netPrice: row.avgNetPrice,
                        grossPrice: row.avgGrossPrice,
                        netAmount: 0,
                        grossAmount: 0,
                    }
                })
                setRows(data)
                setValue("trxIds", value.data.trxIds)
            })
            .catch(reason => {
                setRows([])
            })
    }, [selectedWarehouse])

    useEffect(() => {
        if (rows.length > 0) {
            const enable = rows.every(row => row.feQuantity !== null && row.feQuantity !== "")
            setEnableSave(!!(enable || isChecked))
        } else {
            setEnableSave(false)
        }

    }, [rows, isChecked])

    const columns = [
        {field: 'warehouseProductName', headerName: translate("inventory.product"), width: 200, sortable: false},
        {field: 'unitName', headerName: translate("inventory.unitName"), width: 120, sortable: false},
        {field: 'stock', headerName: translate("inventory.stock"), width: 120, sortable: false},
        {field: 'netPrice', headerName: 'nettó egységár', width: 120, sortable: false,
            renderCell: (params) => {
                return bigDecimal.round(params.row.netPrice, 2)
            }
        },
        {field: 'grossPrice', headerName: 'bruttó egységár', width: 120, sortable: false,
            renderCell: (params) => {
                return bigDecimal.round(params.row.grossPrice, 2)
            }
        },
        {
            field: 'feQuantity',
            headerName: translate("inventory.quantity"),
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <TextField
                        key={params.row.warehouseProductId}
                        variant={"standard"}
                        type={"number"}
                        value={params.row.feQuantity}
                        onChange={(event) => {
                            setRows( prevState =>  prevState.map(row => {
                                if (row.warehouseProductId === params.row.warehouseProductId) {
                                    const value = event.target.value.replace(/-/g,'').replace(/,/g, '.')
                                    if (value.startsWith('.')) {
                                        notify(translate("inventory.invalidNumber"), {type: 'error'})
                                        return row
                                    }
                                    return {
                                        ...row,
                                        feQuantity: value,
                                        difference: value === '' ? null : (value - row.stock).toFixed(2),
                                        feDifference: value === '' ? null : (value - row.stock).toFixed(2),
                                        quantity: value,
                                        netPrice: row.netPrice,
                                        grossPrice: row.grossPrice,
                                        netAmount: value === '' ? null : ((value - row.stock) * row.netPrice),
                                        grossAmount: value === '' ? null : ((value - row.stock) * row.grossPrice)
                                    }
                                }
                                return row
                            }))
                        }
                        }

                    />

                )
            }
        },

        {
            field: 'feDifference',
            headerName: translate("inventory.difference"),
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography key={params.row.id}>
                        {params.row.difference > 0 ? translate("inventory.surplus") : params.row.feDifference === 0 ? "" : translate("inventory.shortage")} {params.row.feDifference}
                    </Typography>
                )
            }
        },
        {
            field: 'netAmount',
            headerName: "Különbség nettó",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography key={params.row.id}>
                        {params.row.difference > 0 ? translate("inventory.surplus") : params.row.difference === 0 ? "" : translate("inventory.shortage")} {(params.row.difference * params.row.netPrice).toFixed(2)}
                    </Typography>
                )
            }
        },
        {
            field: 'grossAmount',
            headerName: "Különbség bruttó",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography>
                        {params.row.difference > 0 ? translate("inventory.surplus") : params.row.difference === 0 ? "" : translate("inventory.shortage")} {(params.row.difference * params.row.grossPrice).toFixed(2)}
                    </Typography>
                )
            }
        },
    ];

    return (
        <div>
            <Autocomplete
                sx={{width: 300, height: 50, marginBottom: 3}}
                getOptionLabel={option => option.name || ""}
                renderInput={(params) => <TextField {...params} label={translate("inventory.switchWarehouse")}/>}
                options={warehouses}
                onChange={(event, value) => {
                    setSelectedWarehouse(value.id)
                    setValue("warehouseId", value.id)
                }}
                disableClearable
            />
            <FormControlLabel control={<Checkbox checked={isChecked} onChange={() => {
                setIsChecked(!isChecked)
            }}/>} label={translate("inventory.type")}/>
            <TextField name={"comment"} label={translate("inventory.comment")} fullWidth multiline
                       inputProps={{maxLength: 1000}}
                       onChange={(event) => {
                           setValue("comment", event.target.value)
                       }}
            />

            {rows.length > 0 &&
                <>
                    <TextField label={translate("inventory.search")} sx={{marginBottom: 5}} onChange={
                        (event) => {
                            setFilterText(event.target.value)
                        }}/>
                    <ThemeProvider theme={muiDatagridTheme}>
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            getRowId={(row) => row.warehouseProductId}
                            autoHeight
                            rowSelection={false}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: 0, pageSize: 10},
                                },
                            }}
                            pageSizeOptions={[10, 20, 50, 100]}
                            getCellClassName={(params) => {
                                if (params.row.difference > 0  && (params.field === 'feDifference' || params.field === 'netAmount' || params.field === 'grossAmount')) {
                                    return 'surplus'
                                } else if (params.row.difference < 0  && (params.field === 'feDifference' || params.field === 'netAmount' || params.field === 'grossAmount')) {
                                    return 'shortage'
                                }
                                return ''
                            }}
                        />
                    </ThemeProvider>
                </>}

        </div>
    )
}

export default InventoryDetails