import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {useState} from "react";
import Purchase from "./Purchase";
import {useTranslate} from "react-admin";
import Summary from "./Summary";
const SimpleSteps = ({Resource}) => {
    const translate = useTranslate()
    const [activeStep, setActiveStep] = useState(0)
    const steps = [
        {
            label: translate("purchase.step1"),
            content: (
                <Purchase Resource={Resource} setActiveStep={setActiveStep}/>
            ),
        },

        {
            label: translate("purchase.step2"),
            content: (
                <Summary setActiveStep={setActiveStep}/>
            ),
        }
    ]

    return (
        <>
                <Stepper sx={{width: '600px', marginX: 'auto', marginTop: '20px'}} activeStep={activeStep}>
                    {steps.map((step, index) => {
                        return (
                            <Step key={step.label}>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {steps[activeStep].content}
        </>
    );
};

export default SimpleSteps;