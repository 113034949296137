import React from 'react';
import {
    ArrayInput,
    FormDataConsumer,
    SaveButton,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    Title,
    Toolbar,
    useDataProvider,
    useNotify, useRedirect, useTranslate,
    required, email
} from 'react-admin';
import {PermissionDatagrid} from "../../components/PermissionDatagrid";
import {Typography} from "@mui/material";


export const UserInviteCreate = () => {
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const redirect = useRedirect()
    const emailValidator = [required(), email()];


    const handleSave = (values) => {
        let priv = []
        values.updatedPrivileges.map(obj => {
            if(obj.posSetsAdmin.length !== 0 && obj.privilegeIdsAdmin.length !== 0) { priv = priv.concat(obj) }
            return obj
        })
        if(priv.length !== 0) {
            dataProvider.create(`invitation`, {data: {email: values.email, updatedPrivileges: priv}})
                .then(value => {
                    notify("profile.inviteSuccessfullySend", {type: "success"})
                    redirect("list", "user")
                })
                .catch(reason => {
                    console.log(reason)
                    notify("profile.savingError", {type: "error"})
                })
        }else {
            notify("profile.noSelectedPosSetOrPrivilege", {type: "error"})
        }

    };

    const ToolbarForPosSets = () => {
        return(
        <Toolbar>
            <SaveButton label="profile.inviteButtonLabel" style={{color: "#fff"}}/>
        </Toolbar>
        );
    }

    return (
        <>
            <Title title="profile.inviteTitle" />
                    <SimpleForm toolbar={<ToolbarForPosSets />} onSubmit={handleSave}>
                        <TextInput
                            source={"email"}
                            label={"profile.username"}
                            sx={{width: "400px"}}
                            validate={(value) => {
                                const error = emailValidator.reduce((error, validator) => {
                                    return error || validator(value);
                                }, undefined);

                                if (!error && value) {
                                    const domainRegex = /\.([a-zA-Z]+)$/;
                                    const domainMatch = value.match(domainRegex);

                                    if (!domainMatch) {
                                        return 'Invalid email domain. Please include a valid domain.';
                                    }
                                }

                                return error;
                            }}
                        />

                        <ArrayInput source={"updatedPrivileges"} label={"technicalUsers.technicalUserRole"}>
                            <SimpleFormIterator disableReordering={true}>
                                <FormDataConsumer>
                                    {({scopedFormData, formData}) => {
                                        return(
                                            <div>
                                                <Typography variant={"h5"} style={{ width: "300px"}}>{translate("technicalUsers.privilegesAdmin")} </Typography>
                                                <PermissionDatagrid propAdmin={true} scopedFormData={scopedFormData} resource={"invitation"} />
                                                <Typography variant={"h5"} style={{ width: "300px"}}>{translate("technicalUsers.privilegesApi")} </Typography>
                                                <PermissionDatagrid propAdmin={false} scopedFormData={scopedFormData} resource={"invitation"} />
                                            </div>
                                        )
                                    }
                                    }
                                </FormDataConsumer>

                            </SimpleFormIterator>
                        </ArrayInput>

                    </SimpleForm>
        </>
    );
};

export default UserInviteCreate