import React, {useEffect, useState} from "react";
import {Button, useLocaleState} from "react-admin";
import {
    Box, Checkbox,
    FormControl, InputLabel, ListItemText, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {useTranslate} from "react-admin";
import Paper from "@mui/material/Paper";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {ThemeProvider} from "@mui/material/styles";
import {muiDatagridTheme} from "./MuiDatagridTheme";
import dataProvider from "./dataProvider";
import MenuItem from "@mui/material/MenuItem";
import {uuidv4} from "../tools/generate";

const MultipleWarehouseSelectWithBox = ({getLocales, getResource, posSetId, setTrigger, setSendData}) => {
    const [selectedWarehouses, setSelectedWarehouses] = useState([])
    const [selectedDate, setSelectedDate] = useState({
        start: getResource === "stockpile" ? dayjs().set('hour', 23).set('minute', 59) : dayjs().set('hour', 0).set('minute', 0),
        end: getResource === "stockpile" ? null : dayjs().set('hour', 23).set('minute', 59)
    })
    const [warehouses, setWarehouses] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [selectedSuppliers, setSelectedSuppliers] = useState([])
    const [all, setAll] = useState([])
    const translate = useTranslate()
    const locale = useLocaleState();

    useEffect(() => {
        setAll([...selectedWarehouses, ...selectedSuppliers])
        const allID = selectedSuppliers.concat(selectedWarehouses).map(item => item.id)
        setSendData((prevState) => ({
            ...prevState,
            selectedWarehouses: allID
        }))
    }, [selectedSuppliers, selectedWarehouses])

    useEffect(() => {
        setSelectedWarehouses([])
        if (posSetId != 0) {
            dataProvider.get(`${getResource}/warehouses`, {posSetId: posSetId})
                .then(({data}) => {
                    data.unshift({id: uuidv4(), name: translate(`${getLocales}.all`), all: true})
                    setWarehouses(data.map(item => {
                        item.type = "WAREHOUSE"
                        return item
                    }))
                    setSendData({
                        selectedWarehouses: [],
                        start: getResource === "stockpile" ? dayjs().set('hour', 23).set('minute', 59) : dayjs().set('hour', 0).set('minute', 0),
                        end: getResource === "stockpile" ? null : dayjs().set('hour', 23).set('minute', 59)
                    })
                    setSelectedDate({
                        start: getResource === "stockpile" ? dayjs().set('hour', 23).set('minute', 59) : dayjs().set('hour', 0).set('minute', 0),
                        end: getResource === "stockpile" ? null : dayjs().set('hour', 23).set('minute', 59)
                    })
                })
                .catch((error) => {
                    setWarehouses([])
                })
            dataProvider.get(`${getResource}/suppliers`, {posSetId: posSetId})
                .then(({data}) => {
                    data.unshift({id: uuidv4(), name: translate(`${getLocales}.all`), all: true})
                    setSuppliers(data.map(item=>{
                        item.type = "SUPPLIER"
                        return item
                    }))
                })
        }
    }, [posSetId])

    const handleDelete = (row) => {
        setAll(all.filter((item) => item.id !== row.id));
        row.type === "SUPPLIER" ?
            setSelectedSuppliers(selectedSuppliers.filter((item) => item.id !== row.id))
            :
            setSelectedWarehouses(selectedWarehouses.filter((item) => item.id !== row.id))
        setSendData((prevState) => ({
            ...prevState,
            selectedWarehouses: prevState.selectedWarehouses.filter((item) => item !== row.id)
        }))
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "WAREHOUSE") {
            if (value.some(v => v.all)) {
                if (selectedWarehouses.length === warehouses.length - 1) {
                    setSelectedWarehouses([]);
                } else {
                    setSelectedWarehouses(warehouses.filter(w => !w.all));
                }
            } else {
                setSelectedWarehouses(value);
            }
        } else if (name === "SUPPLIER") {
            if (value.some(v => v.all)) {
                if (selectedSuppliers.length === suppliers.length - 1) {
                    setSelectedSuppliers([]);
                } else {
                    setSelectedSuppliers(suppliers.filter(s => !s.all));
                }
            } else {
                setSelectedSuppliers(value);
            }
        }
    };

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 20,
                width: getLocales === 'stockReport' ? 1000 : 800
            }}>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id="warhouse">{translate(`${getLocales}.warehouse`)}</InputLabel>
                    <Select
                        name={"WAREHOUSE"}
                        variant={"standard"}
                        labelId="warhouse"
                        id="warehuse-multiple-checkbox"
                        multiple
                        value={selectedWarehouses}
                        onChange={handleChange}
                        renderValue={(selected) => selected.map((item) => item.name).join(', ')}
                        MenuProps={MenuProps}
                    >
                        {warehouses.map((warehouse) => (
                            <MenuItem key={warehouse.id} value={warehouse}>
                                <Checkbox checked={warehouse.all ? selectedWarehouses.length === warehouses.length - 1 : selectedWarehouses.includes(warehouse)}/>
                                <ListItemText primary={warehouse.name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{m: 1, width: 300}}>
                    <InputLabel id="suppliers">{translate(`${getLocales}.sup`)}</InputLabel>
                    <Select
                        variant={"standard"}
                        labelId="suppliers"
                        id="suppliers-multiple-checkbox"
                        multiple
                        name={"SUPPLIER"}
                        value={selectedSuppliers}
                        onChange={handleChange}
                        renderValue={(selected) => selected.map((item) => item.name).join(', ')}
                        MenuProps={MenuProps}
                    >
                        {suppliers.map((supplier) => (
                            <MenuItem key={supplier.id} value={supplier}>
                                <Checkbox checked={supplier.all ? selectedSuppliers.length === suppliers.length - 1 : selectedSuppliers.includes(supplier)}/>
                                <ListItemText primary={supplier.name}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {getLocales === "stockReport" ?
                    <>
                        <ThemeProvider theme={muiDatagridTheme}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale[0]}>
                                <DateTimePicker
                                    value={selectedDate.start}
                                    label={translate(`${getLocales}.dateStart`)}
                                    renderInput={(params) => <TextField {...params} />}
                                    maxDate={dayjs()}
                                    onChange={(newValue) => {
                                        setSelectedDate({...selectedDate, start: newValue})
                                        setSendData((prevState) => ({
                                            ...prevState,
                                            start: newValue
                                        }))
                                    }}
                                />
                            </LocalizationProvider>
                        </ThemeProvider>

                        <ThemeProvider theme={muiDatagridTheme}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale[0]}>
                                <DateTimePicker
                                    value={selectedDate.end}
                                    label={translate(`${getLocales}.dateEnd`)}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDateTime={selectedDate.start}
                                    maxDate={dayjs()}
                                    onChange={(newValue) => {
                                        setSelectedDate({...selectedDate, end: newValue})
                                        setSendData((prevState) => ({
                                            ...prevState,
                                            end: newValue
                                        }))
                                    }}
                                />
                            </LocalizationProvider>
                        </ThemeProvider>
                    </>
                    :
                    <ThemeProvider theme={muiDatagridTheme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale[0]}>
                            <DateTimePicker
                                value={selectedDate.start}
                                label={translate(`${getLocales}.date`)}
                                renderInput={(params) => <TextField {...params} />}
                                maxDate={dayjs()}
                                onChange={(newValue) => {
                                    setSelectedDate({...selectedDate, start: newValue})
                                    setSendData((prevState) => ({
                                        ...prevState,
                                        start: newValue
                                    }))
                                }}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                }
            </div>

            {all.length > 0 &&
                <Box sx={{
                    width: 1000,
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                    marginBottom: '20px'
                }}>
                    <TableContainer component={Paper} elevation={3}
                                    style={{maxHeight: 400, width: 800, overflowY: 'auto'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell sx={{width: "465px"}}>{translate(`${getLocales}.name`)}</TableCell>
                                    <TableCell>{translate(`${getLocales}.type`)}</TableCell>
                                    <TableCell>{translate(`${getLocales}.delete`)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {all.map((row) => (

                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.type === "WAREHOUSE" ? translate(`${getLocales}.wh`) : translate(`${getLocales}.supplier`)}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleDelete(row)}
                                            >
                                                {translate(`${getLocales}.delete`)}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {getLocales === "stockReport" ?
                        null :
                        <Button
                            disabled={all.length === 0 || !selectedDate.start}
                            sx={{width: 150, height: 50, marginTop: 'auto'}}
                            variant="contained"
                            color={"secondary"}
                            onClick={() => {
                                setTrigger(prevTrigger => !prevTrigger)
                            }}
                        >
                            {translate(`${getLocales}.create`)}
                        </Button>
                    }
                </Box>
            }

        </>
    );
}

export default MultipleWarehouseSelectWithBox;