import {Create, SimpleForm} from "react-admin";
import OperationsDetails from "./WarehouseOperationsDetails";
import {PinkToolbar} from "../../../components/PinkToolbar";
import React, {useState} from "react";

const OperationsCreate = ()=>{
    const [enableSave, setEnableSave] = useState(false)
    const [disposalPrivilege, setDisposalPrivilege] = useState(true)
    const [allItemsFilled, setAllItemsFilled] = useState(false)
    const transform = (data) => {
        localStorage.removeItem("importedWarehouseOperations")
        data.posSetId = localStorage.getItem("operationsPosSetId")
        return data
    }

    return(
        <Create redirect={"list"} transform={transform}>
            <SimpleForm toolbar={<PinkToolbar alwaysEnableSave={enableSave && disposalPrivilege && allItemsFilled} deleteEnabled={false}/>}>
            <OperationsDetails  setEnableSave={setEnableSave} setDisposalPrivilege={setDisposalPrivilege} setAllItemsFilled={setAllItemsFilled}/>
            </SimpleForm>
        </Create>
    )
}

export default OperationsCreate