import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    CircularProgress, Dialog, DialogContent, DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import {useNotify, useRedirect, useTranslate, useDataProvider, useLocaleState} from "react-admin";
import {Link} from "react-router-dom";
import simplePayLogo from '../../../assets/simplepay_w360.png'
import React, {useEffect, useState, useRef} from "react";
import bigDecimal from "js-big-decimal";
import dataProvider from "../../../components/dataProvider";
import SimpleBack from "./SimpleBack";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";


const Summary = ({setActiveStep}) => {

    const dataFromPrevStage = JSON.parse(localStorage.getItem('purchaseData'))
    const translate = useTranslate()
    const [email, setEmail] = useState(dataFromPrevStage.email)
    const [validEmail, setValidEmail] = useState(!!email)
    const [waiting, setWaiting] = useState(false)
    const [approve, setApprove] = useState(false)
    const [approveAszf, setApproveAszf] = useState(false)
    const [message, setMessage] = useState(null)
    const [paymentUrl, setPaymentUrl] = useState(null)
    const notify = useNotify()
    const redirect = useRedirect()
    const vat = new bigDecimal(1.27)
    const monthlyGrossPrice = new bigDecimal(dataFromPrevStage.monthlyNetPrice).multiply(vat)
    const maxTokenAmount = new bigDecimal(dataFromPrevStage.maxTokenAmount);
    const twiceMonthlyGrossPrice = monthlyGrossPrice.multiply(new bigDecimal(2));
    const token = maxTokenAmount.compareTo(monthlyGrossPrice) >= 0 ? maxTokenAmount : twiceMonthlyGrossPrice;
    const [invoice, setInvoice] = useState(null)
    const [open, setOpen] = useState(false)
    const provider = useDataProvider()
    const descriptionElementRef = useRef(null);
    const locale = useLocaleState()

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    useEffect(() => {
        dataProvider.get(`license`, {companyId: dataFromPrevStage.company.id})
            .then(res => {
                if (res.data.paymentUrl){
                    setPaymentUrl(res.data.paymentUrl)
                }else{
                    setPaymentUrl(null)
                }
            })
            .catch(reason => {
                console.log(reason)
            })
        dataProvider.get(`license/invoices`, {companyId: dataFromPrevStage.company.id, packageId: dataFromPrevStage.packageId, supportId: dataFromPrevStage.support.id, licenseCount: dataFromPrevStage.licenseCount, voucherCode: dataFromPrevStage.voucher})
            .then(res => {
               setInvoice(res.data)
            })
            .catch(reason => {
                console.log(reason)
            })
    }, []);
    const getQuery = async (orderId) => {
        try {
            return await dataProvider.get(`license/query`, {orderId: orderId})
        } catch (e) {
            console.log(e)
            return e
        }
    }
    const handleClose = () => {
        setOpen(false);
    };

    const handlePurchase = () => {
        setWaiting(true)
        const data = {
           email: email,
           companyId: dataFromPrevStage.company.id,
           licenseCount: dataFromPrevStage.licenseCount,
           netPrice: invoice[0].sumNetPrice,
           monthlyNetPrice: dataFromPrevStage.monthlyNetPrice,
           packageId: dataFromPrevStage.packageId,
           expirationDate: null,
           supportId: dataFromPrevStage.support.id,
           grossPrice: invoice.find(item => item.type === "NOW").sum,
           monthlyGrossPrice: monthlyGrossPrice.round(2, bigDecimal.RoundingModes.HALF_UP).getValue(),
           voucherCode: dataFromPrevStage.voucher,
           monthlyNetPriceNextXMonth: invoice.find(item => item.type === "NEXT_X_MONTH")?.sumNetPrice,
           monthlyGrossPriceNextXMonth: invoice.find(item => item.type === "NEXT_X_MONTH")?.sum,
       }

        provider.create(`license/simple`, {data})
            .then(res => {
                if (res.data.response.paymentUrl){
                    redirect(res.data.response.paymentUrl)
                }else{
                    if (res.data.response.orderId){
                        getQuery(res.data.response.orderId)
                        const interval = setInterval(async () => {
                            try {
                                const response = await getQuery(res.data.response.orderId)
                                if(response.data.transactions[0]){
                                    switch(response.data.transactions[0].status) {
                                        case ("FINISHED"):
                                            clearInterval(interval)
                                            setMessage(response.data.transactions[0])
                                            break;
                                        case ("FRAUD"):
                                            clearInterval(interval)
                                            setMessage(response.data.transactions[0])
                                            break;
                                        case ("TIMEOUT"):
                                            clearInterval(interval)
                                            setMessage(response.data.transactions[0])
                                            break;
                                        case ("CANCELLED"):
                                            clearInterval(interval)
                                            setMessage(response.data.transactions[0])
                                            break;
                                        case ("NOTAUTHORIZED"):
                                            clearInterval(interval)
                                            setMessage(response.data.transactions[0])
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            }
                            catch (e) {
                                notify(translate("summary.error"), {type: "error"})
                                setWaiting(false)
                            }

                        }, 5000)
                        return () => clearInterval(interval)
                    }
                    if(res.data.response.paymentPath === "FREE"){
                        notify('Sikeres csomagváltás', {type: "success"})
                        setTimeout(() => {
                            redirect('/dashboard')
                        }, 1500)
                    }

                }
                setWaiting(false)
            })
            .catch(err => {
                notify(err.message, {type: "error"})
                setWaiting(false)
            })
    }

    const validateEmail = (e) => {
        setEmail(e.target.value)
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
            setValidEmail(true)
        }
        else {
            setValidEmail(false)
        }
    }

    return (
        message ?
            <SimpleBack message={message} />
            : paymentUrl ?
                <Card sx={{ width: 450, marginTop: "40px" }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                            {translate("purchase.purchaseInProgress")}
                        </Typography>
                        <br/>
                        <Typography sx={{fontSize: 18}} variant="body2">
                            {translate(`purchase.continueOnSimple`)}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button color="primary" onClick={() => redirect(paymentUrl)}>{translate("purchase.continueOnSimpleButton")}</Button>
                    </CardActions>
                </Card>
            :
        <Box sx={{width: '100%'}}>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{translate("summary.title")}</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {invoice?.map((item, index) => {
                            return (
                                <Box key={index} sx={{width: 550}} >
                                    <Typography variant="h8" gutterBottom component="div">
                                        {locale[0] === 'hu' ?
                                            item.type === "NOW" ? "Mostani befizetés" : item.type === "AFTER_X_MONTH" ? "Havi befizetés" : `Következő ${item.numberOfMonths} havi befizetés`
                                            :
                                         item.type
                                        }
                                    </Typography>
                                    {item.invoiceItems.map((elem, i) => {
                                        return (
                                            <Box key={i} sx={{}}>
                                                <Typography variant="h8" gutterBottom component="div">
                                                    {elem.name}
                                                </Typography>
                                                <Typography variant="h8" gutterBottom component="div">
                                                    {elem.count} x {elem.netPrice} EUR + ÁFA = {elem.sum} EUR
                                                </Typography>
                                            </Box>
                                        )
                                    })}
                                    <Typography variant="h8" gutterBottom component="div">
                                        {item.sum} EUR
                                    </Typography>
                                    <hr/>
                                </Box>
                            )
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
            <Box sx={{width: '80%', marginX: 'auto'}}>
                <Typography variant="h6" gutterBottom component="div" sx={{marginTop: '20px'}}>
                    {translate("summary.title")}
                </Typography>
                <hr/>
                <Box sx={{display: 'flex', flexDirection: 'column' , justifyContent: 'space-between', marginTop: '20px', height:'350px'}}>
                    <Typography variant="h6" gutterBottom component="div" sx={{marginBottom: "-10px"}}>
                        {translate("summary.company")}: {dataFromPrevStage.company.name}
                    </Typography>
                    <Box sx={{display: 'flex', alignItems: 'end'}}>
                        <TextField sx={{width: '300px'}}
                            error={!validEmail}
                            label={translate("summary.email")}
                            variant={"standard"}
                            type={"email"}
                            value={email}
                            onChange={validateEmail}
                            required={true}
                        />
                        <Typography sx={{marginLeft: '20px', fontSize: '11px'}}>
                            {translate("summary.reqMail")}
                        </Typography>
                    </Box>
                <Typography variant="h8" gutterBottom component="div">
                    {translate("summary.gross")}
                    {invoice?.find(item => item.type === "NOW")?.sum} EUR
                </Typography>
                <Box sx={{display: 'flex', justifyContent: "space-between"}}>
                    <Typography variant="h8" gutterBottom component="div">
                        {translate("summary.monthly")}
                        {invoice?.find(item => item.type === "AFTER_X_MONTH")?.sum} EUR
                    </Typography>
                    <Button sx={{color: "#ffffff"}} variant="contained" onClick={()=>{setOpen(!open)}}>{translate("summary.detailed")}</Button>
                </Box>
                <Typography variant="h8" gutterBottom component="div">
                    {translate("summary.max")} {token.round(2, bigDecimal.RoundingModes.HALF_UP).getValue()} EUR
                </Typography>

                <Typography variant="h8" gutterBottom component="div">
                    {translate("summary.summary")} {dataFromPrevStage.monthlyNetPrice <= 0 ? 0 : monthlyGrossPrice.round(2, bigDecimal.RoundingModes.HALF_UP).getValue()} {translate("summary.summary2")}
                </Typography>

                </Box>

                <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                    <Checkbox value={approve} onChange={()=>{setApprove(!approve)}} sx={{height: '70px'}}/>
                    <Typography variant="h8" gutterBottom component="div">
                        {translate("summary.simpleAszf")} <Link to={'http://simplepay.hu/vasarlo-aff'}
                                                                target="_blank"> {translate("summary.simpleLink")} </Link>
                    </Typography>
                </Box>

                <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                    <Checkbox value={approveAszf} onChange={()=>{setApproveAszf(!approveAszf)}} sx={{height: '70px'}}/>
                    <Typography variant="h8" gutterBottom component="div">
                        {translate("summary.pinkAszf")} <Link to={'http://simplepay.hu/vasarlo-aff'}
                                                                target="_blank"> {translate("summary.simpleLink")} </Link>
                    </Typography>
                </Box>

                <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                    <Box sx={{display: 'flex'}}>
                    <Button onClick={() => setActiveStep(0)} disabled={waiting}>{translate("summary.back")}</Button>
                    <Button onClick={handlePurchase} disabled={(!validEmail || !approve || !approveAszf)|| waiting}>{dataFromPrevStage.netPrice > 0 ? translate("summary.pay") : translate("summary.finalization")}</Button>
                    {waiting && <CircularProgress/>}
                    </Box>
                    <Link to={'http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf'} target="_blank"> <img
                        src={simplePayLogo} title=" SimplePay - Online bankkártyás fizetés"
                        alt=" SimplePay vásárlói tájékoztató"/> </Link>
                </Box>
            </Box>
        </Box>
    )
}

export default Summary