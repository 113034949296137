import Dialog from "@mui/material/Dialog"
import { Button, DialogTitle, IconButton } from "@mui/material"
import DialogContent from "@mui/material/DialogContent"
import React, { useRef, useState } from "react"
import { SimpleForm, useDataProvider, useNotify, useTranslate } from "react-admin"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import * as XLSX from "xlsx"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import { uuidv4 } from "../../../tools/generate";


export const SuppliersUploadDialog = (props) => {
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const inputRef = useRef(null)
    const [excelFile, setExcelFile] = useState(null)
    const [fileName, setFileName] = useState("")


    const handleFileChange = (e) => {
        let fileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']
        let selectedFile = e.target.files[0]
        setFileName(selectedFile.name)
        if (selectedFile && fileTypes.includes(selectedFile.type)) {
            let reader = new FileReader()
            reader.readAsArrayBuffer(selectedFile)
            reader.onload = (e) => {
                setExcelFile(e.target.result)
            }
        } else {
            setExcelFile(null)
            notify("products.fileExtensionError", { type: "error" })
        }
    }

    const handleFileSubmit = () => {
        if (props.mode === 'list') {
            importSuppliers()
        }
        else {
            importSupplierPriceList()
        }
    }

    const importSuppliers = () => {
        if (excelFile !== null) {
            const headers = [translate("suppliers.name"), translate("suppliers.address"), translate("suppliers.taxNumber"), translate("suppliers.phonePrefix"),
            translate("suppliers.phone"), translate("suppliers.email"), translate("suppliers.contact")]
            const workbook = XLSX.read(excelFile, { type: 'array' })
            const worksheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[worksheetName]
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
            const excelData = data.filter(item => Object.keys(item).length >= 1)
            const importedHeaders = excelData[0]
            for (let i = 0; i < headers.length; i++) {
                if (headers[i] !== importedHeaders[i]) {
                    notify(translate("suppliers.wrongImportedFile"), { type: 'error' })
                    return;
                }
            }
            const suppliers = excelData.map(prop => ({
                name: prop[0],
                posSetId: props.selectedPosSetId,
                address: prop[1],
                taxNumber: prop[2],
                phonePrefix: prop[3],
                phone: prop[4],
                email: prop[5],
                contact: prop[6],
                inPriceListItems: [],
                outPriceListItems: []
            })).slice(1)
            dataProvider.create("supplier/saveSuppliers", { data: suppliers }).then(value => {
                notify("suppliers.saveSuccess", { type: "success" })
                closeDialog()
            })
                .catch(reason => notify("suppliers.saveError", { type: "error" }))
        } else {
            closeDialog()
        }
    }

    const importSupplierPriceList = () => {
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, { type: 'array' })
            const worksheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[worksheetName]
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
            const excelData = data.filter(item => Object.keys(item).length >= 1).slice(2)
            let suppliersInArray = []
            let suppliersOutArray = []
            let products = props.products
            excelData.forEach(array => {
                let firstPart = array.slice(0, 5).slice(0, 4)
                let secondPart = array.slice(5).slice(0, 4)

                let firstObject = {
                    id: firstPart[0],
                    name: firstPart[1],
                    unit: firstPart[2],
                    netPrice: firstPart[3]
                }

                let secondObject = {
                    id: secondPart[0],
                    name: secondPart[1],
                    unit: secondPart[2],
                    netPrice: secondPart[3]
                }

                suppliersInArray.push(firstObject)
                suppliersOutArray.push(secondObject)
            });
            const suppliersIn = suppliersInArray.map((item) => {
                let product = products.find(product => product.id === item.id)
                let unitId = product?.units.find(unit => unit.unitName === item.unit).id
                if (product === undefined) {
                    return null;
                }
                return {
                    feId: Date.now(),
                    warehouseProductId: item.id,
                    netPrice: item.netPrice,
                    tab: "in",
                    warehouseProduct: product,
                    unit: product?.units.find(unit => unit.unitName === item.unit),
                    unitId: unitId.includes('unit-') ? unitId.replace('unit-', '') : null,
                    warehouseProductUnitId: unitId.includes('unit-') ? null : unitId
                }
            }).filter(item => item !== null); 
            const suppliersOut = suppliersOutArray.map((item) => {
                let product = products.find(product => product.id === item.id)
                let unitId = product?.units.find(unit => unit.unitName === item.unit).id
                if (product === undefined) {
                    return null;
                }
                return {
                    feId: Date.now(),
                    warehouseProductId: item.id,
                    netPrice: item.netPrice,
                    tab: "out",
                    warehouseProduct: product,
                    unit: product?.units.find(unit => unit.unitName === item.unit),
                    unitId: unitId.includes('unit-') ? unitId.replace('unit-', '') : null,
                    warehouseProductUnitId: unitId.includes('unit-') ? null : unitId
                }
            }).filter(item => item !== null); 
            props.setSupplierIn(prevState => {
                return prevState.concat(suppliersIn)
            })
            props.setSupplierOut(prevState => {
                return prevState.concat(suppliersOut)
            })
            closeDialog()
        } else {
            closeDialog()
        }
    }

    const closeDialog = () => {
        setExcelFile(null)
        setFileName(null)
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
            <IconButton
                aria-label="close"
                onClick={() => closeDialog()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle>{translate("suppliers.import")}</DialogTitle>
            <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                <SimpleForm toolbar={false} onSubmit={handleFileSubmit}>
                    <Button style={{ color: "white", width: "200px" }} variant={"contained"} startIcon={<AddIcon />}
                        onClick={() => (inputRef.current?.click())}>{translate("products.fileAdd")}</Button>
                    <input ref={inputRef} type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                    <div style={{ marginBottom: "20px" }}>{excelFile && fileName}</div>
                    <Button startIcon={<UploadFileIcon />} style={{ color: "white", width: "200px" }} variant={"contained"}
                        disabled={!excelFile} type={'submit'}>{translate("products.fileUpload")}</Button>
                </SimpleForm>
            </DialogContent>
        </Dialog>
    )
}

