import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import {
    List,
    TextField,
    DatagridConfigurable,
    usePermissions, Pagination
} from "react-admin";
import {EmptyPage} from "../../components/EmtpyPage";
import {PinkActions} from "../../components/PinkActions";
import {PhoneField} from "../../components/PhoneField";



export const CompanyList = () => {
    const { permissions } = usePermissions()
    const hasEdit = getValidPrivilegesIds("COMPANY", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    return(
        <List pagination={<Pagination rowsPerPageOptions={[10, 50, 100, 500]} />} actions={<PinkActions hasCreate={true}/>} title={"company.title"} empty={<EmptyPage text={"company.empty"} createEnabled={true} />}>
            <DatagridConfigurable size="small"  omit={['id', 'euTaxNumber', 'companyRegistrationNumber', 'contactPhone']} rowClick={hasEdit} bulkActionButtons={false} >
                <TextField source="id" label={'company.id'} />
                <TextField source="name" label={'company.name'} />
                <TextField source="zipCode" label={'company.zipCode'} />
                <TextField source="state" label={'company.state'} />
                <TextField source="settlement" label={'company.settlement'} />
                <TextField source="address" label={'company.address'} />
                <TextField source="taxNumber" label={'company.taxNumber'} />
                <TextField source="groupTaxNumber" label={'company.groupTaxNumber'} />
                <TextField source="euTaxNumber" label={'company.euTaxNumber'} />
                <TextField source="companyRegistrationNumber" label={'company.companyRegistrationNumber'} />
                <TextField source="contactName" label={'company.contactName'} />
                <TextField source="contactEmail" label={'company.contactEmail'} />
                <PhoneField source={"contactPhone"} label={"company.contactPhone"} />
            </DatagridConfigurable>
        </List>
    )
}