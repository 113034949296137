import {Create, SimpleForm} from "react-admin"
import SuppliersDetails from "./SuppliersDetails";
import {PinkToolbar} from "../../../components/PinkToolbar";
import React, {useState} from "react";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";
const SuppliersCreate = ()=> {
    const [saveButton, setSaveButton] = useState(false)
    const transform = (data) => {
        data.posSetId = localStorage.getItem("supplierPosSetId")
        if(data.phonePrefix != null && data.phone == null){
            data.phonePrefix = null
        }
        return data
    }

    return (
        <>
        <Create redirect={"list"} transform={transform}>
            <SimpleForm toolbar={<PinkToolbar disabled={!saveButton} deleteEnabled={false}/>}>
                <SuppliersDetails setSaveButton={setSaveButton}/>
            </SimpleForm>
        </Create>
    <img src={shorticLogo}
         style={{
             position: "relative",
             bottom: "0",
             left: "20",
             width: "150px",
             paddingBottom: '10px',
             paddingTop: '10px'
         }}/>
        </>

)
}

export default SuppliersCreate