import {
    Create,
    SimpleForm
} from "react-admin"
import {QuickMenu} from "./QuickMenu";
import {PinkToolbar} from "../../components/PinkToolbar";
import {useState} from "react";
const quickMenuTransform = (data) => {
    return {
        priceListId: data.priceListInstanceId ? data.priceListInstanceId : data.priceListId,
        width: data.width,
        height: data.height,
        quickMenu: data.layouts ? data.layouts.map(it => {
            const product = data.products.filter(product => it.i === product.i)
            return {id: product[0].id, x: it.x, y: it.y, width: it.w, height: it.h, color: product[0].color}
        }) : []
    }
}

export const QuickMenuCreate = () => {
    const [enableSave, setEnableSave] = useState(false)
    return (
    <Create transform={quickMenuTransform}>
        <SimpleForm toolbar={<PinkToolbar alwaysEnableSave={enableSave} deleteEnabled={false} />}>
            <QuickMenu setEnableSave={setEnableSave} />
        </SimpleForm>
    </Create>
    )
}