import {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import dataProvider from "../../../components/dataProvider";

const WarehouseProductNameInput = ({getResource, ingredient, setUnitComponents, setUnits, posSetId, setInputUnits}) => {
    const [inputValue, setInputValue] = useState("")
    const [warehouseProducts, setWarehouseProducts] = useState([])
    useEffect(() => {
        dataProvider.get(`product/${getResource}`, {posSetId: posSetId})
            .then(value => {
                let filteredWarehouseProducts = value.data.filter(item => item.activeFlag === true)

                const temp = value.data.find((warehouseProduct) => {
                    return warehouseProduct.id === ingredient.warehouseProductId
                })
                if (temp && !temp.activeFlag) {
                    setWarehouseProducts([...filteredWarehouseProducts, temp]);
                } else {
                    setWarehouseProducts(filteredWarehouseProducts);
                }
                setInputValue(temp || "");
            })
            .catch(reason => {
                setWarehouseProducts([])
            })
    }, []);
    const handleAutocompleteChange = (event, newValue) => {
        setUnits(newValue.units)
        setInputValue(newValue)
        setInputUnits({})
        setUnitComponents((prevUnitComponents) => {
            return prevUnitComponents.map((prevUnitComponent) => {
                if (prevUnitComponent.id ? prevUnitComponent.id === ingredient.id : prevUnitComponent.feId === ingredient.feId) {
                    return {...prevUnitComponent, warehouseProductUnitId: null, warehouseProductId: newValue.id, modifiers: []}
                }
                return prevUnitComponent
            })
        })
    };
    return (
        <Autocomplete
            getOptionLabel={option => option.name || ""}
            value={inputValue}
            onChange={handleAutocompleteChange}
            renderInput={(params) => <TextField {...params} label="Raktár tétel"/>}
            options={warehouseProducts}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            disableClearable
        />
    )
}

export default WarehouseProductNameInput