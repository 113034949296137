import {AutocompleteInput, SimpleForm, TextInput, required, BooleanInput} from "react-admin";
import {PinkToolbar} from "../../../components/PinkToolbar";
import {countyCodes} from "../../../components/CountyCodes";


const WarehouseStorageDetails = ()=>{
    return(
        <SimpleForm toolbar={<PinkToolbar deleteEnabled={false}/>}>
            <TextInput label={"storage.name"} source={"name"} name={"name"} validate={required()} />
            <TextInput label={"storage.address"} source={"address"} name={"address"}/>
            <TextInput label={"storage.contact"} source={"contact"} name={"contact"}/>
            <TextInput label={"storage.email"} source={"email"} name={"email"}/>
            <div style={{display: "flex"}}>
                <AutocompleteInput style={{marginRight: "8px"}} label={'storage.phonePrefix'} source={"phonePrefix"} choices={countyCodes} optionValue={"dial_code"} name={"phonePrefix"}
                                   optionText={((record)=> {
                                       return `${record.dial_code} (${record.code})`
                                   })} defaultValue={"+36"} />
                <TextInput source="phone" inputProps={{maxLength: 20}} label={'storage.phone'} name={"phone"}/>
            </div>
            <BooleanInput name={"activeFlag"} source={"activeFlag"} label={"storage.isActive"} defaultValue={true}/>
            <BooleanInput name={"isLogistics"} source={"isLogistics"} label={"storage.isLogistics"}/>
        </SimpleForm>
    )
}

export default WarehouseStorageDetails