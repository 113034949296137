import * as React from "react";
import {
    Button, useDataProvider,
    useListContext, useTranslate, useUnselectAll,
} from 'react-admin';
import {useFormContext} from "react-hook-form";

export const ProductSelectBulkAction = ({current, setOpen}) => {
    const { selectedIds } = useListContext();
    const { setValue } = useFormContext();
    const translate = useTranslate()
    const unselectAll = useUnselectAll('priceList/products/byUnitPageable');
    const dataProvider = useDataProvider()



    const updateMany = () => {
        dataProvider.get("priceList/products/byUnit", {buId: localStorage.getItem("priceListPosSetId"), ids: [selectedIds]}).then(x => {
            x.data.map(data => {
                data.id = "x"+data.id
                data.name = data.product.name
                data.vatName = data.product.vatName
                data.isTakeawayAllowed = data.product.isTakeawayAllowed
                data.tags = data.product.tags
                data.categoryName = data.product.mainCategory
                data.subcategoryName = data.product.subCategory
                data.takeawayVatName = data.product.takeawayVatName
                delete data.product
                return data
            }) //add 'x' prefix because datagrid id column not possible null
            console.log(x.data)
            setValue('products', current.concat(x.data))

        })

        setOpen(false)
        unselectAll()
    }


    return (
        <Button
            label= {translate("priceListTemplate.addProductsToPriceList")}
            onClick={updateMany}
        />
    );
};