import {Edit, SimpleForm, useNotify} from "react-admin";
import {PinkToolbar} from "../../../components/PinkToolbar";
import React from "react";
import WarehouseProductDetails from "./WarehouseProductDetails";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";

const WarehouseProductEdit = (props) => {

    const notify = useNotify()

    const transform = (data) => {
        data.posSetId = parseInt(localStorage.getItem("warehouseProductPosSetId"))
        return data
    }

    const onError = (error) => {
        const errorMessageMap = {
            'duplicateUnitType' : 'warehouse.duplicateUnitType',
            'duplicateNameError': 'warehouse.duplicateNameError',
            'differentUnitError': 'warehouse.unitDifference',
            'Name cannot be one of the SI units': 'warehouse.siUnitError',
            'unitId and quantity can only be null if the other is also null': 'warehouse.unitAndQuantityError'
        };

        const defaultErrorMessage = 'warehouse.serverError';

        const errorMessageKey = errorMessageMap[error.message] || defaultErrorMessage;

        notify(errorMessageKey, { type: 'error', autoHideDuration: 3000 });
    };

    return (
        <>
        <Edit mutationOptions={{onError}} mutationMode="pessimistic" {...props} redirect={"list"} title={'warehouse.create'} transform={transform}>
            <SimpleForm toolbar={<PinkToolbar deleteEnabled={false} alwaysEnableSave={true} />}>
                <WarehouseProductDetails />
            </SimpleForm>
        </Edit>
            <img src={shorticLogo}
                 style={{position: "relative", bottom: "0", left: "20", width: "150px", paddingBottom: '10px', paddingTop: '10px'}}/>
        </>
    )
}

export default WarehouseProductEdit