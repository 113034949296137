import {Edit, SimpleForm} from "react-admin";
import SuppliersDetails from "./SuppliersDetails";
import {PinkToolbar} from "../../../components/PinkToolbar";
import React, {useState} from "react";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";

const SuppliersEdit = ()=> {
    const [saveButton, setSaveButton] = useState(false)
    return(
        <>
            <Edit redirect={"list"}>
                <SimpleForm toolbar={<PinkToolbar alwaysEnableSave={saveButton} deleteEnabled={false}/>}>
                    <SuppliersDetails setSaveButton={setSaveButton}/>
                </SimpleForm>
            </Edit>
            <img src={shorticLogo}
                 style={{
                     position: "relative",
                     bottom: "0",
                     left: "20",
                     width: "150px",
                     paddingBottom: '10px',
                     paddingTop: '10px'
                 }}/>
        </>
            )
            }

            export default SuppliersEdit