import React from "react";
import {Title} from "../components/Title";

export const groupTransform = (data) => {
    data.trees = Array.isArray(data.trees) ? data.trees : [data.trees]
    mapTree(data.trees)
    return data;
};

export function traverse_tree(root, setIds) {
    let nodes = [];

    // Push the current node onto the stack
    root = Array.isArray(root) ? root : [root]
        nodes = [...root]
    let ids = []

    // Loop while the stack is not empty
    while (nodes.length !== 0) {
        // Store the current node and pop
        // it from the stack
        let currentNode = nodes.pop();
        // Current node has been travarsed
        if (currentNode != null) {
            if(currentNode.children?.length > 0) {
                ids.push(currentNode?.id)
            }
            currentNode.name = currentNode.title
            currentNode.title =
                <Title
                    title={currentNode.title}
                    currentName={currentNode.priceListName || currentNode.warehouseName}
                    createdAt={currentNode.priceListCreatedAt || currentNode.posConfigValidFrom}
                />
            // Store all the childrent of
            // current node from right to left.
            for(var i = currentNode.children?.length - 1; i >= 0; i--)
            {
                nodes.push(currentNode.children[i]);
            }
        }
    }
    setIds(ids)
}

export function mapTree(root) {
    // Stack to store the nodes
    let nodes = [];
    nodes = [...root]

    // Loop while the stack is not empty
    while (nodes.length !== 0) {
        // Store the current node and pop
        // it from the stack
        let currentNode = nodes.pop();

        // Current node has been travarsed
        if (currentNode != null) {
            currentNode.title = currentNode.name
            // Store all the childrent of
            // current node from right to left.
            if(currentNode.children && currentNode.children.length > 0) {
                for (let i = 0; i < currentNode.children.length; i++) {
                    currentNode.children[i].orderId = i
                    nodes.push(currentNode.children[i]);
                }
            }
        }
    }
}