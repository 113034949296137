import {Edit} from "react-admin";
import WarehouseStorageDetails from "./WarehouseStorageDetails";
import shorticLogo from "../../../assets/Shortic_logo_high_resolution.png";
import React from "react";

const WarehouseStorageEdit = () => {
    return (
        <>
            <Edit redirect={"list"}>
                <WarehouseStorageDetails/>
            </Edit>
            <img src={shorticLogo}
                 style={{
                     position: "relative",
                     bottom: "0",
                     left: "20",
                     width: "150px",
                     paddingBottom: '10px',
                     paddingTop: '10px'
                 }}/>
        </>
            )
            }
            export default WarehouseStorageEdit