import React from 'react';
import {Create, SimpleForm} from 'react-admin';
import ProductDetails from "./ProductDetails";
import {PinkToolbar} from "../../components/PinkToolbar";

const ProductCreate = (props) => {
    const transform = (data) => {
        data.posSetId = localStorage.getItem("productPosSetId")
        return data
    }

    return (
        <Create {...props} redirect={"list"} title={'products.create'} transform={transform}>
            <SimpleForm toolbar={<PinkToolbar deleteEnabled={false} alwaysEnableSave={true}/>}>
                <ProductDetails/>
            </SimpleForm>
        </Create>
    )
}

export default ProductCreate