import {
    FormTab, TabbedForm,
    TextInput, useTranslate, useNotify,
    useRecordContext, BooleanInput,
    required, AutocompleteInput, regex, useDataProvider, useRefresh
} from "react-admin";
import React, {useState, useRef, useEffect} from "react";
import {PinkToolbar} from "../../components/PinkToolbar";
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import {Button, CircularProgress, TextField, Typography} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export const BusinessUnitDetails = () => {
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const [data, setData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const record = useRecordContext()
    const translate = useTranslate()
    const [ntakNumber, setNtakNumber] = useState("")
    const [cerFile, setCerFile] = useState()
    const [csrFile, setCsrFile] = useState(undefined)
    const inputRef = useRef(null)
    const notify = useNotify()
    const [isLoading, setLoading] = useState(false)
    useEffect(() => {
        if (!data.loading && !data.loaded) {
            setData({...data, loading: true});
            dataProvider.get(record ? 'businessUnit/allowed-companies-for-bu' : "businessUnit/bu-create", {buId: record?.id})
                .then(value => {
                    setData({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [data.loading, data.loaded]);

    useEffect(() => {
        if(record) {
            setNtakNumber(record.ntakRegNumber)
        }
    }, [])

    const handleChangeNTAKNumber = e => {
        setNtakNumber(e.target.value)
    }

    const handleCSRGenerate = props => {
        setLoading(true)
        dataProvider.get(`businessUnit/cert/${record.id}/${ntakNumber}`)
            .then(response => {
                const contentType = "application/pkcs10"
                const base64Data = response.data.body
                const fileName = response.data.name
                const linkSource = `data:${contentType};base64,${base64Data}`
                setCsrFile({file:linkSource, fileName: fileName})
                setLoading(false)
            })
            .catch(reason => {
                console.log(reason)
                notify("businessUnits.csrGenerateError", {type: "error"})
                setLoading(false)
            })
    }

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
        })
    }
    const handleCertificateUpload = () => {
        if (!cerFile) {
            notify("businessUnits.fileUploadError", {type: "error"})
        }else {
            getBase64(cerFile)
                .then(result => {
                    dataProvider.create(`businessUnit/cert`, {
                        data: {
                            id: record.id,
                            ntakRegNumber: ntakNumber,
                            fileName: cerFile.name,
                            fileBody: result
                        }
                    })
                        .then(value => {
                            notify("businessUnits.fileUploadSuccess", {type: "success"})
                        })
                        .catch(reason => {
                            console.log(reason)
                            notify("businessUnits.fileUploadError", {type: "error"})

                        })
                })
        }
    }

    const handleFileChange = e => {
        if (!e.target.files || e.target.files[0] === undefined) {
            notify("businessUnits.fileSelectError", {type: "error"})
        }else {
            setCerFile(e.target.files[0])
        }
    }

    return (
        <TabbedForm toolbar={<PinkToolbar deleteEnabled={false} />} mode="onBlur" reValidateMode="onBlur">
            <TabbedForm.Tab label="businessUnits.generalData">
                {(record?.hasOpenWorkday && data.data) ?
                    <>
                        <TextField id="company" label={translate("businessUnits.company")} variant="filled"
                                   value={data.data?.find(element => element.id === record.companyId)?.name}
                                   InputLabelProps={{ shrink: true }} disabled={true} fullWidth={true}
                        />
                        <Typography style={{ marginLeft: "16px", marginTop: "0px", marginBottom: "10px"}} variant={"h6"}>{translate("businessUnits.companyChangeHint")}</Typography>
                     </>:
                    <AutocompleteInput
                        source="companyId"
                        choices={data.data}
                        optionText={((record)=> { return record.name})}
                        fullWidth={true}
                        label={"businessUnits.company"}
                        validate={required()}
                    />}
                <TextInput source="name" label={"businessUnits.name"} inputProps={{maxLength: 255}} validate={required()} />
                <TextInput source="zipCode" label={"businessUnits.zipCode"} type={"number"} inputProps={{maxLength: 10}} validate={[regex(/^[1-9]\d{3,9}$/, "businessUnits.wrongZipCodeFormat"), required()]}/>
                <TextInput source="settlement" label={"businessUnits.settlement"} inputProps={{maxLength: 50}} validate={required()}/>
                <TextInput source="publicDomainName" label={"businessUnits.publicDomainName"} inputProps={{maxLength: 255}} validate={required()}/>
                <TextInput source="publicDomainType" label={"businessUnits.publicDomainType"} inputProps={{maxLength: 50}} validate={required()}/>
                <TextInput source="number" label={"businessUnits.number"} inputProps={{maxLength: 10}} validate={required()}/>
                <BooleanInput name={'simplifiedFlag'} source={'simplifiedFlag'} label={"businessUnits.isSimplified"} />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="businessUnits.ntakData">
                <TextInput source="ntakRegNumber" label={"businessUnits.ntakNumber"} inputProps={{maxLength: 10}} onChange={handleChangeNTAKNumber} />
                    <BooleanInput source="ntakDataProvision" label={"businessUnits.ntakDataProvision"} />
                {(record && !record?.ntakChangeEnabled) ?
                    <Typography style={{ marginLeft: "16px", marginTop: "-20px", marginBottom: "10px"}} variant={"h6"}>{translate("businessUnits.ntakChangeHint")}</Typography> : null}
                { !record ?
                    <Typography style={{ marginLeft: "16px", marginTop: "10px", marginBottom: "10px"}} variant={"h6"}>{translate("businessUnits.ntakCsrHint")}</Typography> : null}
                <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} disabled={((ntakNumber || "").length < 8 || isLoading || !record )} onClick={handleCSRGenerate} size={"medium"}><DownloadIcon /> {translate("businessUnits.generateCSR")}</Button>
                { csrFile ?
                    <>
                        <Typography style={{ marginLeft: "16px"}} variant={"h6"}>{csrFile.fileName}</Typography>
                        <a download={csrFile.fileName} href={csrFile.file} style={{textDecoration: "none", marginLeft: "16px"}}>
                            <Button variant={"contained"} style={{color: "white", marginBottom: "10px"}} color={"primary"} onClick={refresh}>
                                <FileDownloadIcon />   {translate('businessUnits.download')}
                            </Button>
                        </a>
                    </>
                    :
                    null
                }
                { isLoading ?
                    <>
                        <CircularProgress /> <Typography style={{ marginLeft: "16px"}} variant={"h6"}>{translate("businessUnits.loading")}</Typography>
                    </>
                    :
                    null
                }
                {(record && record?.ntakCertExpired != null) ?
                    <Typography style={{ marginLeft: "16px", marginTop: "10px", marginBottom: "10px"}} variant={"h6"}>{translate("businessUnits.certExpired")} {record?.ntakCertExpired} </Typography> : null}
                <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} onClick={() => (inputRef.current?.click())} size={"medium"} disabled={!record || record?.ntakCertUploadDisabled}><AddIcon /> {translate("businessUnits.certAdd")}</Button>
                    <input ref={inputRef} type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                    <div>{cerFile && `${cerFile.name} - ${cerFile.type}`}</div>
                {cerFile ?
                        <>
                            <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} onClick={handleCertificateUpload} size={"medium"} disabled={!record || record?.ntakCertUploadDisabled}><UploadFileIcon /> {translate("businessUnits.certUpload")}</Button>
                        </>
                    :
                        null
                }
                <Typography style={{ marginLeft: "16px", marginBottom: "10px"}} variant={"h6"}>{translate("businessUnits.certHint")}</Typography>
            </TabbedForm.Tab>
        </TabbedForm>
    )
}