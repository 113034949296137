import {useEffect, useState} from "react";
import {IconButton, TextField, Typography} from "@mui/material";
import {DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import {ThemeProvider} from "@mui/material/styles";
import {muiDatagridTheme} from "../../../components/MuiDatagridTheme";
import dataProvider from "../../../components/dataProvider";
import "./datagrid.css"
import {useTranslate} from "react-admin";

const Modifiers = ({posSetId, index, num, ingredient, setUnitComponents}) => {
    const [warehouseProducts, setWarehouseProducts] = useState([])
    const [warehouseProductsOptions, setWarehouseProductsOptions] = useState([])
    const [tags, setTags] = useState([])
    const translate = useTranslate()
    const [selectedModifiers, setSelectedModifiers] = useState(ingredient.modifiers.length>0 ? ingredient.modifiers
        .filter((modifier) => modifier.isActive)
        .map((modifier) => modifier.warehouseProductId) : []);

    useEffect(() => {
        if (ingredient.warehouseProductUnitId !== null && ingredient.warehouseProductId !== null) {
            dataProvider.get(`product/modifiers`, {
                warehouseProductUnitId: ingredient.warehouseProductUnitId,
                posSetId: posSetId,
                excludedId: ingredient.warehouseProductId
            })
                .then(value => {
                    const expandedWarehouseProducts = value.data.map((warehouseProduct) => {
                        return {warehouseProductId: warehouseProduct.id, name: warehouseProduct.name, tags: warehouseProduct.tags, units: warehouseProduct.units, grossPriceDifference: 0, isActive: false}
                    })
                    setWarehouseProducts(expandedWarehouseProducts)
                    if(ingredient.modifiers.length > 0) {
                        const filteredWarehouseProducts = expandedWarehouseProducts.filter((warehouseProduct) => {
                            return ingredient.modifiers.some((modifier) => {
                                return modifier.warehouseProductId !== warehouseProduct.warehouseProductId
                            })
                        })
                        setWarehouseProductsOptions(filteredWarehouseProducts)
                    }else {
                        setWarehouseProductsOptions(expandedWarehouseProducts)
                    }
                    })
                .catch(reason => {
                    setWarehouseProducts([])
                })
        }
        dataProvider.get(`product/warehouse-product-tags`, {posSetId: posSetId})
            .then(value => {
                setTags(value.data)
            }).catch(reason => {
            setTags([])
        })

    }, [ingredient.warehouseProductId, ingredient.warehouseProductUnitId]);
    const handleQuantityRowChange = (value, id) => {
        const newWarehouseProducts = ingredient.modifiers.map((warehouseProduct) => {
            if (warehouseProduct.warehouseProductId === id) {
                return {...warehouseProduct, grossPriceDifference: value}
            }
            return warehouseProduct
        })
        setUnitComponents((prevUnitComponents) => {
            return prevUnitComponents.map((prevUnitComponent) => {
                if (prevUnitComponent.id ? prevUnitComponent.id === ingredient.id : prevUnitComponent.feId === ingredient.feId) {
                    return {...prevUnitComponent, modifiers: newWarehouseProducts}
                }
                return prevUnitComponent
            })
        })
    }

    const columns = [
        {field: 'warehouseProductId', headerName: 'ID', width: 50, sortable: false},
        {
            field: 'modifier', headerName: translate("modifiers.modifier"), renderCell: (params) => {
                return <Typography>{`${params.row.name} `}</Typography>
            }, width: 160, sortable: false
        },

        {
            field: 'grossPriceDef', headerName: translate("modifiers.priceDifference"), renderCell: (params) => {
                return (
                    <TextField
                        key={params.row.warehouseProductId}
                        type="number"
                        value={params.row.grossPriceDifference}
                        onChange={(e) => {
                            const value = parseInt(e.target.value)
                            if (value >= 0 || e.target.value === "") {
                                handleQuantityRowChange(value, params.row.warehouseProductId)
                            }
                        }}
                    />

                )
            }, width: 150, sortable: false
        },

        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            renderHeader: (params) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{translate("modifiers.active")}</span>
                    {GRID_CHECKBOX_SELECTION_COL_DEF.renderHeader(params)}
                </div>
            ),
            width: 80,
        },

        {
            field: 'edit',
            headerName: translate("modifiers.delete"),
            width: 75,
            sortable: false,
            renderCell: (params) => {
                const handleRemoveRow = () => {
                    setWarehouseProductsOptions((prevWarehouseProducts) => {
                        return [...prevWarehouseProducts, params.row]
                    })
                    setUnitComponents((prevUnitComponents) => {
                        return prevUnitComponents.map((prevUnitComponent) => {
                            if (prevUnitComponent.id ? prevUnitComponent.id === ingredient.id : prevUnitComponent.feId === ingredient.feId) {
                                return {
                                    ...prevUnitComponent,
                                    modifiers: prevUnitComponent.modifiers.filter((modifier) => {
                                        return modifier.warehouseProductId !== params.row.warehouseProductId
                                    })
                                }
                            }
                            return prevUnitComponent
                        })
                    })
                }
                return (
                    <IconButton size={"small"} onClick={handleRemoveRow}>
                        <DeleteIcon sx={{color: "red"}}></DeleteIcon>
                    </IconButton>

                )
            }
        },
    ];

    return (
        <>
            <Typography variant={"subtitle1"}> {translate("modifiers.ingredient") + " " + (index + 1) +"/"+num} </Typography>
            <Autocomplete
                renderInput={params => <TextField {...params} label={translate("modifiers.tags")}/>}
                options={tags}
                getOptionLabel={option => option.name || ""}
                onChange={(event, newValue) => {
                    if(newValue !== null) {
                        setWarehouseProductsOptions((prevWarehouseProducts) => {
                        return prevWarehouseProducts.filter((warehouseProduct) => {
                            return warehouseProduct.tags.some((tag) => {
                                return tag.id === newValue.id
                            })
                        })
                    })}else {
                        setWarehouseProductsOptions(warehouseProducts)
                    }
                }}
            />
            <Autocomplete
                value={""}
                renderInput={params => <TextField {...params} label={translate("modifiers.product")}/>}
                options={warehouseProductsOptions}
                getOptionLabel={option => option.name || ""}
                onChange={(event, newValue) => {
                    setUnitComponents((prevUnitComponents) => {
                        return prevUnitComponents.map((prevUnitComponent) => {
                            if (prevUnitComponent.id ? prevUnitComponent.id === ingredient.id : prevUnitComponent.feId === ingredient.feId) {
                                return {...prevUnitComponent, modifiers: [...prevUnitComponent.modifiers, newValue]}
                            }
                            return prevUnitComponent
                        })
                    })
                    setWarehouseProductsOptions(warehouseProducts.filter((warehouseProduct) => {
                        return warehouseProduct.warehouseProductId !== newValue.warehouseProductId && !ingredient.modifiers.some((modifier) => {
                            return modifier.warehouseProductId === warehouseProduct.warehouseProductId
                        })
                    }))
                }
                }
                disableClearable
            />
            {ingredient.modifiers.length > 0 &&
                <ThemeProvider theme={muiDatagridTheme}>
                    <DataGrid
                        sx={{marginBottom: 3}}
                        rows={ingredient.modifiers}
                        columns={columns}
                        disableRowSelectionOnClick
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10},
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        disableColumnFilter
                        disableColumnMenu
                        disableSelectionOnClick
                        getRowId={(row) => row.warehouseProductId}
                        checkboxSelection
                        onRowSelectionModelChange={(newRowSelectionModel)=>{
                            setSelectedModifiers(newRowSelectionModel)
                            setUnitComponents((prevUnitComponents) => {
                                return prevUnitComponents.map((prevUnitComponent) => {
                                    if (prevUnitComponent.id ? prevUnitComponent.id === ingredient.id : prevUnitComponent.feId === ingredient.feId) {
                                        return {...prevUnitComponent, modifiers: ingredient.modifiers.map((modifier) => {
                                                if(newRowSelectionModel.includes(modifier.warehouseProductId)){
                                                    return {...modifier, isActive: true}
                                                }else{
                                                    return {...modifier, isActive: false}
                                                }
                                            })}
                                    }
                                    return prevUnitComponent
                                })
                            })
                        }
                    }
                        rowSelectionModel={selectedModifiers}

                    />
               </ThemeProvider>
            }
        </>
    )
}

export default Modifiers